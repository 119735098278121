import React from 'react'
import flag from "./region/UstTatarskiy/herb1.png"
import emblem from "./region/UstTatarskiy/herb2.png"
import church_1_image from "./region/UstTatarskiy/h1.jpg"
import church_2_image from "./region/UstTatarskiy/h3.jpg"
import church_3_image from "./region/UstTatarskiy/h5.jpg"
import ryam_1 from "./region/UstTatarskiy/sil ryam.jpg"
import ryam_2 from "./region/UstTatarskiy/mirn ryam.jpg"
function UstTatarskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="герб" title="герб" />
                    </div>
                    <h1 className='head'>Усть-Таркский район</h1>
                </div>
            
            
                <div class="about-region">
                    
                   
                    <div class="inf-4">
                        <p>Административный центр — село Усть-Тарка.
                            Население 10 933 чел.
                            Образован в 1936 г.
                            В 1925 на территории нынешнего Усть-Таркского района был образован Еланский район с центром в селе Еланка в составе Омского округа Сибирского края. В 1929 Еланский район был передан в состав Барабинского округа Сибирского края, с 1930 в составе Западно-Сибирского края. В 1932 Еланский район был ликвидирован и присоединен к Татарскому району. 20 января 1936 из частей Татарского и Венгеровского районов был создан Кушаговский район с центром в с. Кушаги. Однако уже 17 апреля 1936 центр района перенесен в с. Усть-Тарка с переименованием в Усть-Таркский.
                            
                        </p>
                    </div>
                </div>


                <div class="churches">
                    <div >
                        <h2 className='churches-title'>Церкви Усть-Таркского района</h2>
                    </div>
                    <div class ={"inf-4"}> </div>
                    
                    
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм Архангела Михаила, с. Усть-Тарка</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={430} alt="Храм в честь Иконы Божией Матери Всех Скорбящих Радость"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Прежде существовавшая в селе Усть–Тарка деревянная церковь в честь Архистратига 
                        Божия Михаила была построена в 1857 г. 
                        В 1936 году была закрыта и разрушена. 
                        В январе 2009 года инициативой местного населения и с благословения 
                        Архиепископа Новосибирского и Бердского Тихона была организована православная 
                        община в честь новомучеников и исповедников Российских.
                        Новая церковь была заложена в 2011 году, а 4 мая 2014 года уже была освящена.
                        Новый храм имеет тип восьмерик на четверике с небольшой трапезной и 
                        шатровой колокольней.
                         
                    <p><b>Адрес:</b> cело Усть-Тарка, ул. Комсомольская, 84.
                        </p> </div>
                    </table>
                    </div>


                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм мученицы Зинаиды Тарсийской, с. Октябрьское</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={430} alt="Храм"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        
                         Идея строительства храма в отдаленном селе района принадлежала главе
                         Усть-Таркского
                        района. Храм в честь мученицы Зинаиды Тарсийской был освящен
                        14 июля 2016 года епископом Феодосием. 
                        <div> </div>
                         
                    <p><b>Адрес:</b> cело Октябрьское.
                        </p> </div>
                    </table>
                    </div>
                    

                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Часовня в честь Казанской иконы Божией Матери, с. Усть-Тарка</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={230} alt="Храм"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        
                        Часовня в память защитников Отечества во все времена от благодарных земляков
                        была построена жителями села Усть-Тарка. <div>7 мая 2015 года часовню освятил
                        епископ Каинский и Барабинский Феодосий. Теперь в часовне регулярно проходят службы. </div> 
                        
                         
                    <p><b>Адрес:</b> cело Усть-Тарка, ул. Есенина 2/1.
                        </p> </div>
                    </table>
                    </div>

                   
                    
                </div>






                
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Природные достопримечательности</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Мирнинский рям</p>
                            </div>
                            <div class="attraction-3-image">
                            <img src={ryam_2} width={800} alt="Мирнинский рям" title="рям" />
                        </div>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Силишинский рям</p>
                            </div>
                            <div class="attraction-3-image">
                            <img src={ryam_1} width={800} alt="Силишинский рям" title="рям" />
                        </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
    );
}

export default UstTatarskiy