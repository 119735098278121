import React from'react'
import flag from "./region/Tatarskiy/herb1.png"
import church_1_image from "./region/Tatarskiy/h1.jpg"
import church_2_image from "./region/Tatarskiy/h2.jpg"
import church_3_image from "./region/Tatarskiy/h4.jpg"
function Tatarskiy(){
    return(
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Татарский район</title>
            </div>
            <section class="region">
            <div class="title-region"> 
            <div class="flag">
                        <img src={flag} width={180} alt="Флаг" title="Герб" />
                    </div>
                    <h1 className='head'>Татарский район</h1>
                </div>
               
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <p>Район образован в 1925 в составе Барабинского округа Сибирского края. 
                            В 1930 году Сибирский край был разделен на западную и восточную части, 
                            и Татарский район оказался в составе Западно-Сибирского края. В 1937 район был включен во 
                            вновь образованную Новосибирскую область. 29 марта 1944 года Татарск получил статус города
                             областного подчинения и был выведен из состава района, оставаясь, при этом, его центром.</p>
                    </div>
                    
                </div>
                <div class="info-region">
                    <div class="attractions">
                        {/*none*/}
                    </div>
                </div>



                
                <div class="church-1"> <table>
                        <div class="church-1-title">
                            <p>Храм в честь Покрова Пресвятой Богородицы г. Татарска</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={450} alt="Храм"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        В конце 1980-х годов в городе стала возрождаться духовная жизнь — 
                        в 1987 году по благословению митрополита Новосибирского и
                         Барнаульского Гедеона в г. Татарске была образована православная община. 
                         Был устроен молельный дом, который позже перестроили в храм. 
                         <div>
                         В 1994 году Преосвященнейший Тихон, епископ Новосибирский и Барнаульский, 
                         освятил место под строительство нового храма и 
                         закладной камень в фундамент будущего храма в честь Покрова Пресвятой Богородицы. 
                         8 июля 2001 храм был освящен.</div>   
                    <p><b>Адрес:</b> Татарск, улица Ленина, 51.</p> </div>
                    </table></div>



                    <table class='table-w'>
                    <div class="church-1"> 
                        <div class="church-1-title">
                            <p>Храм во имя Архангела Михаила, с.Новомихайловка </p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={450} alt="Храм во имя Архангела Михаила"
                                title="Храм во имя Архангела Михаила" />
                        </div>
                        <div class ={"inf-4"}>
                       Храм освящен в честь Архистратига Божия Михаила в 2003 году. 
                        
                    <p><b>Адрес:</b> село Новомихайловка, ул. Учительская 11.</p> </div>
                    </div>
                    </table>

                    <table class='table-w'>
                    <div class="church-1"> 
                        <div class="church-1-title">
                            <p>Часовня в честь святых Гурия и Варсонофия Казанских  </p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={450} alt="Храм во имя Архангела Михаила"
                                title="Храм во имя Архангела Михаила" />
                        </div>
                        <div class ={"inf-4"}>
                       Часовня в честь святых Гурия и Варсонофия Казанских сооружена у трассы М51 "Байкал" недалеко от села 
                       Северотатарское. <div>
                       Построена около 2006 года. Приписана к Покровскому храму в г. Татарске. 
                       </div>
                    <p><b>Адрес:</b> Татарский район, трасса М51 "Байкал" 985 километр.
</p> </div>
                    </div>
                    </table>



            </section>
        </div>
    );
}

export default Tatarskiy