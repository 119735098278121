import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import sv_Iliya from './img_sobor/schmch-ilia.jpg'
import sv_Iliya1 from './img_sobor/sv-Iliya-moshk.jpg'



function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <title>Священномученик Илия Березовский</title>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Священномученик Илия Березовский</b>
        </div><p></p> 
      


                  {/* Священномученик Илия Березовский */}
                   <div> <table>
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={sv_Iliya} width={280}
                                alt="Священномученик Илия Березовский"
                                title="Священномученик Илия Березовский" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        Священномученик Илия Березовский родился в 1890 году в деревне Гляденской Томского уезда Томской губернии 
                        (ныне деревня Глядень Мошковского района Новосибирской области). Служил священником в храме во имя преподобного 
                        Алексия, человека Божия,
                        в селе Алексеевском (с 1933 года — село Мошково) Новосибирской области. 
                        <div>В 1932 году Свято-Алексиевский храм был закрыт, а в следующем году Мошковское ОГПУ арестовала отца Илию.
                           Священника выслали в Казахстан, в село Чемолган Каскеленского района Алма-Атинской области (ныне село Ушконыр 
                           Карасайского района). Спустя четыре года, в декабре 1937-го, он был арестован Каскеленским отделом НКВД. 
                           В уголовном деле значился как «священник православной церкви, на момент ареста безработный, без определённого 
                           места жительства». Отца Илию обвинили в систематической антисоветской пропаганде, террористических настроениях 
                           против руководителей ВКП(б) и правительства, а также в том, что он вёл среди населения села Чемолган агитацию 
                           за создание церковной общины и открытие церкви. 
                          Следователь требовал от священника признать себя виновным, но тот решительно заявил: «Своей вины не признаю».  </div>
                        <div>
                        <div>25 декабря 1937 года тройка НКВД по Алма-Атинской области приговорила священника Илию Березовского к заключению 
                          в исправительно-трудовой лагерь сроком на 10 лет. 
                          Однако 1 февраля 1938 года состоялось повторное заседание тройки НКВД, на котором был вынесен уже смертный приговор. </div>

                          <div>3 февраля 1938 года священник Илия Березовский был расстрелян и погребён в общей безвестной могиле на полигоне НКВД 
                            Жаналык в  Алма-Атинской области. В 1937–1938 годах это 
                            место стало самым масштабным в Казахстане местом казни и захоронения репрессированных православных 
                            священнослужителей и мирян.</div>
                            <div class="church-1r-image"><table><tr>
                            <img src={sv_Iliya1} width={420}
                                alt="Священномученик Илия Березовский"
                                title="Священномученик Илия Березовский" /></tr>
                        <tr><div class='podpis'>Памятная доска, установленная на храме в честь   <div> 
                          св. прав. Алексия, человека Божия в Мошково</div></div></tr></table></div>
                          <div> 11 апреля 1989 года Илья Яковлевич Березовский был реабилитирован генеральным прокурором Республики Казахстан.</div>
                          
                          
                          
                          <div>В августе 2000 года на Юбилейном Архиерейском Соборе Русской 
                            Православной Церкви имя священномученика Илии Березовского было включено в Собор новомучеников и исповедников Российских. </div>
                            
                              
                              <div> Память священномученика Илии Березовского совершается в день его мученической кончины — 21 января (3 февраля н.ст.), 
                                в Соборе святых Новосибирской митрополии 16 февраля (29 февраля н.ст.), в Соборе новомучеников и исповедников 
                                Казахстанских (первое воскресенье, начиная с 3 сентября (16 сентября н.ст.)) 
                                и в Соборе новомучеников и исповедников Церкви Русской (первое воскресенье, начиная с 25 января (7 февраля н. ст.)). </div>
                              <div></div>
                              
                              <div> </div>
                              <div> </div>
                      </div> 
                      </div>
                    </table>
                    </div>   
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
