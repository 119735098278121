import React from "react";
import "../nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import r from "./2560px-Храм_Андрея_Первозванного_в_Карасуке.jpg"
function Karasuk() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
      </div>
      <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
      </div>
      <div className="header" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h1>Карасукская епархия</h1>
        <a>
          Карасу́кская епархия — епархия Русской православной церкви,
          объединяющая приходы и монастыри в южной части Новосибирской области
          (в границах Баганского, Доволенского, Здвинского, Карасукского,
          Кочковского, Краснозерского, Купинского, Ордынского и Чистоозёрного
          районов). Входит в состав Новосибирской митрополии.
        </a>
      </div>
      <div className="all_ep" style={{ filter: isActive ? "blur(150px)" : "" }}>
        <Link class="sem" to="/kainsk">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Каинская епархия
          </div>
        </Link>
        <Link class="sem" to="/Karasuk">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            {" "}
            Карасукская епархия
          </div>
        </Link>
        <Link class="sem" to="/Iskitim">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Искитимская епархия
          </div>
        </Link>
        <Link class="sem" to="/ep">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Новосибирская митрополия
          </div>
        </Link>
      </div>
      <div className="history" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2>История</h2>
        <div className="his-inf">
          Учреждена решением Священного Синода 28 декабря 2011 года путём
          выделения части приходов из Новосибирской епархии. Административно
          включена в состав Новосибирской митрополии[1]. Управляющим епархией
          избран <i>игумен Филипп (Новиков)</i>, клирик Новосибирской епархии,
          хиротонисаный во епископа 11 марта 2012 года. В марте 2016 года портал
          Православие и мир так описывал жизнь в епархии: «Здесь не замечают
          экономического кризиса — никогда не жили в достатке. Смертность
          превышает рождаемость, в деревнях люди часто злоупотребляют алкоголем…
          Сюда редко едут служить выпускники московских духовных академий.
          <b>Епархии всего</b> <i>четыре года</i>, но многое уже удалось
          сделать»
        </div>
      </div>
      <div className="arh" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="arh-h1" style={{ filter: isActive ? "" : "blur(20px)" }}>
          Кафедральный собор:
        </h2>
        <div
          className="arh-name"
          style={{ filter: isActive ? "" : "blur(20px)" }}
        >
          Собор Андрея Первозванного (г.Карасук). Был построен в 2006 году и
          освящен 26 октября 2006 года.
        </div>
        <img src={r} alt="Собор Андрея Первозванного" width={700}/>
      </div>
      <div className="sbr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="sbr-h1">Архиерей:</h2>
        <div>
          Преосвященнейший Филипп (Новиков) Епископ Карасукский и Ордынский.
          Решением Священного Синода от 28 декабря 2011 г. избран епископом
          Карасукским и Ордынским.
        </div>
      </div>
      <div className="obr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="obr-h1">Монастыри:</h2>
        <div>
          В 1998 году Указом Священного Синода был утверждён епархиальный
          женский монастырь во имя Архистратига Божия Михаила и настоятельница
          обители – монахиня Мария (Серопян).
        </div>
      </div>
      <Link to="/">
        <div class="BiExit">
          <BiExit />
        </div>
      </Link>
    </div>
  );
}

export default Karasuk;
