import React from 'react'
import church_1_image from  "./region/Bolotinskiy/h1-.jpg"
import church_2_image from  "./region/Bolotinskiy/h2.png"
import church_3_image from  "./region/Bolotinskiy/h7.jpg"
import church_4_image from  "./region/Bolotinskiy/h4.png"
import church_5_image from  "./region/Bolotinskiy/h8.jpg"
import flag from "./region/Bolotinskiy/herb1.png"
import emblem from "./region/Bolotinskiy/herb2.png"
import map_region from "./region/Bolotinskiy/map1.png"
import attraction_1_image from "./region/Bolotinskiy/priroda.png"
function Bolotinskiy() {
    return(
    <div class='main'>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
        <meta name="viewport" content="user-scalable=yes"/>
        <title>Document</title>
        <section class="region">
                
                <div class="flag">
                    <img src={emblem} width={180} alt="Герб болотинского района" title="Герб" />
                </div>
            <div class="title-region">
                <h1 className='head'>Болотнинский район</h1>
            </div>
            <div class="about-region">
                
                
                
                <div class="text">
                    <p>Население: 26.7 тыс. человек. Район образован в 1924 году. Административный центр – г. Болотное
                        (15478 чел.). Территория района 3.37 тыс. км². На территории района расположено 57 населенных
                        пунктов. Наиболее крупные населенные пункты: Дивинка (808 чел.) , Светлая Поляна (801 чел.), Ояш
                        (770 чел.).</p>
                </div>
            </div>
            <div class="info-region">
                
            </div>
            <div class="churches">
                <div>
                    <h2 className='churches-title'>Церкви Болотнинского Района</h2>
                </div>

                <div class="church-1">
                    <div class="church-1-title">
                        <p>Храм Святителя Николая Чудотворца в г. Болотное</p>
                    </div>
                    <div class="church-1-image">
                        <img src={church_1_image} width={550} alt="Храм Николая чудотворца" title="Храм1" />
                    </div>
                    <div class ={"inf-4"}>
                    Деревянная однопрестольная церковь во имя Святителя Николая в селе Болотное была построена 
                    в 1912 году на пересечении Московского тракта и улицы Гондатьевской. После революции храм передали обновленцам.
                    А потом его совсем закрыли (Распоряжение Облисполкома № 35 от 10.02.40 г.). 
                    Церковь отдали под склад, потом в бывшем храме открыли кинотеатр «Победа». 
                    В 1952 году здание сгорело. </div>
                    <div class ={"inf-4"}> Верующие жители г.Болотное неоднократно обращались в Новосибирский облисполком 
                    о возврате церкви и возобновлении богослужений. Дважды это ходатайство было отклонено.
                    Наконец в июле 1945 года было получено разрешение на открытие молитвенного дома.
                    Молитвенный   дом находился по адресу: ул. 2-я Кондукторская, 7. 
                    Его купили на пожертвования жителей Болотного. 
                    Новый храм был освящен в честь Святителя Николая Чудотворца митрополитом Варфоломеем в 1948 году. </div> 
                    <div class ={"inf-4"}> В последующие годы храм расширили, перестроили. Во время настоятельства о. Николая Чугайнова
                    церковь стала двухэтажной. В 1980 году установили новый иконостас, вырезанный мастером 
                    М.И. Селезнёвым из Рубцовска. </div> 
                    <div class ={"inf-4"}> В советские годы этот храм был одним из трех действующих храмов 
                    на территории Новосибирской области. В храме есть старинные иконы, многие из которых принесены
                    из других храмов, которые закрывались и разрушались в годы гонений. </div>
                    <p>
                    <div class ={"inf-4"}><b>Чтимые иконы храма и святыни:  </b></div>
                    <div class ={"inf-4"}>– иконы Архистратига Божия Михаила</div>
                    <div class ={"inf-4"}>– икона святого великомученика и целителя Пантелеимона</div>
                    <div class ={"inf-4"}>– икона Святителя Николая Чудотворца</div>
                    <div class ={"inf-4"}>– икона Преподобного Серафима Саровского</div>
                    <div class ={"inf-4"}>– частица святых мощей Феодора Томского</div>
                    <div class ={"inf-4"}>– частица святых мощей старца Амвросия Оптинского</div>
                    <div class ={"inf-4"}>– частицы мощей преподобных Александры, Елены, Марфы — Дивеевских сестер. </div>
                    </p>
                    <div class ={"inf-4"}><b>Адрес:</b>  г. Болотное, ул. 2-я Кондукторская, 7. Телефон: 8-(383-49) 21-086.</div>
                        
                   
                </div>

                <div class="church-2"> <table>
                    <div class="church-2-title">
                        <p>Храм Архангела Михаила в г. Болотное.</p>
                    </div>
                    <div class="church-1-image">
                        <img src={church_2_image} width={600} alt="Храм Архангела Михаила" titile="храм2" />
                    </div>
                    <div class ={"inf-4"}> 4 июля 2008 года в Болотном был открыт храм Архангела Михаила, в котором 
                    утром прошла первая служба. Новый собор возвели рекордно 
                    быстро — за год и один месяц. Строители работали без выходных, от зари до зари, частенько 
                    помогали и местные жители. Новый храм во имя Архангела Михаила освятил 
                    архиепископ Новосибирский и Бердский Тихон 21 ноября 2008 года. </div>
                    <p><div class ={"inf-4"}><b>Адрес:</b> г. Болотное, ул. Кирова, 24/1</div></p>
                    <div class ={"inf-4"}><b>Телефон:</b> 8(383-49) 25-280, факс 8(383-49) 21-086
                    
                    </div>
                    </table>
                </div>
                
                <div class="church-3"> <table>
                    <div class="church-2-title">
                        <p>Церковь Иверской иконы Божией Матери в деревне Новaя Чебула</p>
                    </div>
                    <div class="church-1-image">
                        <img src={church_3_image} width={700} alt="Церковь Иверской иконы Божией Матери" title="Церковь Иверской иконы Божией Матери" />
                    </div>
                    <div class ={"inf-4"}>Церковь устроена в приспособленном здании в 2008 году. Внешне выделена главкой и звонницей.
                    </div>
                    <p><div class ={"inf-4"}><b>Адрес:</b> Новосибирская обл., Болотнинский р-н, д. Новая Чебула,
                     ул. Овражная, 2А</div></p> <p> </p><p> </p><p> </p><p> </p><p> </p><p> </p><p> </p>
                </table>    
                </div>
                
                
                <div class="church-4">
                    <div class="church-2-title">
                        <p>Церковь Серафима Саровского в Турнаево</p>
                    </div>
                    <div class="church-1-image">
                        <img src={church_4_image} width={700} alt="Церковь Серафима Саровского" title="Церковь Серафима Саровского" />
                    </div>
                    <div class ={"inf-4"}>Строительство храма началось через 9 лет после прославления преподобного Серафима Саровского
                     — в 1912 году. Основные средства на строительство храма — около 12 тысяч рублей собрали крестьяне с. Турнаево и 
                     окрестных деревень, 4 тыс. рублей было выделено Священным Синодом, было и пожертвование от купца Блинова. 
                     Церковь строилась артелью   плотников из Перми в составе из 45 человек. Рубка брёвен «в лапу», одного диаметра, 
                     создаёт чёткий рисунок сруба, так называемый восьмерик на четверике. Камень на фундамент и цоколь были привезены 
                     из Искитимского района, где находились каменные (известняковые) карьеры, лесной материал из приобских сосновых 
                     брёвен — со станции Тайга Великого Сибирского железнодорожного пути. Храм венчают семь куполов и крестов. 
                     В былые времена звон колоколов был слышен за много вёрст. 
                    Верхняя отметка — 29 метров, основной шатёр — 20 метров.  </div>
                    <div class ={"inf-4"}>Композиция и декор храма характерны для конца XIX века. В благоукрашении храма активно 
                    участвовали томские купцы, кузнецкие промышленники, алтайские предприниматели. Большой вклад внесла томская купчиха Александра 
                    Терпигорова. Она действовала по завещанию своей матушки Варвары, которая была духовной дочерью старца Феодора 
                    Томского. По воспоминанию старожилов, церковь была огорожена деревянной резной оградой. 
                    Церковь как бы парит в воздухе, по архитектуре храм напоминает знаменитые церкви острова Кижи.</div>

                    <div class ={"inf-4"}> Храм был построен всего за два строительных сезона и освящён в сентябре 1914 года. 
                    Первым священником был о. Александр Злобин, псаломщиком – Герасим Фёдорович Рочегов. 
                    Первым церковным старостой был Василий Баннов. </div>
                    <div class ={"inf-4"}>К 1937 году храм оставался единственным православным храмом не только в Болотнинском районе, 
                    но и в окружающих районах Томской, Новосибирской и Кемеровской областей. Накануне престольного праздника
                     30 июня 1937 года за отказ от передачи храма властям были арестованы настоятель храма священник 
                     Пётр Манойленко, староста Василий Баннов и псаломщик Демьян Литвинов. По приговору особой 
                     тройки НКВД они были расстреляны 14 августа 1937 года. После этого храм был закрыт и разграблен. 
                     В закрытом храме размещался клуб, затем зерносклад.  </div>
                     
                     <div class ={"inf-4"}>Вскоре после празднования Тысячелетия Крещения Руси, 
                     19 января 1989 года, Новосибирским облисполком было принято решение о 
                     возвращении церкви во имя Преподобного Серафима Саровского Новосибирской епархии.
                     На престольный праздник 1 августа 1998 года в храме был отслужен молебен с акафистом преп. Серафиму Саровскому 
                     и совершено массовое крещение селян (более 70 человек). 12 июля 2000 года, на праздник славных и всехвальных 
                     первоверховных апостолов Петра и Павла, в храме была отслужена первая с момента закрытия храма Божественная 
                     литургия, а 31 июля 2000 года из Болотного в Турнаево прошёл первый Серафимо-Турнаевский Крестный ход. 
                     С тех пор Крестный ход проходит ежегодно 31 июля и собирает паломников из Новосибирской, Кемеровской 
                     и Томской областей.</div>
                    <div class ={"inf-4"}> <b>Адрес:</b> Болотнинский район, 
                    с. Турнаево, ул. Хохловка, 1. </div>
                </div>


                <table>
                <div>
                    
                <div class="church-2-title">
                        <p>Церковь Феодора Томского в селе Турнаево</p>
                </div>
                    <div class="church-1-image">
                        <img src={church_5_image} width={700} alt="Церковь Феодора Томского" title="Храм5" />
                    </div>
                    <div class ={"inf-4"}>В 2003 г. в селе Турнаево был освящён престол во 
                    вновь построенной церкви во имя святого 
                    Феодора Томского, прославленного в лике Сибирских святых. Храм построен недавно, но воплощает традиции древнего 
                    русского узорочья — резной деревянный иконостас, резная отделка крыши. 
                    <div>Небольшая деревянная церковь была построена 
                    для проведения служб во время реставрации Серафимовского храма. Интересно, что Феодор Томский и 
                    Серафим Саровский жили в одно время и были современниками. Может, поэтому и возникла мысль 
                    построить рядом с Серафимовской церковью храм в честь праведного Феодора Томского.</div></div>
                    <div class ={"inf-4"}> <b>Адрес:</b> Болотнинский район, 
                    с. Турнаево, ул. Хохловка, 5А. <p> </p> </div>
                </div>
                </table>

            </div>
        </section>
    </div>
    );
}
export default Bolotinskiy