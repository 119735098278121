import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";



import Sv_Ioann from './img_sobor/ioann-kuminov.jpg'
import Sv_Ioann2 from './img_sobor/ioann_kuminov_.jpg'


function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Священномученик Иоанн Куминов</b>
        </div><p></p> 
      


                  
                   <div> 
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={Sv_Ioann} width={280}
                                alt="Священномученик Иоанн Куминов"
                                title="Священномученик Иоанн Куминов" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        Как и многие другие новомученики и исповедники Церкви Русской, священномученик Иоанн Куминов 
                        пришёл на служение Церкви в годы гонений, 
                        когда принятие сана само по себе означало вступление на крестный путь.    </div>
                        
                        <div class ={"inf-5"}>Священномученик Иоанн родился в 1865 году в селе Куликовском Тобольской 
                        губернии в семье крестьянина. Когда ему было 12 лет, семья переехала в Омск. Здесь Иван окончил 
                        учительскую семинарию и после испытания при Омской классической гимназии был назначен инспектором 
                        народных училищ Тарского округа Тобольской губернии. В этой должности 
                        И.И. Куминов прослужил до 1922 года, когда был рукоположен во священника.  </div>
                        
                        <div class ={"inf-5"}>Отец Иоанн служил в разных храмах Омской епархии, а в 1928 году был направлен 
                        в Богоявленский храм села Малокрасноярка 
                        (ныне Кыштовского района Новосибирской области), в котором настоятелем был священник Михаил Пятаев.

                        

                        <div>7 января 1930 года отец Иоанн совершал в храме праздничное Рождествен-ское богослужение. 
                          В своей проповеди он поздравил всех пришедших с праздником и призвал молодых людей и их 
                          родителей чаще посещать церковь, молиться Богу и не слушать, что им говорят против веры и Церкви. 
                          Одна из присутствовавших в храме стала после службы говорить всем о том, что священник произнёс 
                          антисоветскую проповедь. Женщины убеждали её не разносить слухи, но та не унималась и впоследствии 
                          лжесвидетельствовала на допросе. Вскоре после праздника обоих 
                          священников Богоявленского храма арестовали и отправили в тюрьму города Каинска. </div>
                        
                            <div class="church-1r-image"><table><tr>
                            <img src={Sv_Ioann2} width={280}
                                alt="Священномученик Иоанн Куминов"
                                title="Священномученик Иоанн Куминов" /></tr>
                        <tr><div class='podpis'>Священномученик Иоанн Куминов.  
                        <div> Тюрьма НКВД. 1930 г.</div></div></tr></table></div>
                        
                          <div>Несмотря на жестокие побои во время допросов, отец Иоанн Куминов отказался признавать себя 
                            виновным в том, что поднимал крестьян на борьбу с колхозами и советской властью. Как показал 
                            во время следствия отец Михаил Пятаев, «священник Куминов проповедует… только по духовным книгам, 
                            а о власти и о проводимых ею мероприятиях никогда не говорит. Он просто призывал молящихся и 
                            молодёжь по-христиански молиться Богу и находить время для этого, ходить по праздникам в церковь». </div>
                             
                          <div>21 февраля 1930 года тройка ОГПУ приговорила отца Иоанна Куминова к расстрелу, а его семью —
                             к ссылке на север. 28 февраля 1930 года 
                            священномученик Иоанн Куминов был расстрелян и погребён в безвестной общей могиле в городе Каинске. </div>
                          
                                                  
                          <div>В августе 2000 года Юбилейный Архиерейский Собор Русской Православной Церкви причислил священника 
                            Иоанна Куминова к лику святых новомучеников и исповедников Российских для общецерковного почитания. </div>
                                                     
                            
                              <div>В память священномучеников Михаила Пятаева и Иоанна Куминова в селе Малокрасноярка установлен 
                                поклонный Крест, у которого совершается молебен святым. В городе Куйбышеве, который ранее назывался 
                                Каинском, на втором этаже Духовно-просветительного центра Каинской 
                                епархии освящён домовой храм во имя священномучеников Михаила Пятаева и Иоанна Куминова. </div>
                              <div>21 февраля 1930 года тройка ОГПУ приговорила священника Михаила Пятаева к расстрелу, 
                                а его семью — к ссылке на север. Такой же приговор был вынесен и отцу Иоанну Куминову.</div>

                                
                                
                                Память священномученика Иоанна Куминова Русская Православная Церковь чтит в день его 
                                мученической кончины — 15 февраля (28 февраля н.ст.), в Соборе святых Новосибирской митрополии 
                                16 февраля (29 февраля н.ст.) и в Соборе новомучеников и 
                                исповедников Церкви Русской (первое воскресенье, начиная с 25 января (7 февраля н.ст.)). </div>
 
                      </div>
                      
                      
                    
                      
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
