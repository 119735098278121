import "./Styl.css";
import MapYa from "./MapYa";
;
export default function Searchh() {

    const points = [
    {
        id: 1,
        name: 'Вознесенский кафедральный собор',
        new: true,
        synonyms: 'Вознесенский, кафедральный, собор',
        image: require('./img/0.png'),
        adress: 'г. Новосибирск, ул. Советская, 91',
        coordinates: [55.042461, 82.912422],
        x: 55.042461,
        y: 82.912422,
        link: 'http://vksnsk.ru/',
        text: 'Храм построен был в 1913 году из дерева. Затем при советской власти один из пределов использовался как зернохранилище. В 1988 году реконструирован, с тех пор собор стал каменным и сохранил свой вид до сих пор.',
    },
    {
        id: 2,
        name: 'Собор в честь святого Александра Невского',
        new: true,
        synonyms: 'Собор, в, честь, святого, Александра, Невского',
        image: require('./img/1.jpg'),
        adress: 'г. Новосибирск, ул. Советская, 1А',
        coordinates: [55.019531, 82.922584],
        x: 55.019531,
        y: 82.922584,
        link: 'http://www.ansobor.ru/',
        text: 'Храм построен был в 1899 году в неовизантийском стиле. Одно из первых каменных зданий Ново-Николаевска. При советской власти храм пытались взорвать, но попытки не увенчались успехом. В 1989 году храм возвращен церкви.',
    },
    {
        id: 3,
        name: 'Часовня во имя святителя Николая Чудотворца',
        new: true,
        synonyms: 'Часовня, во, имя, святителя, Николая, Чудотворца',
        image: require('./img/3.jpg'),
        adress: 'г. Новосибирск, ул. Красный проспект, 17А',
        coordinates: [55.026498, 82.921457],
        x: 55.026498,
        y: 82.921457,
        link: '',
        text: 'Была построена в 1915 году, символизировала географический центр Российской империи. В 1930 году часовня была полностью разрушена, а в 1993 году - вновь построена.',
    },
    {
        id: 4,
        name: 'Храм в честь Покрова Пресвятой Богородицы',
        new: true,
        synonyms: 'Храм, в, честь, Покрова, Пресвятой, Богородицы',
        image: require('./img/4.jpg'),
        adress: 'г. Новосибирск, ул. Октябрьская, 9',
        coordinates: [55.024235, 82.913529],
        x: 55.024235,
        y: 82.913529,
        link: '',
        text: 'Храм был освящен в 1901 году. В 1939 году церковь была закрыта и вновь освящена только в 2009 году.',
    },
    {
        id: 5,
        name: 'Часовня в честь иконы Божией Матери "Споручница грешных"',
        new: true,
        synonyms: 'Часовня, в, честь, иконы, Божией, Матери, Споручница, грешных',
        image: require('./img/5.jpg'),
        adress: 'г. Новосибирск, ул. Владимировский спуск, 2А к16',
        coordinates: [55.035217, 82.884950],
        x: 55.035217,
        y: 82.884950,
        link: '',
        text: 'Часовня была освящена в 2006 году. Располагается на территории Дорожной клинической больницы.',
    },
    {
        id: 6,
        name: 'Храм во имя святого мученика Евгения',
        new: true,
        synonyms: 'Храм, во, имя, святого, мученика, Евгения',
        image: require('./img/6.jpg'),
        adress: 'г. Новосибирск, ул. Мочищенское шоссе, 1',
        coordinates: [55.088634, 82.891201],
        x: 55.088634,
        y: 82.891201,
        link: '',
        text: 'Располагается на подворье женского монастыря во имя Михаила Архангела.',
    },
    {
        id: 7,
        name: 'Храм в честь иконы Богородицы "Неупиваемая Чаша"',
        new: true,
        synonyms: 'Храм, в, честь, иконы, Богородицы, Неупиваемая, Чаша',
        image: require('./img/7.jpg'),
        adress: 'г. Новосибирск, ул. Ереванская, 14/1',
        coordinates: [55.074238, 82.898370],
        x: 55.074238,
        y: 82.898370,
        link: '',
        text: 'Храм располагается в здании бывшего кинотеатра "Спутник".',
    },
    { //error!
        id: 8,
        name: 'Часовня в честь Казанской иконы Пресвятой Богородицы',
        new: true,
        synonyms: 'Часовня, в, честь, Казанской, иконы, Пресвятой, Богородицы',
        image: require('./img/7.jpg'),
        adress: 'г. Новосибирск, ул. Лесное, 1 к1',
        coordinates: [54.915321, 83.051036],
        x: 54.915321,
        y: 83.051036,
        link: '',
        text: 'Часовня находится на территории санатория "Ревитальский парк".',
    },
    {
        id: 9,
        name: 'Храм во имя святого мученика Вонифатия Тарсийского',
        new: true,
        synonyms: 'Храм, во, имя, святого, мученика, Вонифатия, Тарсийского',
        image: require('./img/9.jpg'),
        adress: 'г. Новосибирск, ул. Столетова, 17а',
        coordinates: [55.100267,  82.962356],
        x: 55.100267,
        y: 82.962356,
        link: '',
        text: 'Храм был освящен в 2012 году.',
    },
    {
        id: 10,
        name: 'Храм в честь иконы Божией Матери "Знамение-Абалацкая"',
        new: true,
        synonyms: 'Храм, в, честь, иконы, Божией, Матери, Знамение-Абалацкая',
        image: require('./img/10.jpg'),
        adress: 'г. Новосибирск, ул. Богдана Хмельницкого, 74',
        coordinates: [55.081395,  82.966685],
        x: 55.081395,
        y: 82.966685,
        link: '',
        text: 'Храм был освящен в 2000 году.',
    },
    {
        id: 11,
        name: 'Храм во имя святого праведного Иоанна Кронштадтского',
        new: true,
        synonyms: 'Храм, во, имя, святого, праведного, Иоанна, Кронштадтского',
        image: require('./img/11.jpg'),
        adress: 'п. Пашино, ул. Магистральная, 9',
        coordinates: [55.162679,   82.978116],
        x: 55.162679,
        y: 82.978116,
        link: '',
        text: 'Храм был освящен в 2015 году.',
    },
    {
        id: 12,
        name: 'Храм во имя святого апостола Андрея Первозванного',
        new: true,
        synonyms: 'Храм, во, имя, святого, апостола, Андрея, Первозванного',
        image: require('./img/12.jpg'),
        adress: 'г. Новосибирск, ул. Тюленина, 1 к2 ',
        coordinates: [55.104798, 82.953911],
        x: 55.104798,
        y: 82.953911,
        link: '',
        text: 'Храм освящен в 2017 году.',
    },
    {
        id: 13,
        name: 'Храм в честь Успения Пресвятой Богородицы (архиерейское подворье)',
        new: true,
        synonyms: 'Храм, в, честь, Успения, Пресвятой, Богородицы, (архиерейское, подворье)',
        image: '',
        adress: 'г. Новосибирск, ул. Гоголя, 179',
        coordinates: [55.045163, 82.952666],
        x: 55.045163,
        y: 82.952666,
        link: '',
        text: 'Храм был построен в 1925 году на кладбище. К 1941 году оставался единственным действующим храмом во всей области, в 1962 году был разобран. Современный Успенский храм открыт в 1995 году.',
    },
    {
        id: 14,
        name: 'Храм во имя преподобной Евфросинии Полоцкой',
        new: true,
        synonyms: 'Храм, во, имя, преподобной, Евфросинии, Полоцкой',
        image: require('./img/14.jpg'),
        adress: 'г. Новосибирск, ул. Лазурная, 26',
        coordinates: [55.033639, 83.019424],
        x: 55.033639,
        y: 83.019424,
        link: '',
        text: 'Приход действует с 2000 года. Храм был освящен в 2002 году.',
    },
    {
        id: 15,
        name: 'Приход во имя святого страстотерпца царя Николая',
        new: true,
        synonyms: 'Приход, во, имя, святого, страстотерпца, царя, Николая',
        image: require('./img/15.jpg'),
        adress: 'г. Новосибирск, улица Никитина, 105/1',
        coordinates: [55.022880, 82.971255],
        x: 55.022880,
        y: 82.971255,
        link: '',
        text: 'Приход действует с 2002 года.',
    },
    {
        id: 16,
        name: 'Храм во имя Михаила Архангела',
        new: true,
        synonyms: 'Храм, во, имя, Михаила, Архангела',
        image: require('./img/16.jpg'),
        adress: 'г. Новосибирск, ул. Большевистская, 229',
        coordinates: [54.990607, 82.984599],
        x: 54.990607,
        y: 82.984599,
        link: '',
        text: 'Храм существовал с дореволюционных времен. При советской власти был закрыт, а здание превращено в клуб. Затем был сломан, а на этом месте был построен кинотеатр "Заря" С 1994 года передан церкви, в 1996 году освящен.',
    },
    {
        id: 17,
        name: 'Храм во имя преподобного и благоверного князя Олега Брянского',
        new: true,
        synonyms: 'Храм, во, имя, преподобного, и, благоверного, князя, Олега, Брянского',
        image: require('./img/17.jpeg'),
        adress: 'г. Новосибирск, ул. Лескова, 131',
        coordinates: [55.019429, 82.957651],
        x: 55.019429,
        y: 82.957651,
        link: '',
        text: 'Храм был освящен в 2013 году.',
    },
    {
        id: 18,
        name: 'Храм в честь иконы Божией Матери "Казанская"',
        new: true,
        synonyms: 'Храм, в, честь, иконы, Божией, Матери, Казанская',
        image: '',
        adress: 'г. Новосибирск, ул. Одоевского, 32',
        coordinates: [54.944259, 83.131918],
        x: 54.944259,
        y: 83.131918,
        link: '',
        text: 'Приход был открыт в 1997 году, с 2017 года совершаются богослужения.',
    },
    {
        id: 19,
        name: 'Храм во имя святителя Митрофана Воронежского',
        new: true,
        synonyms: 'Храм, во, имя, святителя, Митрофана, Воронежского',
        image: require('./img/19.jpeg'),
        adress: 'г. Новосибирск, ул. Изоляторная, 5',
        coordinates: [54.911299, 83.071048],
        x: 54.911299,
        y: 83.071048,
        link: '',
        text: 'Храм освящен в 2008 году.',
    },
    {
        id: 20,
        
        name: 'Часовня в честь иконы Пресвятой Богородицы (Благодатное небо)',
        new: true,
        synonyms: 'Часовня, в, честь, иконы, Пресвятой, Богородицы, (Благодатное, небо)',
        image: '',
        adress: 'г. Новосибирск, ул. Марии Ульяновой, 18 к1',
        coordinates: [54.977180, 83.092523],
        x: 54.977180,
        y: 83.092523,
        link: '',
        text: 'Часовня располагается в ЖК "Благодатный", освящена в 2017 году.',
    },
    {
        id: 21,
        name: 'Церковь Спаса Нерукотворного Образа из Зашиверска',
        new: true,
        synonyms: 'Церковь, Спаса, Нерукотворного, Образа, из, Зашиверска',
        image: require('./img/21.jpg'),
        adress: 'г. Новосибирск, ул. Ионосферная, 6 к2',
        coordinates: [54.849397, 83.177434],
        x: 54.849397,
        y: 83.177434,
        link: '',
        text: 'Церковь была построена в 1700 году в заполярном городе Зашиверске. Перевезена в Новосибирск в 1971 году из исчезнувшего города.',
    },
    {
        id: 22,
        name: 'Храм во имя святителя Николая Чудотврца (с пределом Святых  Царственных страстотерпцев)',
        new: true,
        synonyms: 'Храм, во, имя, святителя, Николая, Чудотврца, (с, пределом, Святых, , Царственных, страстотерпцев)',
        image: require('./img/22.jpg'),
        adress: 'г. Новосибирск, ул. Зоологическая, 8а',
        coordinates: [54.889595,  83.071611],
        x: 54.889595,
        y: 83.071611,
        link: '',
        text: 'Приход образован в 1998 году, с 2002 года по 2009 год велась реконструкция деревянного здания прихода, после которой храм приобрел свой современный вид.',
    },
    {
        id: 23,
        name: 'Храм в честь Рождества Пресвятой Богородицы',
        new: true,
        synonyms: 'Храм, в, честь, Рождества, Пресвятой, Богородицы',
        image: require('./img/23.jpg'),
        adress: 'г. Новосибирск, ул. Шатурская, 2а',
        coordinates: [54.872185,  83.090537],
        x: 54.872185,
        y: 83.090537,
        link: '',
        text: 'Приход был образован в 1994 году. Храм освящен в 1996 году.',
    },
    {
        id: 24,
        name: 'Храм в честь Благовещения Пресвятой Богородицы',
        new: true,
        synonyms: 'Храм, в, честь, Благовещения, Пресвятой, Богородицы',
        image: require('./img/24.jpg'),
        adress: 'г. Новосибирск, ул. Русская, 8а',
        coordinates: [54.844146, 83.041947],
        x: 54.844146,
        y: 83.041947,
        link: '',
        text: 'В 1995 году начал действовать приход. Храм освящен в 2001 году.',
    },
    {
        id: 25,
        name: 'Храм в честь Всех святых в земле Российской просиявших',
        new: true,
        synonyms: 'Храм, в, честь, Всех, святых, в, земле, Российской, просиявших',
        image: require('./img/25.jpg'),
        adress: 'г. Новосибирск, ул. Терешковой, 35',
        coordinates: [54.836350, 83.119390],
        x: 54.836350,
        y: 83.119390,
        link: '',
        text: 'Приход был организован в 1989 году. В 1991 году - освящен храм. При храме действует детский сад, гимназия и строится дом милосердия.',
    },
    {
        id: 26,
        name: 'Епархиальный мужской монастырь во имя святого Иоанна Предтечи',
        new: true,
        synonyms: 'Епархиальный, мужской, монастырь, во, имя, святого, Иоанна, Предтечи',
        image: require('./img/26.jpg'),
        adress: 'г. Новосибирск, ул. Приморская, 7',
        coordinates: [54.856038, 82.982089],
        x: 54.856038,
        y: 82.982089,
        link: '',
        text: 'Храм во имя Архистратига Михаила был построен в 1914 году. В 1937 был закрыт местными властями, в 1953 году - разобран. В 2003 году был был освящен.',
    },
    {
        id: 27,
        name: 'Епархиальный мужской монастырь в честь Новомучеников и Исповедников Церкви Русской',
        new: true,
        synonyms: 'Епархиальный, мужской, монастырь, в, честь, Новомучеников, и, Исповедников, Церкви, Русской',
        image: require('./img/27.jpg'),
        adress: 'г. Новосибирск, ул. Немировича-Данченко, 120/1',
        coordinates: [54.975059, 82.908293],
        x: 54.975059,
        y: 82.908293,
        link: '',
        text: 'Храм в честь святых Новомучеников Церкви Русской был освящен в 2004 году.',
    },

    {
        id: 28,
        name: 'Часовня в честь иконы Божией Матери "Владимирской"',
        new: true,
        synonyms: 'Часовня, в, честь, иконы, Божией, Матери, Владимирской',
        image: require('./img/28.jpg'),
        adress: 'г. Новосибирск, ул. Сибиряков-Гвардейцев, 7/1',
        coordinates: [54.977680, 82.897544],
        x: 54.977680,
        y: 82.897544,
        link: '',
        text: 'Часовня была освящена в 2002 году и посвящена памяти воинов, погибших во время время Великой Отечественной Войны, и всех пострадавших за веру и Отечество в годы гонений и репрессий.',
    },
    {
        id: 29,
        name: 'Приход в честь Всех святых',
        new: true,
        synonyms: 'Приход, в, честь, Всех, святых',
        image: require('./img/29.jpg'),
        adress: 'г. Новосибирск, ул. Новогодняя, 24',
        coordinates: [54.983706, 82.906407],
        x: 54.983706,
        y: 82.906407,
        link: '',
        text: '',
    },
    {
        id: 30,
        name: 'Часовня во имя святого равноапостольного князя Владимира',
        new: true,
        synonyms: 'Часовня, во, имя, святого, равноапостольного, князя, Владимира',
        image: require('./img/30.jpg'),
        adress: 'г. Новосибирск, ул. Немировича-Данченко, 134 к1',
        coordinates: [54.985922, 82.918411],
        x: 54.985922,
        y: 82.918411,
        link: '',
        text: 'Была возведена в 2008 году на территории областного бюро судмедэкспертизы.',
    },
    {
        id: 31,
        name: 'Приход во имя Святой Троицы',
        new: true,
        synonyms: 'Приход, во, имя, Святой, Троицы',
        image: '',
        adress: 'г. Новосибирск, ул. Саввы Кожевникова, 6',
        coordinates: [54.968824, 82.958244],
        x: 54.968824,
        y: 82.958244,
        link: '',
        text: 'Приъод был образован в 1993 году. Ведется строительство приходского храма.',
    },
    {
        id: 32,
        name: 'Храм в честь Успения Пресвятой Богородицы',
        new: true,
        synonyms: 'Храм, в, честь, Успения, Пресвятой, Богородицы',
        image: require('./img/32.jpg'),
        adress: 'г. Новосибирск, ул. Громова, 19',
        coordinates: [54.933468, 82.909598],
        x: 54.933468,
        y: 82.909598,
        link: '',
        text: 'Приход был организован в 1992 году. В 2007 году был освящен деревянный Успенский храм.',
    },
    {
        id: 34,
        name: 'Часовня в честь Иверской иконы Пресвятой Богородицы',
        new: true,
        synonyms: 'Часовня, в, честь, Иверской, иконы, Пресвятой, Богородицы',
        image: '',
        adress: 'г. Новосибирск, ул. Малыгина, 11а к2',
        coordinates: [54.950355, 82.827966],
        x: 54.950355,
        y: 82.827966,
        link: '',
        text: 'Часовня освящена в 2015 году, находится на территории Клещихинского кладбища.',
    },
    {
        id: 35,
        name: 'Храм в честь иконы Божией Матери "Утоли моя печали"',
        new: true,
        synonyms: 'Храм, в, честь, иконы, Божией, Матери, Утоли, моя, печали',
        image: require('./img/35.jpg'),
        adress: 'г. Новосибирск, ул. Хилокская, 10/1',
        coordinates: [54.950567, 82.828660],
        x: 54.950567,
        y: 82.828660,
        link: '',
        text: 'Нижний храм во имя святого мученика Уара был освящен в 2006 году. Верхний храм в честь иконы Божией Матери "Утоли моя печали" был освящен в 2013 году.',
    },
    {
        id: 36,
        name: 'Приход во имя преподобного Серафима Саровского',
        new: true,
        synonyms: 'Приход, во, имя, преподобного, Серафима, Саровского',
        image: require('./img/36.jpg'),
        adress: 'г. Новосибирск, 1-ый пер. Пархоменко, 3',
        coordinates: [54.989507, 82.873766],
        x: 54.989507,
        y: 82.873766,
        link: '',
        text: 'Приход действует с 1996 года.',
    },
    {
        id: 37,
        name: 'Троице-Владимирский собор',
        new: true,
        synonyms: 'Троице-Владимирский, собор',
        image: require('./img/37.jpg'),
        adress: 'г. Новосибирск, ул. Филатова, 14а',
        coordinates: [54.984761, 82.828915],
        x: 54.984761,
        y: 82.828915,
        link: '',
        text: 'Нижний храм во имя Святой Троицы был освящен в 2008 году. Верхний храм во имя святого равноапостольного князя Владимира был освящен в 2013 году.',
    },
    {
        id: 38,
        name: 'Часовня во имя Георгия Победоносца',
        new: true,
        synonyms: 'Часовня, во, имя, Георгия, Победоносца',
        image: require('./img/38.jpg'),
        adress: 'г. Новосибирск, ул. Римского-Корсакова, 1/1 к1',
        coordinates: [54.987444, 82.883356],
        x: 54.987444,
        y: 82.883356,
        link: '',
        text: 'Часовня освещена в 2002 году, располагается в сквере у Монумента Славы города Новосибирска. Посвящена воинам, погибшим за Отечество.',
    },
    {
        id: 39,
        name: 'Храм во имя святителя Иоанна Шанхайского и Сан-Францисского',
        new: true,
        synonyms: 'Храм, во, имя, святителя, Иоанна, Шанхайского, и, Сан-Францисского',
        image: require('./img/39.jpg'),
        adress: 'г. Новосибирск, ул. Восточный поселок, 13а',
        coordinates: [54.999446, 82.868794],
        x: 54.999446,
        y: 82.868794,
        link: '',
        text: 'Храм находится на территории Епарихального комплексного центра соц. обслуживания граждан, которые остались без жилья. Храм освящен в 2016 году.',
    },
    {
        id: 40,
        name: 'Преображенский кафедральный собор г. Бердска',
        new: true,
        synonyms: 'Преображенский, кафедральный, собор, г., Бердска',
        image: require('./img/40.jpg'),
        adress: 'г. Бердск, ул. Горького, 44',
        coordinates: [54.751761, 83.099624],
        x: 54.751761,
        y: 83.099624,
        link: '',
        text: 'Строительство храма было начато в 1992 году. Нижний храм во имя святителей Василия Великого, Григория Богослова и Иоанна Златоустого был освящен в 1999 году. Верхний храм в честь Преображения Господня был освящен в 2004 году.',
    },
    {
        id: 41,
        name: 'Храм в честь Сретения Господня',
        new: true,
        synonyms: 'Храм, в, честь, Сретения, Господня',
        image: require('./img/41.jpg'),
        adress: 'г. Бердск, ул. Кутузова, 17',
        coordinates: [54.762490, 83.084696],
        x: 54.762490,
        y: 83.084696,
        link: '',
        text: 'Храм освящен в 2009 году. Он был построен вместо старой Сретенской церкви, которая была закрыта в 1939 году, а после затоплена вместе со старым городом.',
    },
    {
        id: 42,
        name: 'Храм в честь Богоявления Господня',
        new: true,
        synonyms: 'Храм, в, честь, Богоявления, Господня',
        image: require('./img/42.jpg'),
        adress: 'г. Бердск, ул. Боровая, 111',
        coordinates: [54.753182, 83.053784],
        x: 54.753182,
        y: 83.053784,
        link: '',
        text: 'Строительство храма было начато по инициативе горожан Бердска. Храм был освящен в 2017 году.',
    },
    {
        id: 43,
        name: 'Храм в честь иконы Божией Матери "Скоропослушница" (архиерейское подворье)',
        new: true,
        synonyms: 'Храм, в, честь, иконы, Божией, Матери, Скоропослушница, (архиерейское, подворье)',
        image: require('./img/43.jpg'),
        adress: 'ст. Мочище, ул. Линейная, 64б',
        coordinates: [55.157402, 83.118341],
        x: 55.157402,
        y: 83.118341,
        link: '',
        text: 'Приход действует с 1993 года. В сентябре 2016 года на храм были установлены купола и кресты. В настоящее время продолжается строительство нового храма.',
    },
    {
        id: 44,
        name: 'Церковь в честь Введения во храм Пресвятой Богородицы',
        new: true,
        synonyms: 'Церковь, в, честь, Введения, во, храм, Пресвятой, Богородицы',
        image: require('./img/44.jpg'),
        adress: 'наукоград Кольцово, 17а',
        coordinates: [54.942790, 83.181535],
        x: 54.942790,
        y: 83.181535,
        link: '',
        text: 'Приход действует с 1994 года. Приходской храм был освящен в 2009 году.',
    },
    {
        id: 45,
        name: 'Храм во имя святителя Николая Чудотворца',
        new: true,
        synonyms: 'Храм, во, имя, святителя, Николая, Чудотворца',
        image: require('./img/45.jpg'),
        adress: 'с. Новолуговое, ул. Первомайская, 3',
        coordinates: [54.979141, 83.108393],
        x: 54.979141,
        y: 83.108393,
        link: '',
        text: 'Храм был построен и освящен примерно в 1926 году. В 1930-х годах многих священников и прихожан расстреляли, а храм закрыли. В 1946 году храм был снова открыт по ходатайству верующих и действует до сих пор.',
    },
    {
        id: 46,
        name: 'Храм в честь иконы Божией Матери "Казанская"',
        new: true,
        synonyms: 'Храм, в, честь, иконы, Божией, Матери, Казанская',
        image: require('./img/46.jpg'),
        adress: 'пос. Краснообск, ул. Западная, 1',
        coordinates: [54.919473, 82.977314],
        x: 54.919473,
        y: 82.977314,
        link: '',
        text: 'Приход был организован в 1992 году. Нижний храм освящен во имя Преподобного Сергия Радонежского. Верхний храм в честь иконы Божией Матери "Казанская" был освящен в 2012 году.',
    }

    ]
  return (
    <div className="App">
      <MapYa points={points} />
    </div>
  );
}

{/* <div className="App">
        {points.map(point => (
            <div key={point.id}>
                <h1>{point.name}</h1>
                <img src={point.img} alt={point.name} />
                <p>{point.text}</p>
                <p>Адрес: {point.adress}</p>
                <MapYa points={point} />
            </div>
        )}
    </div> */}
