import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import sv_Makariy from './img_sobor/svt-makari-icon2.jpg';
import sv_Makariy4 from './img_sobor/sv_Makariy_4.jpg';
import sv_Makariy6 from './img_sobor/sv_Makariy_6.jpg';
import sv_Makariy7 from './img_sobor/sv_Makariy_8.jpg';
import sv_Makariy8 from './img_sobor/sv_Makariy_9.jpg';



function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Святитель Макарий Алтайский</b>
        </div><p></p> 
      



                  {/* св. Макарий Алтайский */}
                    <div> <table>
                        <div class="sv-title">
                            {/* <p>Святитель Макарий, митрополит Алтайский</p> */}
                        </div>
                        <div class="church-1r1-image">
                            <img src={sv_Makariy} width={280}
                                alt="Святитель Макарий"
                                title="Святитель Макарий" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        Святитель Макарий возглавляет сонм святых Новосибирской митрополии не случайно. 
                        Территория современной Новосибир­ской области до революции 1917 входила в Томскую губернию 
                        (Новониколаевский и Каинский уезды). С 1891 по 1912 
                        год все храмы Новониколаевского и Каинского уездов находились под омофором святителя Макария. 
                       <p></p>
                       <div>Митрополит Макарий (в миру Михаил Андреевич Невский, при рождении Парвицкий) 
                        (1835-1926) — митрополит Московский и Коломенский (1912-1917), 
                        митрополит Алтайский (с 1920 года). Известный миссионер, «апостол Алтая».</div>
                       <div>Будущий святитель родился в 1835 г. в с. Шапкино Ковровского уезда Владимирской губернии. 
                        Он был шестым ребенком в бедной семье причетника сельского храма Андрея Парвицкого. 
                        В 1843 г. семья переселилась в Сибирь. 
                        В Тобольске Михаил поступил в семинарию, где и получил фамилию Невский.</div>
                       <div>По окончании Тобольской семинарии он был принят в Алтайскую духовную миссию в 1855 г. 
                        в звании учителя и миссионерского сотрудника. Молодому труженику, посвятившему свою жизнь 
                        миссионерству, было явление архимандрита Макария (Глухарёва), ныне прославленного в лике святых. 
                        Преподобный Макарий (Глухарёв) (1792-1847), основавший в 1830 году Алтайскую миссию, создал 
                        на основе кириллицы алтайскую азбуку, сделал первые переводы на наречия алтайцев библейских 
                        текстов и катехизаторской литературы, положив, таким образом, начало их письменности. 
                        В честь него алтайцы называли впоследствии святителя Макария (Невского) «вторым Макарием».</div>
                        
                        
                       <div>Первые годы в Алтайской миссии Михаил Невский посвятил изучению языка алтайцев. 
                        Дело шло весьма трудно. Тогда он стал усиленно молиться Матери Божией и вскоре чудесным образом
                         овладел новым языком. Молодой проповедник все свое время проводил в далеких аилах, проповедуя 
                         истинную веру. 
                         <div class="church-1l-image">
                            <img src={sv_Makariy4} width={280}
                                alt="Святитель Макарий"
                                title="Святитель Макарий" />
                        </div>
                         С 1857 г. Михаил Невский становится рясофорным послушником. В 1861 г. он принял 
                         монашеский постриг с именем Макарий в память преподобного Макария Великого, в том же году 
                         состоялась его иерейская хиротония. После этого иеромонах Макарий направляется на миссионерское 
                         служение в Чемальский стан. Нередко о. Макарий действовал как врач. Хотя знания его были весьма 
                         простые, аптечка скудной, он доставлял облегчение людям усиленной молитвой. Господь нередко 
                         через него совершал чудеса. Известен случай, 
                        когда отец Макарий высосал смертельный яд из ноги мальчика, укушенного змеей, и остался жив.</div>
                       <div>Выдающимся вкладом в дело алтайского миссионерства была переводческая и издательская 
                        деятельность владыки Макария. В 1860-е годы в Санкт-Петербурге им были напечатаны на алтайском 
                        языке Литургия св. Иоанна Златоуста, Священная история Нового Завета, Огласительные поучения 
                        для готовящихся ко Св. Крещению, Последование Св. Крещения и ряд богослужебных книг. Большая 
                        часть этих книг была переведена о. Макарием, некоторые были переведены до него, а им были 
                        пересмотрены и исправлены, 
                        при этом окончательно была установлена транскрипция алтайской письменности.</div>
                       <div>Назначенный в 1865 г. начальником Алтайской миссии архимандрит Владимир (Петров) направил 
                        иеромонаха Макария в Казань для ознакомления с организацией крещено-татарских школ по системе 
                        Н.И. Ильминского. В 1868-1869 гг. отец Макарий не только подробно изучил деятельность профессора 
                        Н.И. Ильминского, но и сам участвовал в жизни крещено-татарских школ, совершая богослужения 
                        на татарском языке.
                        <div class="church-1r-image">
                            <img src={sv_Makariy6} width={280}
                                alt="Святитель Макарий"
                                title="Святитель Макарий" />
                        </div> 
                        По возвращении из Казани иеромонах Макарий устроил в Чопоше по типу крещено-татарских школ 
                        училище на семьдесят человек с общежитием. Отец Макарий привлекал учащихся к переводческой 
                        деятельности. В результате появилась целая библиотека 
                        христианской литературы: переводной и оригинальной, печатной и рукописной.</div>
                        
                       <div>В 1871 г. иеромонах Макарий был возведен в сан игумена, а в 1875 г. стал помощником начальника 
                        Алтайской миссии. На игумена Макария была возложена ответственность ежегодно обозревать все 
                        миссионерские станы и наблюдать за состоянием школьного дела. Опытный и ревностный руководитель 
                        значительно оживил дело просвещения и обучения алтайцев.
                        
                        В 1883 г. отец Макарий был возведен в сан архимандрита, а в 1884 г. рукоположен в епископа
                         Бийского. В целях противораскольнической деятельности епископ Макарий учредил в 1884 г. 
                         в Бийске Братство святителя Димитрия Ростовского.</div></div>
                         <div class ={"inf-5"}>В 1891 году Владыка Макарий был переведен на Томску кафедру. 
                         С 1891 по 1895 год он носил титул епископа Томского и 
                        Семипа­латинского. С 1895 по 1906 год он именовался епископом Томским и Барнаульским. 
                        В 1906 году — возведён в сан архиепископа. После учреждения в октябре 1908 году Барнаульского
                         викариатства стал именоваться архиепископом Томским и Алтайским. В конце 1912 года он получил 
                        сан митрополита и был возведён на кафедру митрополитов Московских и Коломенских.</div>
                        <div class ={"inf-5"}>Будучи томским архиереем, Владыка Макарий многократно посещал приходы 
                        своей обширнейшей епархии. В Новониколаевске в 1899 году 
                        он освятил Александро-Невский храм (ныне Александро-Невский собор Новосибирска).
                      
                       <div>После перевода на Томскую, а затем на Московскую кафедры святитель 
                        Макарий не оставлял попечения об Алтайской миссии и был ее духовным руководителем.</div>
                        <div class="church-1l-image">
                            <img src={sv_Makariy7} width={280}
                                alt="Святитель Макарий"
                                title="Святитель Макарий" />
                        </div> 
                       
                       <div>После февральской революции, постановлением от 20 марта 1917 г. Священный Синод почислил 
                        митрополита Московского Макария 
                        на покой за преклонностью лет. Этому решению предшествовали грубые действия обер-прокурора 
                        Св. Синода В.Н. Львова. Митрополиту не разрешили жить в Троице-Сергиевой лавре. Он переехал 
                        в Зосимову Смоленскую пустынь, а затем местом его пребывания 
                        определили подмосковный Николо-Угрешский монастырь, где владыка прожил восемь лет.</div>
                       <div>В 1920 г. владыку постигла болезнь, из-за которой он оставался частично парализован 
                        до конца жизни. В августе 1920 г. по предложению Святейшего Патриарха 
                        Тихона Владыке Макарию пожизненно был присвоен титул митрополита Алтайского.</div>
                       <div>В феврале 1926 года митрополит Алтайский Макарий заболел воспалением легких. 
                        Вскоре, 1 марта, на 91-м году жизни, владыка отошел ко Господу. Святитель был похоронен 
                        около алтаря церкви 
                        с. Котельники близ Люберец, где он жил с 1925 года после закрытия Николо-Угрешского 
                        монастыря.</div>
                        <div>В 1957 году Святейший Патриарх Московский и всея Руси Алексий I
                        (Симанский) благословил перенести честные останки митрополита
                        Макария в Троице-Сергиеву лавру. В апреле они были освидетельствованы представителями Московской Патриархии: оказалось, что гроб
                        истлел, а тело и облачение остались целыми. О нетлении мощей святителя Макария свидетельствовал камчатский миссионер — митрополит
                        Нестор (Анисимов). Святые останки были перенесены в лавру и захоронены в крипте Успенского собора. 
                        В 1988 году в крипте был устроен престол в честь Всех святых, в земле Российской просиявших.</div>
                        <div>В 2000 году Юбилейным Архиерейским
                        Собором Русской Православной Церкви митрополит Макарий (Невский), «занимавший
                        с 1912 по 1917 год Московскую кафедру, ревностный миссионер, просветитель Алтайского края, молитвенник и аскет, обильно
                        облагодатствованный дарами Святого Духа»,
                        был прославлен в лике святых. </div>
                        <div>1 августа 2016 года совершилось перенесение святых мощей святителя 
                        Макария из Свято-Троицкой Сергиевой лавры в Горно-Алтайск.
                        Мощи святителя почивают в кафедральном храме Горно-Алтайска во имя преподобного
                        Макария, основателя Алтайской Духовной Миссии. Так во святых
                        мощах святитель Макарий возвратился на Алтай. </div>
                        </div> <p></p>
                        </table>
                         
                        <div class="church-1l-image">
                            <img src={sv_Makariy8} width={480}
                                alt="Святитель Макарий"
                                title="Святитель Макарий" />
                        </div> 
                        
                        <table>
                          <div class="sv-title">Тропарь, глас 4</div>
                        <div className="inf-4">Божественныя веры проповеданием, землю Сибирскую озарил еси, теплоту и свет
                        Христов источая, ко Христу многия люди привел еси, первопрестольному граду
                        Москве и всея России преславное украшение явился еси, апостольским деянием
                        ревнуя, созидал еси Церковь Христову, пастырю наш добрый святителю Макарие,
                        моли Христа Бога спастися всем нам.</div>
                        <p></p>
                        <div class="sv-title">Кондак, глас 3</div>
                        <div className="inf-4">Святителю Христу Богу возлюбленне, правило веры и образ 
                        милосердия людем
                        был еси, проповедию своею и многими труды языки алтайския ко Христу привел еси,
                        сам же в подвизех пребывая, от Бога нетлением на земли увенчался еси, духом же
                        Престолу Божию предстоя, молися ныне Господеви да укрепит нас во православии
                        и благочестии, и спасет души наша.</div>
                        <p></p>
                        <div class="sv-title">Молитва</div>
                        <div className="inf-4">О пречестная и священная главо и благодати Святаго Духа исполненная, Спасово
                        со Отцем обиталище, великий архиерее, теплый наш заступниче, святителю Макарие!
                        Предстоя у Престола всех Царя и наслаждаяся света единосущныя Троицы и херувимски 
                        со Ангелы возглашая песнь Трисвятую, великое же и неизследованное дерзновение имея ко 
                        Всемилостивому Владыце, моли спастися паствы Христовы людем, благостояние Святых Церквей утверди, 
                        архиереи благолепием святительства украси,
                        монашествующия к подвигом добраго течения укрепи, наш град и вся грады и страны
                        добре сохрани и веру святую непорочну соблюсти умоли, мир весь предстательством
                        твоим умири, от глада и пагубы избави ны и от нападения иноплеменных сохрани; старыя утеши, 
                        юныя настави, безумныя умудри, вдовицы помилуй, сироты заступи, младенцы возрасти, плененныя 
                        возврати, немощствующия исцели, и везде тепле призывающия тя и с верою притекающия к тебе и 
                        усердно припадающия и молящияся тебе
                        от всяких напастей и бед ходатайством твоим свободи. Моли о нас всещедраго
                        и человеколюбиваго Христа Бога нашего, да и в день страшнаго Пришествия Его от
                        шуияго стояния избавит нас, и радости святых причастники сотворит со всеми святыми во веки 
                        веков. Аминь</div>
                        </table>
                        
                          
                    
                    </div>        
      


             
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
