import React from 'react'
import emblem from "./region/Severniy/herb2.png"
import a1 from "./region/Severniy/a1.jpg"
import church_1_image from "./region/Severniy/h1.jpg"
import church_2_image from "./region/Severniy/h3.jpg"

function Severniy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} alt="Флаг " />
                    </div>
                    <h1 className='head'>Северный район</h1>
                </div>
                
                <div class="about-region">
                   
                    
                    <div class="inf-4">
                        <p>
                            Район образован в 1933 в составе Западно-Сибирского крaя. 
                            В 1937 район был включен во вновь образованную Новосибирскую область.
                            Самый малонаселенный район Новосибирской области, при этом самый большой по площади.  
                            Территория района 1554,8 тыс. га,  Население 9066 чел. 
                            
                            Административный центр — село Северное.</p>
                    </div>
                    <div class="center-image">
                <img src={a1} width={700} alt="Северный р-н" title="Северный район" />
                      </div>
                </div>
                <div class="churches">





                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Северного района</h2>
                    </div>

                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Церковь Живоначальной Троицы, с. Северное
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={400} alt="Церковь Живоначальной Троицы"  />
                        </div>
                        <div class ={"inf-4"}>
                        Приход в честь Святой Троицы был образован 
                        5 января 1996 года и зарегистрирован 6 февраля 1996 года. 
                        Приходу было передано здание, которое переоборудовали под 
                        храм, и в нем начали совершаться службы. 
                        <div> Новый построенный храм был освящен в 2017 году.  </div> </div>
                        <p><div class ={"inf-4"}><b>Адрес: </b> с. Северное, ул. Ленина, 19а. </div></p>
                        
                    </div>

                    </table>
                    
                    <p></p>
                    
                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Храм во имя Архистратига Божия Михаила, с. Биаза
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={400} alt="Храм во имя Архистратига Божия Михаила"  />
                        </div>
                        <div class ={"inf-4"}>
                        В с. Биязинское (ныне с. Биаза) до революции была деревянная однопрестольная церковь
                        во имя Архистратига Божия Михаила. Церковь была закрыта постановлением 
                        Запсибисполкома от 5 января 1936 года. Впоследствии церковь была разрушена. 
                        <div>В память о церкви в конце XX века в селе было начато строительство деревянного храма-часовни 
                        во имя Михаила Архангела, который был освящен 26 июня 2009 года.
                        Храм построили на новом месте, на перекрёстке улиц Бугаёва и Садовой, 
                        возле реки Биазинка.  </div>
                        <div>  </div> </div>
                        
                        
                    </div>

                    </table>

                
                </div>




                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2></h2>
                        </div>
                        <div class="attraction-1">
                            
                        </div>
                        <div class="attraction-1">
                            
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
    );
}

export default Severniy