import React from 'react'
import flag from "./region/Chulimskiy/hh1.png"
import './main.css'
//import flag from "./region/Chulimskiy/herb1.jpg"
import admin from "./region/Chulimskiy/admin.jpg"
import church_1_image from "./region/Chulimskiy/h3.jpg"
import aa1 from "./region/Chulimskiy/aa1.jpg"
import oo1 from "./region/Chulimskiy/oo1.jpg"


function Chulimskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={flag} alt="Флаг Баганск" title="Герб" />
                    </div>
                    <h1 className='head'>Чулымский район</h1>
                </div>
                
              
                
                <div class="about-region">
                
                        <div class="inf-4">
                        Административный центр — город Чулым. Территория района – 855,9 тыс. га 
                        Население 20874 чел. Восточное благочиние Каинской епархии.
                        Район образован в 1925 году в составе Новосибирского округа Сибирского края, ранее эти 
                        земли входили в состав Каинского района.
                        В 1930 году Сибирский край был разделен на западную и восточную части, и район оказался 
                        в Западно-Сибирского края.
                        В 1937 году район был включён в состав новообразованной Новосибирской области.
                    
                        
                    </div>
                    
                </div>
              
               <p></p>
               
                <div class="center-image"><img  src={admin} width={700}/> </div>
               
               
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Чулымского района</h2>
                    </div>
                   <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Церковь Рождества Пресвятой Богородицы</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={500} alt="Церковь Рождества Пресвятой Богородицы"
                                title="Церковь Рождества Пресвятой Богородицы" />
                        </div>
                    
                    <div class ={"inf-4"}>
                    Первые богослужения в г. Чулыме Новосибирской области начали совершаться в 1992 г. в здании бывшего магазина, 
                    которое было усилиями жителей Чулыма переоборудовано под храм. 
                    Освящение этого храма было совершено Епископом Новосибирским и Бердским Тихоном. 
                    
                    <div>Осенью 2005 г. в городе Чулыме началось строительство нового храма в честь Рождества Пресвятой Богородицы. 
                    
                    Районные власти выделили для строительства храма площадку практически в самом центре Чулыма.
                        
                       

                            В июле 2008 года были освящены купола храма, а 23 октября 2009 г. построенный 
                                храм в честь Рождества Пресвятой Богородицы был открыт и освящен.</div>
                    
                            
                    <p><b>Адрес:</b> г. Чулым, ул. Кооперативная, 14.</p>  
                    </div> 
                    </div>
                    </table>

                </div>
            
                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Чикманский заказник</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={aa1} width={700} /*alt="Озеро Горькое" title="Озеро"*/ />
                            </div>
                        </div>



                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Иткуль</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={oo1} width={700} /*alt="Озеро Горькое" title="Озеро"*/ />
                            </div>
                        </div>

                        
                
                
                    
                


                
                </div>
            </section>
        </div>);
}

export default Chulimskiy






/*
import React from 'react'
import flag from "./region/Chulimskiy/herb1.jpg"
import emblem from "./region/Chulimskiy/hh1.png" //"./region/Chulimskiy/herb2.png"
import admin from "./region/Chulimskiy/admin.jpg"
import church_1_image from "./region/Chulimskiy/h1.jpg"
// import church_2_image from "./region/Chulimskiy/h2.png"
function Chulimskiy() {
    return ( 
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
            <div class="flag">
                        <img src={emblem} alt="Герб" title="Герб" />

                    </div>
                <div class="title-region">
                <h1 className='head'>Чулымский район.</h1>
                
                
                </div>

                
               
        
                <div class="about-region">
                    
                    
                    <div class="text">
                        <p>Административный центр — город Чулым. Территория района – 855,9 тыс. га Население 20874 чел. Восточное благочиние Каинской епархии.
                            Район образован в 1925 году в составе Новосибирского округа Сибирского края, ранее эти земли входили в состав Каинского района. В 1930 году Сибирский край был разделен на западную и восточную части, и район оказался в Западно-Сибирского края. В 1937 году район был включён в состав новообразованной Новосибирской области.</p>
                    </div>
                    <div class="admin-1-image">
                        <img src={admin} width={600} alt="амин" title="амин" />
                    </div>
                </div>
                <div class="info-region">
                    
                    
                </div>
                <div class="churches">
                    <div class="churches-titile">
                        <h2>Церкви Чулымского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Церковь Рождества Пресвятой Богородицы.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Церковь Рождества Пресвятой Богородицы" title="Храм1" />
                        </div>
                    </div>
                </div>

                <div class="attractions">
                        <div class="attractions-title">
                            <h2>Природное достояние:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Памятник природы областного значения "Филимоновский рям";</p>
                            </div>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p> Река Чулым;</p>
                            </div>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Иткуль;</p>
                            </div>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Культурно-историческое наследие;</p>
                            </div>
                        </div>
                    </div>

            </section>
        </div>
    );
}

export default Chulimskiy
*/