import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './fonts/Great_Vibes/GreatVibes-Regular.ttf';
import './fonts/Alumni_Sans_Collegiate_One/AlumniSansCollegiateOne-Italic.ttf';
import './fonts/Alumni_Sans_Collegiate_One/AlumniSansCollegiateOne-Regular.ttf';

// import 'bootstrap/dist/css/bootstrap.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
