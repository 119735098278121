import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import sv_Nikolay from './img_sobor/ermolov1905.jpg'
import sv_Nikolay1 from './img_sobor/nsk-nik-ermolov-photo.jpg'
import sv_prosl from './img_sobor/proslavlenie.jpg'


function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Священномученик Николай Ермолов</b>
        </div><p></p> 
      


                  {/* свщмч. Николай Ермолов */}
                   <div> <table>
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1r1-image">
                            <img src={sv_Nikolay} width={280}
                                alt="Священномученик Николай пресвитер"
                                title="Священномученик Николай пресвитер" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        Священномученик Николай Ермолов родился в 1874 году в селе Секретарка Бугульминского уезда 
                        Самарской губернии в семье псаломщика. В 1897 году окончил Самарскую духовную семинарию, 
                        был определён псаломщиком и учителем в г. Бу­гульме. В 1900 году стал священником, до 1906 года 
                        служил в Михаило-Архангельском храме села Шаран Белебеевского уезда Уфимской губернии, 
                        а после — в Михаило-Архангельском храме села Байки Бирского уезда той же губернии. 
                        В 1919 году вместе с отступающей 
                        армией Колчака отправился в Сибирь, где в это время в Новониколаевске жил его брат. </div>
                        <div class ={"inf-5"}>В 1920 году священник был определён на служение в Покровский храм села 
                        Доронино Барнаульского уезда (ныне Тогучинский район Новосибирской области). Летом того же года 
                        в районе села Колывань, за 140 километров от Доронина, вспыхнуло антибольшевистское восстание, 
                        вошедшее в историю как Сибирская Вандея. Отец Николай ничего не знал о восстании, но его 
                        арестовали по нелепому обвинению в причастности к нему. 
                        В течение девяти месяцев он находился в тюремной больнице и впоследствии без допроса был 
                        освобождён.</div>
                        <div class ={"inf-5"}>С 1924 года отец Николай служил настоятелем Никольской церкви в селе 
                        Ордынском Новосибирской области. По воспоминаниям сына Сергея, он часто не назначал плату 
                        за крещение, молебен, панихиду, лишь говорил: «Сколько можете». С бедных вообще не брал плату, 
                        наоборот, старался чем-то помочь. Священники окружающих сёл уважали его, приезжали за советом, 
                        с некоторыми он дружил, например с  отцом Иннокентием 
                        Кикиным из села Нижне-Каменского, которое располагалось на другом берегу Оби, напротив Ордынского. 
                        <div>В 1928 году отец Николай был удостоен сана протоиерея. За усердное служение Церкви Христовой 
                          и мужественное противостояние 
                          обновленческому расколу священноначалие назначило его благочинным Ордынского округа. </div>
                          <div>В 1933 году отца Николая вновь арестовали. Ордынский сельсовет наложил на него налог: в трёхдневный срок сдать государству 100 килограммов мяса. Непосильный налог часто становился предлогом для лишения священника права совершать богослужение в церкви, его дальнейшего ареста и высылки. Конечно, выполнить это требование отец Николай не мог, и потому он был арестован якобы за неуплату налога. В этот раз заточение продлилось два месяца.</div>
                          <div>В начале мая 1937 года был арестован архиепископ Новосибирский Сергий (Васильков) вместе с рядом священнослужителей и мирян по делу так называемого «Сибирского церковно-монархического центра», якобы имевшего связи с рядом иностранных разведок и белоэмигрантских организаций. 25 июля 1937 года все обвиняемые по этому делу тройкой УНКВД по Западно-Сибирскому краю были приговорены к высшей мере наказания. Не дожидаясь их расстрела, органы НКВД в конце июля провели новые аресты среди духовенства. Теперь по делу «Сибирского церковно-монархического центра» проходил сразу 101 человек, включая духовенство Ордынского округа.</div>
                          <div class="church-1l-image">
                            <img src={sv_Nikolay1} width={280}
                                alt="Священномученик Николай пресвитер"
                                title="Священномученик Николай пресвитер" />
                        </div>
                          <div>27 июля 1937 года был арестован и отец Николай Ермолов. Вместе с ним арестовали несколько человек, в том числе священника Иннокентия Кикина.</div>
                          <div>Сначала заключённые содержались в Ордынском, где у них добивались признания в том, что они якобы вели активную контрреволюционную работу, собирая вокруг себя недовольные и кулацкие элементы, распространяли провокационные слухи о скором падении советской власти, организовывали диверсионные акты в колхозах.</div>
                          <div>На все вопросы следователей отец Николай твёрдо отвечал: «Руководителем повстанческой контрреволюционной организации я не состоял и даже об этой организации ничего не знал и не слышал и контрреволюционной деятельностью никогда не занимался». Гонители Церкви хотели обвинить его в том, что он якобы готовил поджог паровой мельницы в колхозе «Сибирское Красное Знамя» и здания начальной школы в деревне Ирменка. Но и эти показания некоей лжесвидетельницы отец Николай также категорически отверг.</div>
                          <div>Несмотря на это в обвинительном заключении арестованным вменили в вину то, что они, «имея непосредственную связь с руководителями контрреволюционного террористического шпионско-диверсионного центра церковников в Запсибкрае, продолжительный период времени проводили в Ордынском районе контрреволюционную повстанческую и подрывную работу, которую возглавлял протоиерей-благочинный Ермолов. Формировали вокруг себя недовольные кулацкие повстанческие элементы на случай 
                            войны для ослабления тыла внутри страны путём восстаний и совершения диверсионных актов».</div>
                          <div>После этого все обвиняемые были этапированы в Новосибирск.</div>
                          <div>1 октября 1937 года особая тройка УНКВД по Западно-Сибирскому краю приговорила 
                            протоиерея Николая Ермолова к высшей мере наказания — расстрелу с конфискацией имущества. 
                            Мужественный страдалец за Христа был расстрелян 26 октября 1937 года в Новосибирске и погребён 
                            в общей безвестной могиле.</div>
                            <div>Прошло время, и неправда мучителей была явлена всему миру. 
                              В 1958 году Постановлением Президиума Новосибирского областного суда 
                              постановление особой тройки НКВД от 1 октября 1937 года было отменено и дело в отношении 
                              протоиерея Николая Ермолова прекращено за отсутствием состава преступления.</div>
                              <div class="church-1l-image">
                            <img src={sv_prosl} width={350}
                                alt="Прославление святых"
                                title="" />
                          </div>
                              <div>24 апреля 2002 года Святейшим Патриархом Московским и всея Руси Алексием II и 
                                Священным Синодом Русской Православной Церкви было принято решение о прославлении 
                                протоиерея Николая Ермолова и священника Иннокентия Кикина в лике священномучеников 
                                для общецерковного почитания и включения их имён в Собор новомучеников и исповедников
                                Церкви Русской. Торжественное прославление священномучеников Николая и Иннокентия, 
                                пресвитеров Новосибирских, состоялось 23 мая 2002 года во время всенощного бдения в 
                                Вознесенском кафедральном соборе города Новосибирска. Определение об их канонизации 
                                зачитал Святейший Патриарх Московский и всея Руси Алексий II. </div>
                              <div>
Служба священномученикам Николаю и Иннокентию, пресвитерам Новосибирским, была утверждена Священным Синодом Русской Православной Церкви 28 декабря 2017 года.
Память священномученика Николая Ермолова празднуется в день его мученической кончины — 13 октября (26 октября н.ст.), в Соборе святых Новосибирской митрополии 16 февраля (29 февраля н.ст.) и в Соборе новомучеников и исповедников Церкви Русской (первое воскресенье, начиная с 25 января (7 февраля н.ст.)).
</div>
                      </div> 
                      
                    </table>
                    </div>   
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
