import React from 'react'
import flag from "./region/Karasukskiy/herb1.png"
import emblem from "./region/Karasukskiy/ee1.png"
import attraction_1_image from "./region/Karasukskiy/priroda1.jpg"
import church_1_image from "./region/Karasukskiy/h1.jpg"
import church_2_image from "./region/Karasukskiy/h2.jpg"
import church_3_image from "./region/Karasukskiy/h3.jpg"
import church_4_image from "./region/Karasukskiy/h4.jpg"
import ad from "./region/Karasukskiy/ad.jpg"

import oo1 from "./region/Karasukskiy/oo1.jpg"


function Karasukskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Карасукский район</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} />
                    </div>
                    <h1 className='head'>Карасукский район</h1>
                </div>
                    <div class="about-region">
                    
                        <div class="inf-4">
                        <p>
                        Образован в 1925 году. При создании Сибирского края, на территории нынешнего
                            
                        Карасукского района был создан Чёрно-Курьинский район с центром в селе Чёрно-Курья, который вошёл в
                            состав Славгородского округа Сибирского края;
                        Карасукским районом тогда назывался нынешний
                            Краснозёрский район.
                        <div>Позже райцентр был перенесён из села Чёрно-Курья в посёлок при станции Карасук,
                            а район переименован в Карасукский. В 1944 году был включён в Новосибирскую область. 
                            Население — 42 515 чел. </div>
                        </p>
                        </div>
                        <div class="center-image"><img src={ad} width={700} /></div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Храмы Карасукского района</h2>
                    </div>
                   
                    
                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Кафедральный Собор во имя святого апостола Андрея Первозванного
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={600}/>
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Кафедральный собор во имя Святого апостола Андрея Первозванного в городе Карасуке был построен в 2006 году, 
                    при помощи Западно-Сибирской железной дороги, и освящен 26 октября 2006 года. 
                                
                    <div>По архитектурному стилю относится к смешанному типу храмовых зданий, в котором присутствуют как византийские, 
                    так и русские архитектурные церковные традиции. Собор построен по проекту храма в поселке городского типа Коченёво, 
                    Новосибирской области, с некоторыми изменениями. </div>
                        <div> Здание имеет форму креста в соответствии с древней христианской традицией и символикой. 
                    Сооружено, в основном, из кирпича красного цвета. Внутри собор разделен на три основных части: 
                    притвор, средняя часть и алтарь. Вместимость – около 300 чел.</div>
                    <p><b>Адрес:</b> г. Карасук, ул. Октябрьская, 31.</p>  
                    </div> 
                    </table>
                   

<p></p>
                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм во имя святого равноапостольного великого князя Владимира
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={600}/>
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    В 1993 году администрация города выделила приходу старое здание бывшего телеателье, 
                    в котором в 1925 году размещалась первая школа. По воспоминаниям старожилов, 
                    здание было построено из материалов разрушенной церкви. 
                    С помощью прихожан здание храма отремонтировали, и оно приобрело новый облик. 
                    Храм был назван в честь святого равноапостольного великого князя Владимира.

                         
                   
                   
                    <div>В 1995 году начался сбор средств на колокольню и началось ее строительство
                        За летний период колокольня была воздвигнута. 
                         В 2003 году началось строительство нового храма, 
                        был возведен фундамент. Но строительство продолжалось довольно долго. 
                        Освящение нового храма состоялось 25 июля 2020 года. </div>
                       
                    <p><b>Адрес:</b>  г. Карасук, ул. Луначарского, 4</p>  
                    </div> 

                    </table>
<p></p>

                   <table>
                    <div class="church-3">
                        <div class="church-1-title">
                            Храм Покрова Пресвятой Богородицы, с. Белое
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={600}/>
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Старый храм  в честь Покрова Пресвятой Богородицы 
                    в селе Белом Карасукского района был построен в 1905 году, в 1960 году его разрушили. 
                    <div>Строительство нового храма продолжалось около 2-х лет/
                    20 октября 2018 года его Преосвященство епископ Филипп возглавил освящение и 
                    Божественную литургию во вновь построенном храме 
                    в честь Покрова Пресвятой Богородицы.</div>


                    
                    <p><b>Адрес:</b> с. Белое, ул. Ленина, 46.</p>  
                    </div> 
                    </table>


                    <table>
                        {/*
                    <div class="church-4">
                        <div class="church-1-title">
                            <p>Часовня святого Николая Чудотворца.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_4_image} width={600}/>
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    В 1993 году по ходатайству правящего архиерея Епископа Новосибирского и Барнаульского Тихона распоряжением 
                    Главы администрации Баганского района Новосибирской Епархии Русской Православной Церкви было 
                    безвозмездно передано здание художественной мастерской, состоящей на балансе районного отдела культуры. 
                    Затем закреплен в бессрочное пользование и земельный участок площадью 0,18 га. 
                    <p>Так у православных Баганского района появилось свое здание для совместной молитвы. Было много 
                        трудностей, не было священника. Один раз в месяц из г. Карасука приезжал батюшка и совершал 
                        богослужения и требы, но Богу содействующему, община духовно крепла и умножалась. Общими усилиями прихожан приобреталось 
                        необходимое богослужебное имущество, устраивалась приходская жизнь. </p>
                        <p> К празднику Рождества Христова в 1993 году православный приход получил святой Антиминс, 
                            на котором и совершается Евхаристия. 
                            Помещение прихода, с внесением в него святого Антиминса, получило статус храма в Честь Успения Пресвятой Богородицы.</p>
                    <p>Адрес: с. Баган, ул. Вокзальная, 10.</p>  
                    </div> 
                    */}
                    </table>


                    
                </div>

               
                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Солёное</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={oo1} width={700} alt="Озеро Горькое" title="Озеро" />
                            </div>
                        </div>
                    </div>

                    
            </section>
        </div>);
}

export default Karasukskiy



/*

function Karasukskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Карасукский район (Кузьмина)</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг Карасукского района" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб Карасукского района" title="Герб" />
                    </div>
                    <div class="text">
                        <p>Население - 42 515 чел. Образован в 1925 году. При создании Сибирского края, на территории нынешнего
                            Карасукского района был создан Чёрно-Курьинский район с центром в селе Чёрно-Курья, который вошёл в
                            состав Славгородского округа Сибирского края; Карасукским районом тогда назывался нынешний
                            Краснозёрский район. Позже райцентр был перенесён из села Чёрно-Курья в посёлок при станции Карасук,
                            а район переименован в Карасукский. В 1944 году был включён в Новосибирскую область.</p>
                    </div>
                </div>
                <div class="info-region">
                    <div class="attraction">
                        <div class="attraction-title">
                            <h2>Достопримечательности Карасукского района:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Административный центр - город Карасук.</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_1_image} alt="Административный центр - город Карасук"
                                    title="Карасук" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="churches">
                    <div class="churches-title">
                        <h2>Храмы Карасукского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Кафедральный Собор святого апостола Андрея Первозванного.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Кафедральный Собор святого апостола Андрея Первозванного"
                                title="Храм1" />
                        </div>
                    </div>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>храм святого князя Владимира.</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} alt="храм святого князя Владимира" title="Храм2" />
                        </div>
                    </div>
                    <div class="church-3">
                        <div class="church-3-title">
                            <p>Храм Покрова Пресвятой Богородицы.</p>
                        </div>
                        <div class="church-3-image">
                            <img src={church_3_image} alt="Храм Покрова Пресвятой Богородицы" title="Храм3" />
                        </div>
                    </div>
                    <div class="church-4">
                        <div class="church-4-title">
                            <p>Часовня святого Николая Чудотворца.</p>
                        </div>
                        <div class="church-4-image">
                            <img src={church_4_image} alt="Часовня святого Николая Чудотворца" title="Храм4" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Karasukskiy

*/