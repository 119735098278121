import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import ww from "./vozn.jpg";
import berdsk from "./Berdsk.jpg";
import hjk from "./xxl.jpg";
import nsk1 from "./vid_na_khram_nevskogo.jpg";
import Arh7 from "./img_arh/vl-leontiy.jpg";
import Arh1 from "./img_arh/sofroniy.jpg";
import Arh6 from "./img_arh/vl-donat.jpg";
import Arh2 from "./img_arh/Nikifor.jpeg";
import Arh5 from "./img_arh/vl-nestor.jpg";
import Arh3 from "./img_arh/vl-sergiy.jpg";
import Arh4 from "./img_arh/vl_varfolomei.jpg";
import Arh8 from "./img_arh/vl-kassian.jpg";
import Arh9 from "./img_arh/vl-pavel.jpg";
import Arh10 from "./img_arh/vl-Gedeon.jpg";
import Arh11 from "./img_arh/vl-Tihon.jpg";
import Arh12 from "./img_arh/vl-Nikodim.jpg";
import Arh13 from "./img_arh/vl_sergiy_sokolov.jpg";
import Arh14 from "./img_arh/vladimir.jpeg";
import Arh15 from "./img_arh/leonid.jpg";
import Arh16 from "./img_arh/filip.jpg";
window.scrollTo(0, 0);
function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
      <p></p>
      <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Из истории Новосибирской Епархии</b>
      </div>
      <p></p>

      <div className="all_ep" style={{ filter: isActive ? "blur(150px)" : "" }}>
        {" "}
        {/* <a href="/Kainsk" className="h">
          Каинская епархия
        </a>{" "} 
        <a href="" className="h">
          Карасукская епархия
        </a>{" "}
        <a href="" className="h">
          Искитимская епархия{" "}
        </a>{" "}
        <a href="" className="h">
          Новосибирская митрополия
        </a> */}
        {/* <Link class='sem' to='/kainsk'><div class="h" style={{ fontSize: isActive ? "0" : "" }}><li><i>Каинская епархия</i></li></div></Link>
        <Link class='sem' to='/Karasuk'><div class="h" style={{ fontSize: isActive ? "0" : "" }}><li><i>Карасукская епархия</i></li></div></Link>
        <Link class='sem' to='/Iskitim'><div class="h" style={{ fontSize: isActive ? "0" : "" }}><li><i>Искитимская епархия</i></li></div></Link>
        <Link class='sem' to='/ep'><div class="h" style={{ fontSize: isActive ? "0" : "" }}><li><i>Новосибирская митрополия</i></li></div></Link>
      */}
      </div>
      <div className="history" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <img src={nsk1} width={600}></img>
        <p></p>

        <div class={"inf-5"}>
          Территория современной Новосибирской области в начале XX века входила
          в Томскую епархию. До 1924 года на территории современной
          Новосибирской области было 310 православных храмов и молитвенных
          домов.{" "}
        </div>
        <div class={"inf-5"}>
          {" "}
          В сентябре 1924 года в Москве была совершена архиерейская хиротония
          архимандрита Никифора (Асташевского) во епископа Ново-Николаевского:
          таким образом, была учреждена новая Ново-Николаевская епархия. С
          приездом владыки Никифора в Ново-Николаевск началось массовое
          возвращение духовенства вместе с прихожанами из обновленческого
          раскола. В конце 1920-х годов Новосибирская епархия насчитывала 206
          приходов.{" "}
        </div>
        <div class={"inf-5"}>
          {" "}
          С 1924 года правящий архиерей Новосибирской епархии носил титул
          «Новосибирский и Барнаульский», с 1994 года «Новосибирский и Томский»,
          с 1995 года «Новосибирский и Бердский»
        </div>
        <div class={"inf-5"}>
          {" "}
          На рубеже 1920-х — 1930-х началось массовое закрытие и разрушение
          храмов. Были арестованы, расстреляны или приговорены к длительным
          срокам заключения несколько сот священнослужителей и мирян
          Новосибирской епархии: протоиерей Николай Ермолов и священник
          Иннокентий Кикин (канонизированы Русской Православной Церковью в 2002
          году), архиепископ Сергий (Васильков) (расстрелян в 1937 году),
          архимандрит Сергий (Скрипальщиков), протоиереи Василий Курков, Иоанн
          Боголюбов, Александр Аристов, Исидор Максимов, священники Василий
          Выхованец, Петр Матузков, Николай Чемерзов, Иоанн Богатов, Владимир
          Тихомиров, протодиакон Григорий Солодилов и многие другие.
        </div>
        <div class={"inf-5"}>
          В 1937—1943 годы кафедра вдовствовала. В конце 1930-х годов были
          закрыты все церкви на территории области, кроме единственной —
          Успенской кладбищенской церкви в Новосибирске. Этот маленький храм
          стал на короткое время кафедральным для прибывшего в Новосибирск в
          августе 1943 года архиепископа (впоследствии митрополита) Варфоломея.
          В 1944 году епархии был возвращён Вознесенский кафедральный собор.
          Трудами митрополита Варфоломея были открыты многие храмы на огромной
          территории от Урала до Тихого океана. При последующих архиереях в
          середине 1950-х — начале 70-х годов епархия пережила волну закрытия
          храмов.{" "}
        </div>
        <div class={"inf-5"}>
          В 1972 году епархию возглавил епископ Гедеон (Докукин) (в будущем
          митрополит). Он добился регистрации новых общин.
        </div>
        <div class={"inf-5"}>
          В конце 1980-х годов Новосибирская епархия включала в себя территории
          Новосибирской, Томской и Кемеровской областей, Красноярского и
          Алтайского краёв, Тувинской АССР. В 1990 году образована Красноярская,
          в 1993 году Кемеровская, в 1994 году Барнаульская епархия, в 1995 году
          Томская епархия. С 1995 года Новосибирская епархия стала
          территориально совпадать с Новосибирской областью.
        </div>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh12} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              Решением Священного Синода от 28 декабря 2011 года в границах
              Новосибирской области была образована{" "}
              <b> Новосибирская митрополия</b>.
              <div>
                {" "}
                Новосибирская митрополия включает в себя
                <i>
                  {" "}
                  Новосибирскую, Каинскую, Карасукскую и Искитимскую епархии
                </i>
                .
                <div>
                  {" "}
                  Управляющим Новосибирской митрополией 28 декабря 2018 года был
                  назначен Митрополит Никодим (Чибисов).{" "}
                </div>
              </div>
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh14} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              Каинская епархия — в административных границах{" "}
              <i>
                Барабинского, Венгеровского, Каргатского, Коченевского,
                Куйбышевского, Кыштовского, Северного, Татарского, Чановского,
                Чулымского, Убинского и Усть-Таркского районов
              </i>{" "}
              Новосибирской области.{" "}
              <div>
                Управляющий епархией — епископ Каинский и Барабинскйй Владимир
                (Бирюков).
              </div>
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh16} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              Карасукская епархия — в административных границах
              <i>
                {" "}
                Баганского, Доволенского, Здвинского, Карасукского, Кочковского,
                Краснозерского, Купинского, Ордынского и Чистоозерного районов{" "}
              </i>{" "}
              Новосибирской области.
              <div>
                Управляющий епархией — епископ Карасукский и Ордынский Филипп
                (Новиков).
              </div>
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh15} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              Искитимская епархия — в административных границах{" "}
              <i>
                {" "}
                Болотнинского, Искитимского, Маслянинского, Мошковского,
                Сузунского, Тогучинского и Черепановского районов{" "}
              </i>{" "}
              Новосибирской области.
              <div>
                Управляющий епархией — епископ Искитимский и Черепановский
                Леонид (Толмачев).
              </div>
            </div>
          </div>{" "}
        </p>

        <div class={"inf-5"}>
          <div class={"inf-5"}> </div>

          <div class={"inf-5"}> </div>

          <div class={"inf-5"}> </div>
        </div>
      </div>
      <div className="arh" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="arh-h1" style={{ filter: isActive ? "" : "blur(20px)" }}>
          * * * <p> </p>Архиереи Новосибирской Епархии
        </h2>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh2} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Митрополит Никифор (Асташевский).</b> В 1924 году Святейшим
              Патриархом Тихоном была образована Ново-Николаевская епархия.
              Епархиальным архиереем был назначен протоиерей Никифор
              (Асташевский), который после принятия им монашеского пострига с
              именем в честь свт. Никифора, патриарха Константинопольского, был
              возведен в сан архимандрита и затем наречен епископом
              Новониколаевским. В 1927 году был возведен в сан архиепископа, в
              1932 — в сан митрополита. Управлял епархией с 28 сентября 1924 —
              по май 1935 года, после чего ушел на покой, однако жил и служил в
              Новосибирске. Скончался 30 апреля 1937 года, в Великую Пятницу.{" "}
              <p></p>
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh3} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              {" "}
              <b> Архиепископ Сергий (Васильков). </b>
              До Новосибирской кафедры был архиепископом Томским. Владыка Сергий
              был назначен на Новосибирскую кафедру 8 мая 1935 года. Последнее
              богослужение совершил 2 мая 1937 года на Пасху. 3 мая участвовал в
              погребении своего предшественника на новосибирской кафедре
              митрополита Никифора (Асташевского). После этого, в ночь с 4 на 5
              мая архиепископ Сергий вместе с рядом духовенства и мирян был
              арестован. Архиепископ Сергий был расстрелян 29 июля 1937 года в
              городе Новосибирске. Вместе с ним были казнены архимандрит Сергий
              (Скрипальщиков) — настоятель Никольского храма с. Новолуговое
              Новосибирского района, Щукин Тихон Акимович — счетовод
              Вознесенской церкви Новосибирска.
              <p>
                <b>В 1937—1943 годах — кафедра вдовствовала.</b>
              </p>
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh4} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Митрополит Варфоломей (Городцев). </b>В 1943 году на
              Новосибирскую кафедру был назначен владыка Варфоломей (Городцев).
              В Новосибирскую и Барнаульскую епархию входила в то время
              территория всей Западной Сибири. Ему также временно было поручено
              управление всеми сибирскими епархиями, не имевшими архиереев. При
              нем в епархии начинается возрождение церковной жизни. Несмотря на
              трудности военных и послевоенных лет, Владыка Варфоломей совершал
              частые поездки по региону, служил в храмах Иркутска, Омска,
              Тюмени, Ишима, Тобольска, неоднократно посещал Барнаул,
              Красноярск, Бийск, освящал храмы, рукополагал священников. 24
              апреля 1949 г. возведен в сан митрополита. Скончался 1 июня 1956
              года. Погребен в приделе прп. Серафима Саровского в Вознесенском
              кафедральном соборе Новосибирска
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh5} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Митрополит Нестор (Анисимов).</b> Миссионер, просветитель
              Сибири и Камчатки. Был назначен на Новосибирскую кафедру 18 июля
              1956 года. До этого Владыка Нестор был митрополитом Харбинским и
              Маньчжурским, а с 1948 по 1956 году отбывал заключение в
              Мордовских лагерях. Владыка Нестор за короткий срок пребывания на
              Новосибирской кафедре успел объехать всю епархию, посетив самые
              отдаленные приходы. Привлекал к пастырскому служению священников,
              освобожденных из мест заключения. Из-за ревностного служения
              Церкви Божией был удален с Новосибирской кафедры. Скончался в сане
              митрополита Кировоградского и Николаевского 4 ноября 1962 года.
              Погребен в ограде Спасо-Преображенского храма в Переделкино.
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh6} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Епископ Донат (Щеголев). </b> Управлял епархией с 8
              сентября 1958 года  по 5 мая 1961 г. С 8 сент. 1958 г. епископ
              Новосибирский и Барнаульский. Его служение на Новосибирской
              кафедре пришлось на годы активного наступления на Церковь,
              усиления атеистической пропаганды, регламентации всех действий
              архиереев и священников, кампании по закрытию храмов при
              Н.С.Хрущёве. 5 мая 1961 г. был переведен на Костромскую и
              Галичскую кафедру. С июля 1961 г. пребывал на покое, но после
              отстранения от власти Хрущёва вернулся к архиерейскому служению. С
              1965 г. — епископ Калужский и Боровский, с 1971 — архиепископ. С
              17 апр. 1975 г. пребывал на покое по болезни в Москве. Скончался 5
              октября 1979 года. Погребен на Ваганьковском кладбище Москвы, близ
              храма в честь Воскресения словущего.{" "}
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh7} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Епископ Леонтий (Бондарь). </b>5 мая 1961 года назначен
              епископом Новосибирским и Барнаульским. Во время архиерейского
              служения в Сибири пытался противостоять антицерковной кампании, не
              допустить массового закрытия храмов. Обращения Владыки Леонтия по
              поводу незаконных действий местных властей вызвали недовольство
              Совета по делам религии, потребовавшего от Московской Патриархии
              перевода архиерея на другую кафедру. С 14 мая 1963 г. — епископ
              Оренбургский и Бузулукский. Оставался на этой кафедре в течение 35
              лет, до конца жизни. В 1971 г. возведен в сан архиепископа, в 1992
              г. — в сан митрополита. Скончался 24 января 1999 года. Погребен в
              Пантелеимоновском приделе Никольского кафедрального собора
              Оренбурга.
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh8} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Архиепископ Кассиан (Ярославский). </b>
              Управлял Новосибирской кафедрой один год с 14 мая 1963 по 20 мая
              1964 г. Это были годы хрущевских гонений, поэтому Владыка Кассиан
              был на кафедре такое короткое время. с 20 мая 1964 года до ухода
              на покой в декабре 1988 года – архиепископ Костромской и
              Галичский. Скончался 20 марта 1990 года, погребен в Угличе, на
              церковном кладбище при Димитриевском храме.
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh9} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              <b>Архиепископ Павел (Голышев). </b>
              23 июня 1964 года Владыка Павел был назначен архиепископом
              Новосибирским и Барнаульским. Ревностный проповедник, старавшийся
              посещать все приходы обширной тогдашней епархии, защитник
              Православия, архиепископ Павел управлял Новосибирской епархией с
              1964 по 1972 год. Недолго был архиепископом Вологодским и
              Великоустюжским. В конце 1972 года архиепископ Павел был отправлен
              на покой. Осенью 1975 года Владыка Павел переехал во Францию к
              своему брату Кириллу Павловичу Голышеву. Скончался Владыка Павел
              21 января 1979 года и похоронен на русском кладбище.
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh10} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              {" "}
              <b>Митрополит Гедеон (Докукин). </b>
              Был назначен епископом Новосибирским и Барнаульским 2 февраля 1972
              года. За 18 лет пребывания в Сибири стараниями Владыки Гедеона
              построено около 50 храмов, перестроен Вознесенский кафедральный
              собор в Новосибирске. Был одним из инициаторов в 1984 году
              установления празднования памяти Всех святых, в земле Сибирской
              просиявших. 2 сентября 1977 г. возведен в сан архиепископа, в 1987
              г. — в сан митрополита. 25 января 1990 переведен на Ставропольскую
              кафедру, которой управлял до декабря 2002 года. Скончался в сане
              митрополита Ставропольского и Владикавказского 21 марта 2003 года.
              Погребен в ограде Андреевского собора г. Ставрополя.
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh11} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              {" "}
              <b>Митрополит Тихон (Емельянов). </b>
              Был назначен на Новосибирскую кафедру 25 января 1990 года.
              Управлял епархией дважды: с января 1990 г. до июля 1995 г., а
              затем с 28 декабря 2000 г. по 28 декабря 2018 г. Время управления
              Владыки Тихона пришлось на новое время, когда уже не было открытых
              гонений на Церковь. Епархия значительно выросла, было открыто
              множество приходов, образованы монастыри. В 2011 году епархия
              преобразована в митрополию с выделением из ее состава Искитимской,
              Карасукской и Каинской епархий. 28 декабря 2018 года по решению
              Священного синода назначен на Владимирскую и Суздальскую кафедру,
              которой и управляет в данное время.
            </div>
          </div>{" "}
        </p>

        <p>
          {" "}
          <div className="block">
            <div
              className="foto"
              style={{ filter: isActive ? "" : "blur(20px)" }}
            >
              <img src={Arh13} width={200}></img>{" "}
            </div>
            <div className="inf-5">
              {" "}
              <b>Епископ Сергий (Соколов). </b>
              Управлял Новосибирской кафедрой с 10 декабря 1995 года по 20
              октября 2000 г. За период пребывания на Новосибирской кафедре
              уделял много внимания миссионерской работе (в том числе
              совершались миссионерские поездки на корабле по Оби),
              строительству храмов и открытию монастырей. Было начато издание
              епархиальной газеты — «Новосибирский Епархиальный вестник».
              Скончался 20 октября 2000 года. По его последней воле был
              похоронен в ограде кафедрального Преображенского собора в Бердске,
              второго кафедрального собора Новосибирской епархии.
            </div>
          </div>{" "}
        </p>
      </div>

      <h2 className="arh-h1" style={{ filter: isActive ? "" : "blur(20px)" }}>
        Кафедральные соборы епархии
      </h2>
      <div class={"inf-5"}>
        <b> НОВОСИБИРСК.</b> Вознесенский Кафедральный собор — главный храм
        епархии, где располагается кафедра правящего архиерея. Верхний имеет три
        придела: в честь Вознесения Христова, Николая Чудотворца и Серафима
        Саровского, нижний храм освящён во имя Александра Невского и праотца
        Гедеона. Первый деревянный храм в честь Вознесения Господня был построен
        в городе Ново-Николаевске в 1913 году. В 1924 году церковь стала
        кафедральным собором, в 1937 году он был закрыт и в нём разместили
        зернохранилище. В 1944 году трудами митрополита Варфоломея он был
        возвращён епархии. В 1970—1980 годы собор был перестроен в камне. В
        соборе организован благотворительный центр, осуществляется сбор денежных
        средств, одежды и обуви для малоимущих. Для своевременного освещения
        религиозной жизни города в средствах массовой информации при соборе
        организована православная видеостудия.
      </div>

      <div className="sbr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <div className="vozn">
          <img src={ww} alt="vozn" width={700} />
        </div>
      </div>
      <div className="obr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <div class={"inf-5"}>
          <b> БЕРДСК.</b> Кафедральный собор в честь Преображения Господня.
          Строительство собора начато 7 июля 1992 года. Храм имеет два уровня:
          нижний — во имя трёх святителей (освящён в 1999 году епископом Сергием
          (Соколовым)) верхний — в честь Преображения Господня (в 2001 году
          (малое освящение); в 2004 году освящение главного престола совершил
          архиепископ Новосибирский и Бердский Тихон (Емельянов). В августе 1998
          года из Каменск-Уральска были привезены девять колоколов,
          установленных на соборной звоннице. В церковной ограде рядом с алтарём
          находятся могилы епископа Новосибирского и Бердского Сергия (Соколова)
          и архимандрита Макария (Реморова).
        </div>

        <div className="sbr" style={{ filter: isActive ? "" : "blur(20px)" }}>
          <div className="vozn">
            <img src={berdsk} alt="vozn" width={700} />
          </div>
        </div>
      </div>
      <Link to="/">
        <div class="BiExit">
          <BiExit />
        </div>
      </Link>
    </div>
  );
}

export default Ep;
