import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import ikona from './img_sobor/sobor.jpg'; 
import sv_Makariy from './img_sobor/svt-makari-icon2.jpg';
import sv_Innocent from './img_sobor/sv-Innocent.jpg'
import sv_Nikolay2 from './img_sobor/sv-Nikolaya.jpg'
import Sv_Michail from './img_sobor/mihail-pyataev.jpg'
import Sv_Ioann from './img_sobor/ioann-kuminov.jpg'
import Sv_Valentin from './img_sobor/prmch-valentin-1.jpg'
import Sv_Iliya from './img_sobor/schmch-ilia.jpg'
import Sv_Dimitry from './img_sobor/sv_dmitry.jpg'




function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Собор святых Новосибирской митрополии</b>
        </div><p></p> 
      

        {/* <div> <table>
                        <div class="sv-title">
                            <p>Часовня святителя и чудотворца Николая</p>
                        </div>
                        <div class="church-1-image">
                            <img src={chas} width={320}
                                alt="Иверская икона"
                                title="Иверская икона" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        Святыней города Ново-Николаевска–Новосибирска стала часовня во имя 
                        Святителя Николая Чудотворца. Она была возведена в 
                        ознаменование 300-летия царствования Дома Романовых.   
                          <div>Автор 
                        проекта — известный архитектор А.Д.Крячков. Строилась часовня 
                        на пожертвования благотворителей и горожан Ново-Николаевска. 
                        Это была поистине всенародная стройка. Освящение часовни 
                        состоялось 6 декабря 1915 года, в день памяти Святителя Николая
                        Чудотворца. </div>
                        <div>А 9 ноября 1929 года Новосибирский окрисполком, 
                        «учитывая пожелания трудящихся масс и считаясь с 
                        благоустройством города», одобрил постановление 
                        Новосибирского горсовета о сносе часовни. Разрушение святыни 
                        началось 29 января 1930 года. </div>
                          <div>В начале 1990-х годов, в ходе 
                        подготовки к празднованию 100-летия Новосибирска, было 
                        принято решение о восстановлении часовни. В юбилейном 1993 
                        году строительство часовни во имя Святителя Николая было завершено. </div>
                        <div > 
                         </div>  </div>
                    </table>
                    </div>   */}




                <div> <table>
                        <div class="sv-title">
                            
                        </div>
                        <div class="church-1-image">
                            <img src={ikona} width={300}
                                alt="Собор святых Новосибирских"
                                title="Собор святых Новосибирских" />
                        </div>
                        <table>
                        <div class ={"inf-4"}>
                        3 ноября 2020 года, согласно рапорту митрополита Новосибирского и Бердского 
                        Никодима, по благословению Святейшего Патриарха Московского и всея Руси 
                        Кирилла в Месяцеслов Русской Православной 
                        Церкви было внесено празднование Собора святых Новосибирской митрополии. </div>
                          <div class ={"inf-4"}>Празднование Собора святых Новосибирской митрополии 
                          было определено совершать в день памяти с
                          вятителя Макария (Невского), митрополита Московского, — 16 февраля
                           (1 марта н.ст., в високосный год — 29 февраля).   </div><p></p>
                        <div class ={"inf-7"}><b>В состав Собора святых Новосибирской митрополии входят:</b>
                        <div>Святитель Макарий (Невский), митрополит Алтайский</div>
                         <div>Священномученик Николай Ермолов</div>
                         <div>Священномученик Иннокентий Кикин</div>
                         <div>Священномученик Михаил Пятаев</div>
                         <div>Священномученик Иоанн Куминов</div>
                         <div>Преподобномученик Валентин (Лукьянов)</div>
                         <div>Священномученик Илия Березовский</div>
                          <div>Священномученик Димитрий Семенов</div>
                         <div>Святая блаженная Домна Каинская и Томская</div>
                         </div></table>
                           
                    </table>
                    </div>        
                    
                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Makariy"> 
                    <div class="church-1-image"> <img src={sv_Makariy} width={230}
                                alt="Святитель Макарий Алтайский"
                                title="Святитель Макарий Алтайский" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Makariy">
                          <div class="sv-title">
                        Святитель Макарий, митрополит Алтайский</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>



                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Nikolay"> 
                    <div class="church-1-image"> <img src={sv_Nikolay2} width={230}
                                alt="Священномученик Николай Ермолов"
                                title="Священномученик Николай Ермолов" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Nikolay">
                          <div class="sv-title">
                        Священномученик Николай Ермолов</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>


                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Innocent"> 
                    <div class="church-1-image"> <img src={sv_Innocent} width={230}
                                alt="Священномученик Иннокентий Кикин"
                                title="Священномученик Иннокентий Кикин" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Innocent">
                          <div class="sv-title">
                          Священномученик Иннокентий Кикин</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>



                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Michail"> 
                    <div class="church-1-image"> <img src={Sv_Michail} width={230}
                                alt="Священномученик Михаил Пятаев"
                                title="Священномученик Михаил Пятаев" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Michail">
                          <div class="sv-title">
                          Священномученик Михаил Пятаев</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>



                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Ioann"> 
                    <div class="church-1-image"> <img src={Sv_Ioann} width={230}
                                alt="Священномученик Иоанн Куминов"
                                title="Священномученик Иоанн Куминов" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Ioann">
                          <div class="sv-title">
                          Священномученик Иоанн Куминов</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>




                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Valentin"> 
                    <div class="church-1-image"> <img src={Sv_Valentin} width={230}
                                alt="Преподобномученик Валентин (Лукьянов)"
                                title="Преподобномученик Валентин (Лукьянов)" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Valentin">
                          <div class="sv-title">
                          Преподобномученик Валентин (Лукьянов)</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>



                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Iliya"> 
                    <div class="church-1-image"> <img src={Sv_Iliya} width={230}
                                alt="Священномученик Илия Березовский"
                                title="Священномученик Илия Березовский" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Iliya">
                          <div class="sv-title">
                          Священномученик Илия Березовский</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>



                    
                    <table>
                      <tr><td>
                    
                    <div class=""> <a class='sem' href="/Sv_Dimitry"> 
                    <div class="church-1-image"> <img src={Sv_Dimitry} width={230}
                                alt="Священномученик Димитрий Семёнов"
                                title="Священномученик Димитрий Семёнов" /> 
                        </div> </a></div> </td>
                        <td>
                        <a class='sem' href="/Sv_Dimitry">
                          <div class="sv-title">
                          Священномученик Димитрий Семёнов</div>
                        <div class="inf-6">
                        <p>Читать краткое житие</p></div>
                        </a></td></tr>
                   
                    </table>
   

      
      <Link to='/'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
