import React from 'react'
import flag from "./region/Baganskiy/Gerb2.jpg"
import './main.css'
// import emblem from "./region/Baganskiy/herb2.jpg"
import attraction_1_image from "./region/Baganskiy/ozero.jpg"
import church_1_img from "./region/Baganskiy/h1.jpg"
import church_2_img from "./region/Baganskiy/h2.jpg"
import church_3_img from "./region/Baganskiy/h3.jpg"
import church_4_img from "./region/Baganskiy/h4.jpg"
import church_5_img from "./region/Baganskiy/h1-1.jpg"
function Baganskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={flag} alt="Флаг Баганск" title="Флаг Баганский район" />
                    </div>
                    <h1 className='head'>Баганский район</h1>
                </div>
                <div class="about-region">
                    
                        <div class="inf-4">
                        
                        Дата образования в настоящих границах — 03.11.1965 год. Административный центр — село Баган. 
                        Расстояние от Багана до Новосибирска — 450 км. 
                        <div>Площадь территории — 3368 кв.км. Протяженность c запада на восток 103 км, 
                            c севера на юг 60 км. 
                        Административно-территориальное деление: 9 муниципальных образований (поселений), 
                        40 населенных пунктов. Население — 15208 чел. </div>
                        
                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Баганского района</h2>
                    </div>
                   

                   
                    <div class="church-2">
                        <table>
                        <div class="church-1-title">
                            Храм в честь Успения Пресвятой Богородицы, с. Баган
                        </div>
                        <table></table>
                        <div class="church-1-image">
                            <img src={church_2_img} width={600} alt="Храм в честь Успения Пресвятой Богородицы (с. Баган)."
                                title="Храм в честь Успения Пресвятой Богородицы" />
                        </div>
                        <div class ={"inf-4"}>
                    В 1993 году по ходатайству правящего архиерея епископа Новосибирского и Барнаульского Тихона распоряжением 
                    Главы администрации Баганского района Новосибирской Епархии Русской Православной Церкви было 
                    безвозмездно передано здание художественной мастерской, состоявшей на балансе районного отдела культуры. 
                    Затем закреплен в бессрочное пользование и земельный участок площадью 0,18 га. 
                    <div>Так у православных Баганского района появилось свое здание для совместной молитвы. Было много 
                        трудностей, не было священника. Один раз в месяц из г. Карасука приезжал батюшка и совершал 
                        богослужения и требы, но Богу содействующу, община духовно крепла и умножалась. Общими усилиями прихожан приобреталось 
                        необходимое богослужебное имущество, устраивалась приходская жизнь. </div>
                        <div> К празднику Рождества Христова в 1993 году православный приход получил святой Антиминс, 
                            на котором и совершается Евхаристия. 
                            Помещение прихода, с внесением в него святого Антиминса, получило статус храма в честь Успения Пресвятой Богородицы.</div>
                    <p><b>Адрес:</b> с. Баган, ул. Вокзальная, 10.</p>  
                    
                    </div> 
                    </table>
                    </div>
                    
                    
                   
                   
                    <div> <table>
                        <div class="church-2-title">
                            <p>Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба, с. Баган</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_img} width={500}
                                alt="Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба (с. Баган)."
                                title="Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба (с. Баган)" />
                        </div>
                        <div class="church-1-image">
                            <img src={church_5_img} width={300}
                                alt="Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба (с. Баган)."
                                title="Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба (с. Баган)" />
                        </div>
                        <div class ={"inf-4"}>
          Храм в честь святых страстотерпцев Бориса и Глеба в селе Баган был заложен в мае 2015 года, 
          а уже 12 октября 2016 года храм был освящен. Храм освятил преосвященный Филипп, епископ Карасукский и 
          Ордынский <p><b>Адрес:</b> с. Баган, ул. Энгельса, 16.</p> </div> 
                    </table>
                    </div>


                    <table>
                    <div>
                        <div class="church-1-title">
                            <p></p>
                            Храм в честь святых мучениц Веры, Надежды, Любви и матери их Софии, с. Мироновка
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_img} width={370}
                                alt=" Храм в честь святых мучениц Веры, Надежды, Любви и матери их Софии."
                                title=" Храм в честь святых мучениц Веры, Надежды, Любви и матери их Софии" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        Православный храм в честь святых мучениц Веры, Надежды, Любови и матери их Софии в селе Мироновка 
                        появился в 2013 году. Строительство храма шло почти 3 года. 
                        <div>В Лазареву субботу 27 апреля 2013 года  состоялось его освящение. 
                        После освящения нового храма епископ Карасукский и Ордынский Филипп отслужил 
                        здесь первую Божественную Литургию.</div> 
                        <p><b>Адрес: </b> Баганский район, с. Мироновка, ул. Центральная, 48.</p>  <p> </p><p> </p><p> </p><p> </p><p> </p></div> 
                    </div>
                    </table>

                    <p></p>
                    <table>
                    <div class="church-1">
                        <div class="church-2-title">
                            Храм в честь святого мученика Иоанна Воина, с. Ивановка
                        </div>
                        <div class="church-1-image">
                            <img src={church_4_img} width={650}
                                alt="Храм в честь святого мученика Иоанна Воина"
                                title="Храм в честь святого мученика Иоанна Воина" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        Православный храм в честь святого мученика Иоанна Воина был освящен 12 августа 2017 года.
                         Храм строился 6 лет. Рядом с храмом построено здание Воскресной школы. 
                        
                          
                        
                        <p><b>Адрес:</b> Баганский район, с. Ивановка, ул. Центральная, 21/1.</p>  <p> </p><p> </p><p> </p><p> </p><p> </p></div> 
                    </div>
                    </table>

                </div>

                
                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Лечебное солевое озеро Горькое</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_1_image} width={700} alt="Озеро Горькое" title="Озеро" />
                            </div>
                        </div>
                    </div>
            </section>
        </div>);
}

export default Baganskiy