import React from 'react'
import flag from "./region/Suzunskiy/herb1.png"
import emblem from "./region/Suzunskiy/herb1.png"
import admin from "./region/Suzunskiy/admin.png"
import church_1_image from "./region/Suzunskiy/h1.jpg"
import church_2_image from "./region/Suzunskiy/h2.jpg"
import church_3_image from "./region/Suzunskiy/h3.jpg"
import attraction_1_image from "./region/Suzunskiy/priroda1.jpg"

function Suzunskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
            <div class="title-region"> 
            <div class="flag">
                        <img src={emblem} width={170} alt="Флаг " title="Герб" />
                    </div>
                    <h1 className='head'>Сузунский район</h1>
                </div>
                <div class="inf-4">
                        <p>Район образован в 1925 году в составе Каменского округа Сибирского края; 
                            в составе Каменского округа также был создан Битковский район, который 
                            11 октября 1927 был передан в Новосибирский округ. В 1930 году Сибирский
                             край был разделён на западную и восточную части (округа при этом были 
                             упразднены), и оба района оказались в составе Западно-Сибирского края. 
                             2 марта 1932 Сузунский и Битковский районы были объединены в один 
                             Лушниковский район с райцентром в селении Лушники, а уже 10 декабря того
                             же года вновь восстановлен Сузунский район с центром в селе Завод-Сузун. 
                             В 1937 году район был включён в состав новообразованной Новосибирской 
                             области. Административный центр — р.п. Сузун. Население 31 700 чел.</p>
                    </div>
                <div class="churches">
                <div class="churches title">
                        <h2 className='churches-title'>Церкви Сузунского района</h2>
                    </div>
                  

                   
                </div>
                <div class="about-region">
                    


                <div>
                        <div class="church-1-title">
                            <p>Храм Вознесения Господня, поселок Сузун</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={570}
                                alt="Храм Вознесения Господня, поселок Сузун"
                                title="Храм Вознесения Господня, поселок Сузун" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        Деревянная церковь, возведенная в 1846 г., представляла собой крестообразную в плане однокупольную постройку в стиле классицизма.
                        За время своего существования Сузунская церковь не раз перестраивалась. Сначала в связи с ветхостью здания, затем – с увеличением числа прихожан. Приход церкви состоял из жителей села Сузун Барнаульского уезда. По воспоминаниям старожилов, здание церкви отличалось богатым убранством. Сведений о чудотворных иконах не сохранилось. Однако особо почитаемыми образами считались храмовая икона Вознесения Христова и икона Святого благоверного и великого князя Александра Невского в серебряной позолоченной ризе, пожертвованная настоятелем церкви священником Василием Дуплевым. При Вознесенской церкви действовала своя церковно-приходская школа, открытая в 1895 г. и размещавшаяся в доме, принадлежавшем церкви.
                        В 1937 г. церковь была закрыта, позже сломана. 
                        <div>Приход восстановлен в 1990 г. На месте старой церкви была 
                            построена новая в 1997–2002 гг. 
                        При церкви действует воскресная школа для детей и взрослых,
                         создана библиотека православной литературы. 
                         В сентябре 2004 г. образовались православные молодежный и детский хоры
                          с обучением основам музыкальной грамоты.
                        </div>
                        
                        <p><b>Адрес:</b> рабочий поселок Сузун, улица Ленина, 26.</p>  
                        </div> 
                </div>   


                <div>
                        <div class="church-1-title">
                            <p>Храм Воскресения Христова, с. Мереть</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={570}
                                alt="Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба (с. Баган)."
                                title="Храм в честь святых благоверных князей-страстотерпцев Бориса и Глеба (с. Баган)" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        Самые первые исторические сведения о церкви с. Мереть относятся к 1752 г., 
                        тогда она именовалась Никольской.
                        В 1860 г. пришедший в ветхость храм был полностью разобран, а на его 
                        месте выстроен новый — тоже деревянный, который был освящен в честь 
                        Святой Живоначальной Троицы.</div> 
                        <div>В советское время Свято-Троицкая церковь была закрыта 
                        постановлением облисполкома
                         от 9 октября 1936 года. Здание было переоборудовано под клуб, который сгорел в 1980 году. </div>
                        <div> В 1997 г. администрация выделила под храм пустовавшее здание магазина; местные жители 
                            провели необходимый ремонт и переустройство. Новый храм в честь Воскресения Христова 
                            был освящен 
                            16 июня 1997 года Преосвященным Сергием, епископом Новосибирским и 
                            Бердским. 
                            В этот же день была отслужена первая Божественная Литургия.</div>
                            <div>В августе 2002 г. на чердаке одного из сельских домов был найден 
                            Крест-Голгофа из старого храма. По воспоминаниям старожилов этому 
                            кресту было уже более 120 лет. По-видимому, после закрытия храма Крест 
                            был вынесен и спрятан прихожанами. 14 августа 2002 года крест был торжественно 
                            перенесен в храм.
                            Также одной из почитаемых святынь Воскресенского храма является икона 
                            Пророка Божия Илии из старой церкви.
                         
                        <p><b>Адрес:</b> с. Мереть, ул. Чкалова, 51.</p></div> 
                </div>   
                   
                <div>
                        <div class="church-1-title">
                            <p>Храм в честь Святой Троицы, с. Болтово</p>
                        </div>
                        <table>
                        <div class="church-1-image">
                            <img src={church_3_image} width={370}
                                alt="Храм в честь Святой Троицы, с. Болтово"
                                title="Храм в честь Святой Троицы, с. Болтово" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        Самым большим храмом на территории нынешнего Сузунского района была церковь в честь Вознесения 
                        Господня с. Болтовского, построенная в 1859 году.
                        В 1911 г. количество прихожан Вознесенской церкви составляло 2238 человек, 
                        через три года их число выросло в полтора раза, до 3477 человек.
                        <div> В 1941 г. по распоряжению Новосибирского облисполкома от 4 апреля Болтовская 
                            Вознесенская церковь была закрыта. После закрытия 
                            здание использовалось под зерносклад, впоследствии его 
                            переоборудовали под школьные мастерские. Впоследствии в 1980-е годы здание снесли. 
                        </div><div>    Новый храм в селе Болтово был построен быстро — чуть более года понадобилось для
                            строительства церкви. И 17 декабря 2011 г. состоялось малое освящение храма 
                            в честь Святой Живоначальной Троицы.
                        </div>
                        <p><b>Адрес:</b> с. Болтово, Набережный проезд, 1. </p>  
                        </div> 
                        </table>             
                </div>   


                </div>

                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Музей сибирской народной иконы расположен в здании-памятнике регионального значения «Здание церковно-приходской школы» постройки конца XIX века.</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_1_image} alt="Музей сибирской народной иконы расположен в здании-памятнике регионального значения «Здание церковно-приходской школы» постройки конца XIX в." title="mkkp" />
                            </div>
                        </div>
                        <div class="attraction-2">
                            <div class="attraction-2-text">
                                
                            </div>
                        </div>
                        <div class="attraction-3">
                          
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
    );
}

export default Suzunskiy