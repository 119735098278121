import React from 'react'
import emblem from "./region/Ordinskiy/herb2.png"
import church_1_image from "./region/Ordinskiy/h1.jpg"
import church_2_image from "./region/Ordinskiy/h2.png"
import church_3_image from "./region/Ordinskiy/h10.jpg"
import church_4_image from "./region/Ordinskiy/h11.jpg"
import church_5_image from "./region/Ordinskiy/h5.png"
import church_6_image from "./region/Ordinskiy/h6.png"
import church_7_image from "./region/Ordinskiy/h7.png"
import church_8_image from "./region/Ordinskiy/h9.jpg"
import church_11_image from "./region/Ordinskiy/h12.jpg"
import church_13_image from "./region/Ordinskiy/h13.jpg"
import church_14_image from "./region/Ordinskiy/h14.jpg"
import church_15_image from "./region/Ordinskiy/h15.jpg"
import church_16_image from "./region/Ordinskiy/h16.jpg"
import P1 from "./region/Ordinskiy/Petra-i-Pavla.jpg"
import d1 from "./region/Ordinskiy/d1.jpg"
import monastery from "./region/Ordinskiy/m1.png"
import monastery1 from "./region/Ordinskiy/mihailo-arh.jpg"
function Ordinskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Ордынский район</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Герб" title="Герб" />
                    </div>
                    <h1 className='head'>Ордынский район</h1>
                </div>
                <div class="inf-4">
                        
                            
                           <div> Ордынский район был образован 25 мая 1925 года на месте бывшей Ордынской волости в 
                            составе Ново-Николаевского уезда/округа (с 1926 — Новосибирского округа) Сибирского края.
                            С 1930 года Ордынский район — в составе Западно-Сибирского края. В начале 1935 года Ордынский район был в основном слит со Спиринским районом, при этом восточная часть района (в том числе Ярковский, Верх-Ирменский, Проньковский и др. сельсоветы) вошла в Ярковский/Ирменский район.
                            В 1937 Ордынский район был включён во вновь образованную Новосибирскую область. В 1950-е годы в его состав частично вошёл Ирменский район (другая часть Ирменского района отошла в состав Новосибирского района). В 1959 году, при создании Обского водохранилища, 
                            практически все обские деревни бывшего Ирменского района (например, Пронькино) ушли под воды этого искусственного водоёма.
                            Административный центр — посёлок городского типа Ордынское. Население 35 846 человек.</div>
                            <div>20 августа 1598 года на берегу реки Обь на территории современного Ордынского района 
                            тарским воеводой Андреем Воейковым в Ирменском сражении был разбит хан Кучум, после 
                            чего в политической истории самостоятельного Сибирского ханства была поставлена точка.</div>
                        
                    </div>
                <p></p>
                <table className='table-w'>
                    <div class="center-image">
                        <img src={P1} width={700} alt="Храм св. Петра и Павла}" />     
                        <div class ={"inf-podpis"}>
                            Церковь святых апостолов Петра и Павла в д. Чингис Ордынского района </div>                     
                    </div>
                </table>

                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Ордынского района</h2>
                    </div>
                    
                   {/* Николая Чудотворца, Ордынское */}
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Церковь во имя Святителя Николая Чудотворца, р.п. Ордынское </p>
                        </div>
                        <table>
                        <div class="church-1-image">
                            <img src={church_3_image} width={500} alt="храм ап. Петра и Павла" title="Храм1" />
                        </div>
                   <div className='inf-4'>Никольская церковь села Ордынска Барнаульского уезда Томской 
                   губернии была заложена 24 июля 1853 года и построена тщанием прихожан в 1863 году. 
                   Здания храма и колокольни были деревянными на каменном фундаменте. 
                   25 апреля 1865 года храм сгорел. По благословению епископа Томского и Семипалатинского 
                   Петра (Екатериновского) на месте сгоревшего был построен новый храм, который освятили 14 ноября 1883 года.
                   <div>С 1924 по 1937 годы настоятелем храма был <b>протоиерей Николай Ермолов</b>, 
                    благочинный Ордынского округа. В 1937 году по ложному обвинению протоиерей Николай Ермолов 
                    был арестован и расстрелян вместе со священником Иннокентием Кикиным, настоятелем Никольского 
                    храма с. Нижнекаменки. В 2002 году они были прославлены в лике священномучеников.</div>
                   
                    <div>23 сентября 1937 г. Никольский храм закрыли. В 1956 г. поселок перенесли на новое место со зданием храма, 
                        отданным под Дом пионеров. </div> 
                        <div>По ходатайству православной общины 
                            здание храма было возвращено Русской Православной Церкви и 
                            26 декабря 1992 г. епископ Новосибирский и Барнаульский Тихон 
                            освятил храм во имя святителя Николая. Летом 2015 года по благословению Преосвященнейшего Филиппа, 
                            епископа Карасукского и Ордынского, храм был отреставрирован, а территория благоустроена.</div>
                   <p><b>Адрес:</b> рп. Ордынское, ул. Маяковского, 25.</p>
                   </div>
                   </table>
                    </div>


                    <table>
                   {/* Церковь в честь Живоначальной Троицы р.п. Ордынское */}
                   <div class="church-3">
                        <div class="church-2-title">
                            <p>Церковь в честь Живоначальной Троицы р.п. Ордынское</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_8_image} width={500} alt="Церковь Александра Невского (с. Красный Яр)" title="Храм3" />
                        </div>
                        <div class="inf-4"> История  Собора  Живоначальной  Троицы началась в 2003 году, 
                        когда под строительство храма была выделена земля.
                        Закладной камень на начало строительства был освящен 15 июля 2004 года 
                        Высокопреосвященнейшим Тихоном, архиепископом Новосибирским и Бердским.
                        <div>К 2010 году были возведены стены и освящены купола и кресты. 
                        К концу 2012 года кирпичный храм был построен, изнутри оштукатурен и 
                        покрашен, установлен иконостас.
                        <div>4 мая 2013 года Его Преосвященство епископ Филипп совершил малое освящение храма Святой Троицы
                         в  Ордынске и возглавил первую Божественную литургию. 
                         В 2016 году для собора были освящены колокола.</div>
                        </div>
                       
                       <p><b>Адрес:</b> р.п. Ордынское, ул. Октябрьская, 35а.</p>
                        </div>
                    </div>
                    </table>


<p></p>
                    {/* иконы Божией Матери «Неопалимая Купина» р.п. Ордынское */}
                    <table class="table-w">
                    <div class="church-4-title">
                    <div class="church-2-title">
                           <p> Церковь в честь иконы Божией Матери «Неопалимая Купина» р.п. Ордынское</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_4_image} width={500} alt="Церковь в честь иконы Божией Матери «Неопалимая Купина" title="Храм4" />
                        </div>
                        <div class="inf-4"> Храм был освящен в июне 2004 года.
                        <p><b>Адрес:</b> р.п. Ордынское, ул. Степная, 52а.</p>
                        </div>
                    </div>
                    </table>




                    <p></p>             {/* Святителя Николая, д. Нижнекаменка */}   
                    <table> 
                   
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Церковь во имя Святителя Николая Чудотворца, с. Нижнекаменка</p>
                        </div>
                        <table>
                        <div class="church-1-image">
                            <img src={church_11_image} width={500} 
                            alt="Церковь во имя Святителя Николая Чудотворца" 
                            title="Церковь во имя Святителя Николая Чудотворца" />
                        </div>
                   <div className='inf-4'>До революции в  селе Нижнекаменка был храм во имя святителя Николая 
                   Чудотворца — в этом храме служил <b>священномученик Иннокентий Кикин</b>. 
                   В богоборческий период храм был разрушен. 
                    <div>Новый храм в честь Святителя Николая был построен в 1997 году. </div> 
                   <p><b>Адрес:</b> с. Нижнекаменка, ул. Тупиковая, 2/1.</p>
                   </div>
                   </table>
                    </div>
                    </table>      



<p></p>             {/* Святых апостолов Петра и Павла, д. Чингис */}   
                    <table> 
                   
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Церковь во имя Святых апостолов Петра и Павла, д. Чингис </p>
                        </div>
                        <table>
                        <div class="church-1-image">
                            <img src={church_1_image} width={500} alt="храм ап. Петра и Павла" title="Храм1" />
                        </div>
                   <div className='inf-4'>Первое деревянное здание храма в честь Святых Первоверховных Апостолов Петра
                    и Павла освящено ещё в 1756 году. Позднее, в 1807 году, в селе по решению правления 
                    Алтайского горного округа заложено каменное здание. Чингисский храм был богатым и знаменитым, 
                    он содержал большое количество икон и святынь. 
                    <div>В августе 1936 года храм был закрыт, в здании размещались зернохранилище, клуб. 
                        В результате пожара обвалилась колокольня, а позднее обрушилось само здание, от храма 
                        остался фундамент и остатки стен. В 1998 году местными жителями принято решение о 
                        восстановлении церкви и в настоящее время на месте прежнего храма 
                   стоит современное кирпичное здание, украшенное иконами местного письма.</div> 
                   <p><b>Адрес:</b> д. Чингис, ул. Пролетарская, 6а.</p>
                   </div>
                   </table>
                    </div>
                    </table>      



                    {/* Церковь во имя св. Александра Невского, с. Красный Яр */}
                    <table class="table-w">
                    
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Церковь во имя святого благоверного князя Александра Невского, с. Красный Яр</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={500} alt="(Чингисская) церковь Во имя Святых апостолов Петра и Павла" title="Храм2" />
                        </div>
                        <div class='inf-4'>Старый храм в с. Красный Яр попал в зону затопления при строительстве Обской ГЭС.
                         Здание храма не сохранилось. Сейчас на острове на р.Обь на месте храма установлен крест 
                         (храм находился на возвышенности). 
                         <div>Строительство нового храма в с.Красный Яр началось после 
                         праздника Успения Божией Матери в 1998 году. Строительство начала братия Михаило-Архангельского 
                         мужского монастыря. 
                         Первая служба состоялась на праздник Преображения Господня в 1999 году. </div>
                         <div>Храм является подворьем Михаило-Архангельского мужского монастыря (с. Козиха).</div>
                        <p><b>Адрес:</b> с. Красный Яр, ул. Ленина, 28.</p></div>
                    </div>
                    </table>


                    
 
                    {/* Церковь во имя Илии Пророка, с. Верх-Ирмень */} 
                    <table class="table-w"> 
                    
                    <div class="church-5">
                        <div class="church-2-title">
                            <p>Церковь во имя пророка Божия Илии, с. Верх-Ирмень</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_5_image} width={500} alt="Церковь во имя Илии Пророка" 
                            title="Церковь во имя Илии Пророка" />
                        </div>
                        <div class="inf-4"> Деревянный храм во имя пророка Божия Илии в с. Верх-Ирмень
                         был построен в 1900 году.
                        <div>В октябре 1937 года церковь была закрыта. Купол и колокольню разобрали. Были 
                            сооружены дополнительные пристройки и к началу 1938 года в 
                            переоборудованном здании разместился клуб.</div>
                            <div>В 1993 году в селе появилась православная община, для Богослужений был приспособлен дом.
                            В 1998 построена новая деревянная однокупольная церковь простой архитектуры с шатровой колокольней. 
                            Выстроена попечением Михаило-Архангельского монастыря в Козихе. 
                                Храм является подворьем монастыря.</div>
                        <p><b>Адрес:</b> с. Верх-Ирмень, ул. Кандикова, 40а.</p>
                        </div>
                    </div>
                    </table> 



                    {/* Церковь во имя преподобного Серафима Саровского, с. Верх-Ирмень */} 
                    <table class="table-w"> 
                    
                    <div class="church-5">
                        <div class="church-2-title">
                            <p>Церковь в честь преподобного Серафима Саровского, с. Верх-Ирмень</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_16_image} width={500} alt="Церковь в честь преподобного Серафима Саровского" 
                            title="Церковь в честь преподобного Серафима Саровского" />
                        </div>
                        <div class="inf-4"> Храм был построен в 2009 году.
                            
                        <p><b>Адрес:</b> с. Верх-Ирмень, ул. Морозова, 83в.</p>
                        </div>
                    </div>
                    </table> 




                    <table class="table-w">
                    {/* Церковь Рождества Христова, д. Новый Шарап */}
                    <div class="church-6">
                    <div class="church-2-title">
                            <p>Церковь Рождества Христова, д. Новый Шарап</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_6_image} width={500} alt="Церковь Рождества Христова" title="Храм" />
                        </div>
                        <div class="inf-4"> Строительство храма в началось летом 1998 года. 
                        Под храм было отдано здание старого купеческого магазина. 
                        Братия Михаило-Архангельского монастыря 
                        поставили купол, перекрыли крышу выстроили колокольню, внутри настелили пол и сделали иконостас. 
                         Храм был построен в 1999 году. 
                        Первую службу совершили 7 января 1999 года в престольный праздник — День Рождества Христова.
                        <div>Храм является подворьем мужского монастыря во имя Михаила Архангела (с. Козиха).</div>
                        <p><b>Адрес:</b> д. Новый Шарап,  ул. Говорухина, 1/1.</p>
                        </div>
                    </div>
                    </table> 



                    {/* Церковь Введения во храм Пресвятой Богородицы, д. Верх-Чик */}
                    <table class="table-w">
                    
                    <div class="church-6">
                    <div class="church-2-title">
                            <p>Церковь Введения во храм Пресвятой Богородицы, д. Верх-Чик</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_13_image} width={500} alt="Церковь Рождества Христова" title="Храм" />
                        </div>
                        <div class="inf-4"> В деревне Верх-Чик на месте, где сейчас находится храм, до революции была деревянная приходская церковь 
                        во имя преподобного Петра Афонского. В 1930-е годы храм был закрыт. Впоследствии здание было уничтожено пожаром.
                        <div>Летом 1997 года на месте старого храма отслужили первый молебен и поставили Крест.
                        Осенью 1997 года начали строительство нового храма. 
                        Храм был построен в 1999 году, первая служба состоялась на Рождество Христово в 1999 году.  </div>
                        <div>Храм является подворьем мужского монастыря во имя Михаила Архангела (с. Козиха).</div>
                        
                        <p><b>Адрес:</b> д. Верх-Чик,  ул. Молодежная, 14.</p>
                        </div>
                    </div>
                    </table> 



                    {/* Церковь святых апостолов Петра и Павлв, пос. Петровский */}
                    <table class="table-w">
                    
                    <div class="church-6">
                    <div class="church-2-title">
                            <p>Церковь святых апостолов Петра и Павлва, поселок Петровский</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_14_image} width={500} alt="Церковь Рождества Христова" title="Храм" />
                        </div>
                        <div class="inf-4"> В 1998 году под строительство храма было передано здание и начались строительные работы. 
                        В 1999 году храм святых апостолов Петра и Павла был построен.
                        
                        <div>Храм является подворьем мужского монастыря во имя Михаила Архангела (с. Козиха).</div>
                        
                        <p><b>Адрес:</b> поселок Петровский, Первомайский переулок, 1.</p>
                        </div>
                    </div>
                    </table> 



                    {/* Церковь во имя Святителя и Чудотворца Николая, д. Усть-Алеус */}
                    <table class="table-w">
                    <div class="church-7">
                    <div class="church-2-title">
                            <p>Церковь во имя Святителя и Чудотворца Николая, с. Усть-Алеус</p>
                        </div>
                       <div class="church-1-image">
                            <img src={church_15_image} width={500} alt="Церковь во имя Святителя и Чудотворца Николая" title="Церковь во имя Святителя и Чудотворца Николая" />
                        </div>
                        <div class="inf-4">Храм Святителя Николая в селе Усть-Алеус был построен до революции в 1900 году. 
                        В советское время храм был закрыт.    
                        
                        <div>Новый храм был построен в начале 2010-х годов. 
                             
                        </div>
                        <p><b>Адрес:</b> с. Усть-Алеус, ул. Кириллова, 54/1.</p>
                        </div>
                    </div>
                    </table> 



                    {/* Церковь во имя святого благоверного князя Глеба, с. Кирза */}
                    <table> 
                    <div class="church-7">
                    <div class="church-2-title">
                            <p>Церковь во имя святого благоверного князя Глеба, с. Кирза</p>
                        </div>
                       <div class="church-1-image">
                            <img src={church_7_image} width={500} alt="Церковь во имя святого благоверного князя Глеба" title="Храм" />
                        </div>
                        <div class="inf-4">В с. Кирза Ордынского района до революции был храм, построенный  
                        и освященный в 1912 году. После революции,   
                        в 1932 году он был закрыт и вскоре разрушен. 
                        <div>В 2011 году жители начали восстановление святыни. Был отслужен молебен для всех жителей 
                            села о возрождении храма.
                            В 2012 году состоялось освящение места, креста и закладного камня под основание храма.
                            Летом  2016 года началось строительство храма, а 
                             12 октября 2017 года состоялось его освящение.
                             <div>Храм  построен в течение года,  кроме того было выполнено благоустройство территории. 
                             Отдельно была воздвигнута небольшая колокольня и установлены колокола.</div>
                        </div>
                        <p><b>Адрес:</b> с. Кирза, ул. Красноармейская, 5.</p>
                        </div>
                    </div>
                    </table> 
                </div>
                

               
                <div class="monasteries">
                        
                    <div>
                        <h1 className='churches-title'>Монастыри Ордынского района</h1>
                    </div>

                    <table>
                    <div class="monastery-1">
                        <div class="church-1-title">
                            Михаило-Архангельский мужской монастырь, с. Козиха.
                        </div>
                        
                        <div class="church-1-image">
                            <img src={monastery1} width={500} alt="Михаило-Архангельский женский монастырь (с. Малоирменка)" title="Монастырь1" />
                        </div>
                        <div class="inf-4"> 17 июля 1997 года Священный Синод Русской Православной Церкви
                         благословил открытие монастыря в честь Архангела Михаила. Первоначально монастырь 
                         располагался в соседней деревне Малоирменка Ордынского района Новосибирской области.
                         В 1998 году братия переехала в с. Козиха. Храм и братский корпус здесь построены на месте, 
                         где до революции была деревянная приходская церковь во имя Архистратига Михаила, построенная в 1903 
                         году и освященная в 1906 году святителем Макарием (Невским), архиепископом Томским и Барнаульским.
                         

                        <p><b>Адрес:</b> с. Козиха, ул. Чиковская, 1а.</p>
                        </div>
                    </div>
                    </table>
<p></p>

                    <table>
                    <div class="monastery-1">
                        <div class="church-1-title">
                            Михаило-Архангельский женский монастырь, с. Малоирменка.
                        </div>
                        
                        <div class="church-1-image">
                            <img src={monastery} width={500} alt="Михаило-Архангельский женский монастырь (с. Малоирменка)" title="Монастырь1" />
                        </div>
                        <div class="inf-4"> В 1997 году был основан Михаило-Архангельский мужской монастырь 
                        в д. Малоирменка.
                        В 1998 году монашествующие переехали в село Козиха, а в деревне Малоирменка был 
                        создан Михаило-Архангельский женский монастырь.
                        <p><b>Адрес:</b> д. Малоирменка, ул. Дачная, 2.</p>
                        </div>
                    </div>
                    </table>

                </div>
                <div class="info-region">

                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Караканский бор</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={d1} width={700} alt="Озеро Горькое" title="Озеро" />
                            </div>
                        </div>
                    </div>

                    



                </div>
                
                
            </section>
        </div>
    );
}

export default Ordinskiy