import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";

import sv_Innocent from './img_sobor/sv-Innocent.jpg'
import sv_Innocent1 from './img_sobor/innocent.jpg'
import sv_nov from './img_sobor/sv_nov.jpg'


function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <title>Священномученик Иннокентий Кикин</title>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Священномученик Иннокентий Кикин</b>
        </div><p></p> 
      


                  {/* Священномученик Иннокентий Кикин */}
                   <div> <table>
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={sv_Innocent} width={280}
                                alt="Священномученик Иннокентий Кикин"
                                title="Священномученик Иннокентий Кикин" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        Священномученик Иннокентий Кикин родился в 1878 году в селе Ушковском Каинского уезда Томской 
                        губернии в семье священника. После окончания в 1899 году Томской духовной семинарии он служил 
                        псаломщиком и учителем подготовительных классов в Томском духовном училище, а затем псаломщиком 
                        и учителем церковно-приходской школы в селе Нижне-Каменское Барнаульского уезда Томской губернии 
                        (ныне Ордынский район Новоси­бирской области). В 1910 году отец Иннокентий был рукоположен 
                        во иерея и назначен настоятелем Никольской церкви села Нижне-Каменское Барнаульского уезда.  </div>
                        <div class ={"inf-5"}>Отец Иннокентий пользовался уважением и любовью прихожан. Большой любитель 
                        пения, он организовал в приходе любительский хор. Во время Гражданской войны использовал 
                        церковные средства, чтобы купить хлеб для голодающих односельчан. В 1922 и 1929 годах иерея 
                        Иннокентия арестовывали, причём в 1929 году его предполагали выслать в трудовую колонию. 
                        Но в обоих случаях он возвращался к своему священническому служению. Вместе со 
                        своим другом протоиереем Николаем Ермоловым призывал паству не поддерживать обновленческий раскол.</div>
                        <div class ={"inf-5"}>Отец Иннокентий Кикин был арестован 28 июля 1937 года, вместе с другими 
                        священниками Ордынского благочиния по делу «Сибирского церковно-монархического центра». 
                        Его обвиняли в том, что он вёл активную организованную контрреволюционную работу, группируя 
                        вокруг себя недовольные и кулацкие элементы, вёл контрреволюционную агитацию за поражение 
                        СССР в будущей войне, распространял провокационные слухи о скором падении соввласти. 
                        Отца Иннокентия также обвиняли в том, что он провёл у себя в храме собрание прихожан 
                        «с целью срыва антирелигиозной беседы, проводимой сельским активом». Эта «антирелигиозная беседа» 
                        была устроена властями для того, 
                        чтобы подготовить закрытие в селе церкви, а пастырь Божий, напротив, срочно 
                        организовал её ремонт. 
                        <div class="church-1r-image">
                            <img src={sv_Innocent1} width={480}
                                alt="Священномученик Иннокентий Кикин"
                                title="Священномученик Иннокентий Кикин" />
                        </div>
                        <div>На следствии отец Иннокентий мужественно отвечал гонителям: «Мой арест был необходим, 
                          потому что без меня легче отобрать церковь, так как теперь среди верующих не осталось человека, 
                          знающего законы. А для этого меня и арестовали». На вопрос следователя «Вы были против закрытия 
                          церкви?» он прямо ответил: «Я был против закрытия церкви и положил много сил для этого. 
                          Я старался произвести ремонт и говорил своему церковному совету о том, что если придёт из 
                          краевого исполкома отношение о закрытии, то можно ещё ходатайствовать перед ВЦИКом, 
                          ну а если из ВЦИКа придёт постановление о закрытии, то уже больше ходатайствовать не перед кем». 
                          Отец Иннокентий категорически отрицал участие в контрреволюционной организации и факт вербовки 
                          его со стороны протоиерея Николая Ермолова. Виновным в предъявленном ему обвинении себя не 
                          признавал, показания вызванного следователем лжесвидетеля не подтвердил. Как и его собрат по 
                          священнослужению протоиерей Николай Ермолов, 
                          отец Иннокентий на все требования назвать сообщников ответил отказом. </div>
                          <div class="church-1l-image">
                            <img src={sv_nov} width={280}
                                alt="Священномученик Иннокентий Кикин"
                                title="Священномученик Иннокентий Кикин" />
                        </div>
                          <div>27 сентября 1937 года был этапирован в Новосибирск. 1 октября особая тройка УНКВД по 
                            Запсибкраю приговорила Кикина Иннокентия Павловича к расстрелу. 26 октября 1937 года приговор 
                            в отношении него и священника Николая Ермолова был приведён к исполнению. 
                            Место общей могилы, где были погребены мужественные страдальцы за веру, не установлено.</div>
                            
                          <div>2 августа 1958 года Президиумом Новосибирского областного суда И.П.Кикин был реабилитирован.</div>
                          
                                                  
                          <div>24 апреля 2002 года священник Иннокентий Кикин вместе
                             с протоиереем Николаем Ермоловым был прославлен в лике священномучеников.</div>
                                                     
                              
                              <div>Память священномученика Иннокентия Кикина Русская Право3славная Церковь чтит в день 
                                его мученической кончины — 13 ок­тября (26 октября н.ст.), в Соборе святых Новосибирской 
                                митрополии 16 февраля (29 февраля н.ст.) и в Соборе новомучеников и исповедников 
                                Церкви Русской (первое воскресенье, начиная с 25 января (7 февраля н.ст.)).
 
                      </div>
                      </div> 
                      
                    </table>
                    </div>   
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
