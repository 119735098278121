import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import sv_Valentin from './img_sobor/prmch-valentin-1.jpg'
import sv_Valentin1 from './img_sobor/prmch-valentin-2.jpg'



function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <title>Преподобномученик Валентин (Лукьянов)</title>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Преподобномученик Валентин (Лукьянов)</b>
        </div><p></p> 
      


                  {/* Преподобномученик Валентин (Лукьянов) */}
                   <div> <table>
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={sv_Valentin1} width={280}
                                alt="Преподобномученик Валентин (Лукьянов)"
                                title="Преподобномученик Валентин (Лукьянов)" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        В сонме святых Новосибирской митрополии прославляется преподобномученик Валентин (Лукьянов; 1875–1940). 
                        Иеромонах Валентин был причислен к лику святых новомучеников и исповедников Церкви Русской в августе 2000 года 
                        Юбилейным Архиерейским Собором Русской Православной Церкви. 
                        Мученический подвиг иеромонаха Валентина завершился 1 июня 1940 года в исправительно-трудовом лагере 
                        в Новосибирской области.
                        <div>Преподобномученик Валентин родился в 1875 году в Калужской губернии в семье крестьянина. 
                          В крещении получил имя Василий. 
                          Окончил церковно-приходскую школу. Женился, имел троих детей, после рождения третьего ребёнка овдовел. </div>
                        <div>В 1906 году Василий Лукьянов поступил в Троице-Сергиеву лавру и спустя два года был зачислен в неё послушником. 
                          Поскольку он имел певческий дар,
                           то был определён на клирос. 7 ноября 1915 года послушник Василий принял монашеский постриг с именем Валентин. 
                        <div>В 1920 году Троице-Сергиева лавра была закрыта, братия изгнана из обители. 
                          Монах Валентин стал служить псаломщиком в храме во имя святой великомученицы Параскевы Пятницы — 
                          Пятницком подворье, которое получило название «Малая лавра».  </div>

                          <div>20 сентября того же года Святейший Патриарх Тихон рукоположил его во иеродиакона, 
                            а 26 ноября 1923 года — во иеромонаха к Введенской церкви села Котово Звенигородского уезда. </div>
                          <div>Вплоть до своего ареста в 1937 году иеромонах Валентин служил в разных храмах Московской области: 
                            Введенском храме села Котово Звенигородского уезда, Никольском храме города Наро-Фоминска, 
                            в одном из храмов города Кунцево (ныне — район Москвы), Никольском храме села Ромашково 
                            (ныне — Одинцовский район Москвы). В годы гонений на православную веру храмы один за другим закрывались,
                             и отец Валентин, как и многие другие священнослужители, переходил на новое место служения. </div>
                          
                          
                              <div class="church-1r1-image">
                            <img src={sv_Valentin} width={350}
                                alt="Прославление святых"
                                title="" />
                          </div>
                              <div> 26 ноября 1937 года иеромонах Валентин был арестован по доносу одного из жителей Кунцева. 
                                Тот показал, что ещё в июне священник проводил с женщинами беседу, во время которой читал им какую-то религиозную книгу,
                                 а затем разъяснял прочитанное. Ещё в разговоре отец Валентин сказал, что мы живём в такое время, когда православная вера 
                                 и духовенство у советской власти не в почёте, коммунисты направляют все усилия к окончательному разгрому Православной Церкви,
                                  но верующие всеми способами должны этому противодействовать, 
                                распространяя слово Божие среди своих знакомых, укрепляя веру с надеждой на то, что этому тиранству придёт конец. </div>
                              <div>Иеромонаха Валентина заключили в Таганскую тюрьму в Москве и на следующий день допросили. От него ждали признания в том, 
                                что он «будучи священником, систематически использовал своё служебное положение для антисоветской деятельности», 
                                «среди населения города Кунцево систематически распространял контрреволюционные клеветнические слухи о притеснении религии 
                                в Советском Союзе». 
                                Однако священник отказался признать себя виновным. В тот же день следствие было закончено. </div>
                              <div>29 ноября 1937 года тройка НКВД приговорила отца Валентина к десяти годам заключения. </div>
                              <div>Скончался иеромонах Валентин (Лукьянов) 1 июня 1940 года в исправительно-трудовом лагере 
                                в Новосибирской области и был погребён в безвестной могиле.</div>
                              <div>В августе 2000 года иеромонах Валентин (Лукьянов) был причислен к лику святых. </div>
                              <div>Память преподобномученика Валентина (Лукьянова) совершается 19 мая (1 июня н.ст.), в Соборе святых
                                 Новосибирской митрополии 16 февраля (29 февраля н.ст.) 
                                и в Соборе новомучеников и исповедников Церкви Русской (первое воскресенье, начиная с 25 января (7 февраля н.ст.)).</div>
                      </div> 
                      </div>
                    </table>
                    </div>   
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
