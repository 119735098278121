import React from 'react'
import flag from "./region/Toguchinskiy/herb1.png"
import emblem from "./region/Toguchinskiy/herb2.png"
import church_1_image from "./region/Toguchinskiy/h1.png"
import church_2_image from "./region/Toguchinskiy/h2.png"
import church_3_image from "./region/Toguchinskiy/h7.jpg"
import church_4_image from "./region/Toguchinskiy/h4.png"
import church_5_image from "./region/Toguchinskiy/h8.jpg"
import church_6_image from "./region/Toguchinskiy/h9.jpg"
function Toguchinskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Флаг" title="Герб" />
                    </div>
                    <h1 className='head'>Тогучинский район</h1>
                </div>
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <p>Административный центр — город Тогучин. Образован 17 июня 1929 года 
                            как Вассинский район с центром в селе Вассино, в составе 
                            Новосибирского округа Сибирского края. В 1930 году Сибирский край 
                            был разделён на западную и восточную части (Новосибирский округ был 
                            при этом упразднен) и район оказался в составе Западно-Сибирского края. 
                            Постановлением ВЦИК от 1 января 1932 года Вассинский район переименован в
                             Тогучинский, центр района был перенесён в посёлок при станции Тогучин. 
                             В 1937 район был включён в Новосибирскую область. Северный епархиальный округ 
                             Искитимской Епархии.</p>
                    </div>
                </div>
                <div class="info-region">
                 
                </div>
                <div class="churches">
                <div class="churches title">
                        <h2 className='churches-title'>Церкви Тогучинского района</h2>
                    </div>


                    <table class='table-w'>
                    <div class="church-1">
                       
                        <div class="church-1-title">
                            Храм во имя преподобного Сергия Радонежского в г. Тогучин
                        </div>
                     
                        <div class="church-1-image">
                            <img src={church_1_image} width={500} alt="Храм" title="Храм1" />
                        </div>
                        <div class ={"inf-4"}><p>Храм был освящен в декабре 2020 года.</p>
                        <p><b>Адрес:</b> г. Тогучин, ул. Садовая, 11а. </p>
                         </div> 
                       

                    </div>
                    </table> 

<p></p>

                    <table class='table-w'>
                    <div class="church-1">
                       
                        <div class="church-1-title">
                            Храм в честь Рождества Христова, п. Горный
                        </div>
                     
                        <div class="church-1-image">
                            <img src={church_5_image} width={500} alt="Храм в честь Рождества Христова, п. Горный" 
                            title="Храм в честь Рождества Христова1" />
                        </div>
                        <div class ={"inf-4"}>
                        Приход в честь Рождества Христова был образован осенью 1996 года.
                        7 июня 1997 года состоялся чин освящения закладного камня в основание будущего  храма.
                        К августу 2004 года были воздвигнуты стены, установлен центральный шатер, купол и крест. 
                        27 апреля 2005 года были установлены четыре угловых шатра с крестами. 
                        30 апреля 2006 года, в Фомину неделю была совершена первая Божественная Литургия.
                        
                       
                        <p><b>Адрес:</b> п. Горный, ул. Советская, 13. </p>
                         </div> 
                       

                    </div>
                    </table> 




                    {/* <div class="church-2">
                        <div class="church-2-title">
                            <p>Храм во имя великомученика Георгия Победоносца в с. Юрты</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} width={800} alt="Храм во имя преп. Сергия Радонежского в г. Тогучин" title="Храм2" />
                        </div>
                    </div> */}

<p></p>

                    <div class="church-3">
                        
                        <div class="church-1-title">
                          <p></p>  Храм в честь Святителя Николая Чудотворца,  с. Карпысак
                        </div>
                        <table class='table-w'>
                        <div class="church-1-image">
                            <img src={church_3_image} width={450} alt="Храм в честь Святителя Николая Чудотворца" title="Храм3" />
                        </div>
                        <div class ={"inf-4"}>
                        <p> <b>Адрес:</b>  с. Карпысак, ул. Больничная, 25 к1.</p>
                         </div> 
                        </table>
                    </div>


                    <table class='table-w'>
                    <div class="church-4">
                       
                        <div class="church-1-title">
                            <p>Храм во имя святого праведного Симеона Верхотурского, п. Шахта</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_4_image} width={500} alt="Храм во имя Симеона Верхотурского в п. Шахта" title="Храм во имя Симеона Верхотурского в п. Шахта" />
                        </div>
                        <div class ={"inf-4"}>
                        <p><b>Адрес:</b>  пос. Шахта, ул. Гагарина, 35. </p>
                         </div> 
                       
                    </div> 
                    </table>


                    <table class='table-w'>
                    <div class="church-4">
                       
                        <div class="church-1-title">
                            <p>Храм в честь Казанской иконы Божией Матери, с. Борцово</p>
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_6_image} width={500} alt="Храм в честь Казанской иконы Божией Матери, с. Борцово"
                             title="Храм в честь Казанской иконы Божией Матери" />
                        </div>
                        <div class ={"inf-4"}>
                        <p><b>Адрес:</b> село Борцово, ул. Центральная, 74. </p>
                         </div> 
                       
                    </div> 
                    </table>

                </div>


                <div class="attractions">
                        {/*none*/}
                    </div>
            </section>
        </div>
    );
}

export default Toguchinskiy