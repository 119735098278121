import React from 'react'
import flag from "./region/Ubinskiy/herb1.png"
import emblem from "./region/Ubinskiy/herb2.png"
import church_1_image from "./region/Ubinskiy/h1.jpg"
import v from "./region/Ubinskiy/v.jpg"
function Ubinskiy() {
    return (
        <div class='main'>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
            <meta name="viewport" content="user-scalable=yes"/>
            <title>Document</title>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Флаг " title="Герб" />
                    </div>
                    <h1 className='head'>Убинский район</h1>
                </div>
        
          
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <p>Административный центр — село Убинское.
                            Район образован в 1925 году в составе Барабинского округа Сибирского края. 
                            В 1930 году Сибирский край был разделён на западную и восточную части, 
                            и район оказался в составе Западно-Сибирского края.
                            В 1937 году район был включён в состав Новосибирской области.
                            Восточное благочиние Каинской епархии.
                        </p>
                    </div>
                </div>

                <div class="churches">
                <div class="churches title">
                        <h2 className='churches-title'>Церкви Убинского района</h2>
                    </div>


                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм во имя преподобного Сергия Радонежского, с. Убинское</p>
                        </div>

                        <table>
                        <div class="church-1-image">
                            <img src={church_1_image} width={400} alt="Храм во имя преп. Сергия Радонежского в с. Убинское" title="Храм1" />
                        </div>

                        
                        <div class ={"inf-4"}>
                        Восстановление церковной жизни и храма в селе Убинском началось в начале 1990-х годов.
                        В 1993 году был образован приход.
                        Убинским сельсоветом Приходу было передано здание по улице Ленина. 
                        Трудами верующих здание стали 
                        приводить в порядок и оно было переоборудовано для совершения богослужений.
                        6 апреля 1996 года епископ Новосибирский и Бердский Сергий освятил антиминс 
                        для Прихода во имя преподобного Сергия Радонежского в селе Убинском.
                        27 октября 1999 года состоялась регистрация прихода.

                    <p><b>Адрес:</b> село Убинское, ул. Ленина 2. 
                    {/* <div>Телефон: +7 (38366) 21-972.</div> */}
                        </p> </div>
                        </table>
                    </div>
                    
                
                    <div class="attractions">
                        <div className="head1">
                            <h2 className="head1">Природные достопримечательности </h2>
                        </div> 
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Васюганские болота</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={v} width={800} alt="Васюганские болота" title="Васюганские болота" />
                            </div>
                        </div>
                        
                        <p> </p>
              
                        
                        <p> </p>
 

                        <p> </p>

                    </div>
                    


                    
                        
                        
                        
                        </div>
            </section>
        </div>
    );
}

export default Ubinskiy