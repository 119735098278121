import React from 'react'
import flag from "./region/Chistoozerniy/herb1.png"
import emblem from "./region/Chistoozerniy/herb3.png"
import church_1_image from "./region/Chistoozerniy/h1.jpg"//"./region/Chistoozerniy/h1.png"
import church_2_image from "./region/Chistoozerniy/h3.jpg"
import church_3_image from "./region/Chistoozerniy/hh3.jpg" //"./region/Chistoozerniy/h3.png"
import oo1 from "./region/Chistoozerniy/oo1.jpg"
import aa1 from "./region/Chistoozerniy/aa1.png"

function Chistoozerniy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                
                    <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Герб" title="Герб" />
                    </div>
                    <h1 className='head'>Чистоозерный район</h1>
                </div>


                
                <div class="about-region">
                    
                                    <div class="inf-4">
                       
                            В 1925 году был образован Юдинский район с центром в селе Юдино в составе Барабинского округа Сибирского края. В 1930 году Юдинский район оказался в составе Западно-Сибирского края. В 1935 году райцентр был перенесён в село Чистоозёрное, а район переименован в Чистоозёрный. В 1937 году район был включён в состав новообразованной Новосибирской области.
                            Административный центр — рабочий посёлок Чистоозёрное. Население: 16 617 чел.
                    </div>
                    
                </div>
                
                <div class="info-region">
                    
                <div class="churches">
                    <div class="churches-titile">
                    <h2 className='churches-title'>Церкви Чистоозерного района</h2>
                    </div>

                    <table class='table-w'>
                    <div class="church-1">
                        <div class="church-1-title">
                            Храм Успения Пресвятой Богородицы
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={500} 
                            alt="Храм Успения Пресвятой Богородицы" 
                            title="Храм Успения Пресвятой Богородицы" />
                        </div>
                    </div>
                               
                    <div class ={"inf-4"}>
                    Православная община в рабочем посёлке Чистоозёрное была зарегистрирована в 1998 году. 
                    <div>1 октября 2002 года Тихон, архиепископ Новосибирский и Бердский, 
                        освятил закладной камень на начало строительства храма в честь Успения Божией Матери. 
                   Строительство храма было вскоре завершено, и уже в январе 2004 года храм был освящен.</div>
                   <div>В храме есть икона святителя Луки Архиепиского Крымского с частицей его святых мощей. 
                    </div> 
                    <p><b>Адрес:</b> ​рп. Чистоозерное, ул. Покрышкина, 5. </p>  
                    </div> 
                    </table>
                
                
                <p></p>
                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм в честь святого благоверного князя Александра Невского, с. Барабо-Юдино
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={500} 
                                alt="Храм в честь св. Александра Невского."
                                title="Храм в честь св. Александра Невского" />
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    
                    
                    Храм в честь святого благоверного князя Александра Невского в селе Барабо-Юдино
                    был освящен в 2007 году. 
                    
                 <p>  <b>Адрес:</b> с. Барабо-Юдино, ул. Центральная, 21.  </p>
                    </div>
                    </table>
                    

                    
                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Храм в честь Покрова Пресвятой Богородицы села Покровка</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_3_image} width={500} alt="" title="Храм3" />
                        </div>
                        <div class='inf-4'>
                         Храм в честь Покрова Пресвятой Богородицы был построен в селе Покровка в 1906 году. 
                         Впоследствии, в годы гонений, был закрыт и частично разрушен.
                        <div> В 2000-е годы началось восстановление святыни. 
                         В 2012 г. состоялось малое освящение и открытие храма. </div>
                       
                    <p><b>Адрес:</b> Чистоозёрный район, село Покровка, ул. Школьная, 4Б.</p>
                    </div>


                    </div>

                    

                    </table>  
                </div>
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Достопримечательности</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>озера Лечебное, Островное и Горькое с лечебными грязью и водой</p>

                                <img src={oo1} width={600} alt="Герб" title="Герб" />

                                




                            </div>
                        </div>
                        <div class="attraction-2">
                            <div class="attraction-2-text">
                                <p>Заказник «Юдинский».</p>
                                <img src={aa1} width={600} alt="Герб" title="Герб" />
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                
            </section>
        </div>
    );
}

export default Chistoozerniy