import React from 'react'
import flag from "./region/Novosibirskiy/herb1.png"
import emblem from "./region/Novosibirskiy/h11.png"
import attraction_1_image from "./region/Novosibirskiy/admin.jpg"
import church_1_image from "./region/Novosibirskiy/Berdsk.jpeg"
import church_2_image from "./region/Novosibirskiy/Berdsk_2.jpg"
import church_3_image from "./region/Novosibirskiy/Berdsk_3.jpg"
import church_4_image from "./region/Novosibirskiy/Berdsk_4.jpg"
import church_5_image from "./region/Novosibirskiy/Koltsovo.jpg"
import church_6_image from "./region/Novosibirskiy/Mochishe.jpg"
import church_7_image from "./region/Novosibirskiy/Razdolnoe.jpg"
import church_8_image from "./region/Novosibirskiy/Gus_brod.jpg"
import church_9_image from "./region/Novosibirskiy/Krasnoobsk.jpg"
import church_10_image from "./region/Novosibirskiy/NPDS2.jpg"
import church_12_image from "./region/Novosibirskiy/Tolmachevo-3 copy.jpg"
import church_13_image from "./region/Novosibirskiy/verh-tula2.jpg"




function Novosibirskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Новосибирский район</title>
            </div>
            <section class="region">
               
               
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Герб Новосибирский район>" title="Герб" />
                    </div>
                    <h1 className='head'>Новосибирский район</h1>
            </div>
               
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <div>Новосибирский район (включавший на тот момент г. Новосибирск)
                             был образован в 1929 году в составе Новосибирского округа Сибирского края,
                              с 1930 в составе Западно-Сибирского края. В 1932 году район был упразднён,
                               а его территория передана в подчинение Новосибирскому горсовету.</div>
                              <div>  22 февраля 1939 года район был восстановлен в составе Новосибирской области,
                                 при этом город Новосибирск не вошёл в его состав, 
                            образовав самостоятельную административно-территориальную единицу.</div>
                            <div>Район расположен в восточной части Новосибирской области, 
                            со всех сторон примыкая к городу Новосибирску. Граничит с Мошковским, Тогучинским, Искитимским, Ордынским,
                             Коченёвским и Колыванским районами Новосибирской области.
                              Территория района по данным на 2008 год — 222,3 тысяч га, в том числе 
                              сельхозугодья — 124,9 тысяч га (56,2 % всей площади). Население 161 124 чел. (2021 г.)</div>
                    </div>
                </div>
                <div class="info-region">
                   
                </div>
                
                 <div class="about-region">
                    
                </div>
                <div class="info-region">
                    
                </div>
               
                              
                <div class="churches">
                    <div>
                        <h2 className='churches-title'>Церкви Новосибирского района</h2>
                    </div>
                    
                    <div >
                    <table>
                        <div class="church-1-title">
                            <p>Собор в честь Преображения Господня г. Бердска (кафедральный)</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={430} alt="Храм в честь Иконы Божией Матери Всех Скорбящих Радость"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Строительство собора начато 7 июля 1992 года. Проект выполнен новосибирским архитектором
                         Петром Чернобровцевым 
                        (автор проекта восстановления Николаевской часовни в Новосибирске).
                        <div>Храм имеет два уровня:

<div>нижний — во имя трёх святителей (освящён в 1999 году епископом Сергием (Соколовым))</div>
<div>верхний — в честь Преображения Господня (в 2001 году (малое освящение); в 2004 году освящение 
главного престола совершил архиепископ Новосибирский и Бердский Тихон (Емельянов).</div>
<div>В августе 1998 года из Каменск-Уральска были привезены девять колоколов, установленных на
соборной звоннице.</div>
<div>В церковной ограде рядом с алтарём находятся могилы архимандрита Макария
 (Реморова) (†08 декабря 1998 года) и епископа Новосибирского и Бердского Сергия (Соколова)
  (†20 октября 2000 года).</div></div> 
                    <p><b>Адрес:</b> г. Бердск, ул. Максима Горького, 44. <div>
                         </div>
                        </p>
                    </div>
                    </table>
                    </div>



                    {/* Храм в честь Сретения Господня, г. Бердск */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь Сретения Господня, г. Бердск</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={400} alt="Храм Сретения Господня"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                         Историю храма можно отследить еще со времен Бердского острога: первый
                          одноименный деревянный храм был построен в 1716 г. 
                          Позднее, его перестроили в камне. В 1930-е гг. XX в. храм разрушили, 
                          а место где он стоял, затопили Обским морем.
                          <div>В 1989 году приход был возрожден. Частный дом переоборудовали в храм.
                         Новый деревянный храм начали строить в 2005 году и освятили в 2009 г. От первого храма до 
                         наших дней сохранился колокол, который до сих пор звонит на колокольне.</div>
                          
                         <p><b>Адрес:</b> г. Бердск, ул. Кутузова, 17. 
                         
                        </p> </div>
                    </table>
                    </div>



                    {/* Храм в честь Богоявления, г. Бердск */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь Богоявления, г. Бердск</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={450} alt="Храм Сретения Господня"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Строительство храма в честь Богоявления, Крещения Господня было начато по инициативе горожан Бердска 
                        в 2014 году. В сентябре 2014 года в День города был освящён закладной камень 
                        в основание храма. Меньше чем за два года были возведены стены и крыша, а
                        летом 2016 года были освящены и воздвигнуты купола и кресты в навершие храма.
                          <div>Освящение состоялось 1 апреля 2017 года в день похвалы Пресвятой Богородицы, 
                            которое совершил Митрополит Бердский и Новосибирский Тихон. </div>
                          
                         <p><b>Адрес:</b> г. Бердск, ул. Боровая, 111. 
                         
                        </p> </div>
                    </table>
                    </div>



                    {/* Храм во имя великомученика Георгия Победоносца, пос. Новый (г. Бердск) */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм во имя великомученика Георгия Победоносца, пос. Новый (г. Бердск)</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_4_image} width={450} alt="Храм Сретения Господня"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Церковь была устроена в 2008 году в деревяннном здании бывшей школы. 
                        До 2015 собственного причта не имела, 
                        была приписана сначала к Преображенскому, затем к Сретенскому храму 
                        г. Бердска.  
                          <div> </div>
                          
                         <p><b>Адрес:</b> пос. Новый, ул. Темирязева, 7. </p> 
                          </div>
                    </table>
                    </div>


                    {/* Храм в честь Введения во Храм Пресвятой Богородицы, наукоград Кольцово */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь Введения во Храм Пресвятой Богородицы, наукоград Кольцово</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_5_image} width={450} alt="Храм Сретения Господня"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Строительство храма в честь Введения во храм Пресвятой Богородицы началось
                        в 1999 году и продолжалось до 2009 года. С 2009 года в храме регулярно 
                        совершаются Богослужения. 
                        На территории храма в 2018 году установили Памятный Крест, в память 100-летия начала гонений 
                        на Русскую Православную Церковь и 100 – летия мученической кончины Царственных Страстотерпцев. 
                          <div> </div>
                          
                         <p><b>Адрес:</b> наукоград Кольцово, 17А. 
                               
                        </p> </div>
                    </table>
                    </div>


                    {/* Храм в честь иконы Божией Матери «Скоропослушница», ст. Мочище */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь иконы Божией Матери «Скоропослушница», ст. Мочище</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_6_image} width={450} alt="Храм Сретения Господня"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Приход здесь был создан 22 ноября 1993 года. Поскольку 
                        в этот день Русская Православная Церковь празднует память иконы Божией Матери 
                        «Скоропослушница», то с названием храма вопросов не возникло — конечно, храм должен 
                        быть освящен в честь этой святой иконы. В 1995 году на Преображение Господне из Новосибирска 
                        в дар приходу передали икону Пресвятой Богородицы «Скоропослушница». Икона потемневшей, 
                        но прихожане были очень рады и сразу установили икону в центре храма. А в Крещенский 
                        сочельник икона обновилась. Это известие мгновенно облетело Новосибирскую епархию. 
                        Православные христиане потянулись в Мочище, 
                        чтобы помолиться у чудесно обновившейся иконы, испросить у Богородицы помощи. 
                        В 2006 году началось строительство нового храма, который был освящен в 2021 году. 
                                                    
                         <p><b>Адрес:</b> ул. Линейная, 64Б. Станция Мочище. 
                                
                        </p> </div>
                    </table>
                    </div>



                    {/* Храм в честь иконы Божией Матери «Смоленская», с. Раздольное */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь иконы Божией Матери «Смоленская», с. Раздольное</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_7_image} width={450} 
                            alt="Храм в честь иконы Божией Матери «Смоленская»"
                                title="Храм в честь иконы Божией Матери «Смоленская»" />
                        </div>
                        <div class ={"inf-4"}>
                        Деревянный храм в честь иконы Божией Матери Смоленская расположен при въезде в село Раздольное, 
                        в парковой зоне, вблизи мемориала героям Великой Отечественной Войны. 
                        Закладной камень в основание храма был освящен 2 июля 2010 года. 
                        Храм был построен и освящен в 2015 году.
                        
                                                    
                         <p><b>Адрес:</b> с. Раздольное, ул. Ленина, 3а. 
                                
                        </p> </div>
                    </table>
                    </div>
                  

                      {/* Храм в честь Святителя и Чудотворца Николая, с. Гусиный Брод */}
                        <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь Святителя и Чудотворца Николая, с. Гусиный Брод</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_8_image} width={450} 
                            alt="Храм в честь Святителя и Чудотворца Николая, с. Гусиный Брод"
                                title="Храм в честь Святителя и Чудотворца Николая" />
                        </div>
                        <div class ={"inf-4"}>
                        История появления храма Николая Чудотворца в селе Гусиный Брод связана с идеей строительства 
                        недалеко от села женского монашеского скита. Под организацию скита отвели земельный участок в стороне от жилой застройки, 
                        в уединенном месте, в живописном природном окружении. Там был возведен храм, два жилых дома, хозяйственные постройки.
                        Позже встал вопрос о переносе храма ближе к селу. В 2018 году был освящен новый фундамент для храма. 
                        <div>В течение осени-зимы 2018-2019гг. велись работы по возведению Никольского храма на новом месте. 
                         Первый молебен состоялся в конце марта 2019 года, а 22 мая, 
                        на Престольный праздник, по благословению митрополита Новосибирского и Бердского Никодима была отслужена 
                        первая Божественная Литургия. Этот день верующие жители села считают днем второго рождения своего храма. 
                        С этого момента богослужения в храме совершаются регулярно.</div>
                        
                                                    
                         <p><b>Адрес:</b> с. Гусиный Брод, ул. Центральная, 27. 
                                
                        </p> </div>
                    </table>
                    </div>



                     {/* Храм в честь Казанской иконы Божией Матери, п. Краснообск */}
                     <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь Казанской иконы Божией Матери, п. Краснообск</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_9_image} width={450} 
                            alt="Храм в честь Казанской иконы Божией Матери, пос. Краснообск"
                                title="Храм в честь Казанской иконы Божией Матери" />
                        </div>
                        <div class ={"inf-4"}>
                            Приход честь иконы Божией Матери «Казанская» в поселке Краснообск был основан 27 августа 1992 года. 
                            22 октября 1992 года состоялось освящение закладного на месте строительства будущего храма.
                        Строительные работы начались в июне 1996 года. 4 ноября 2004 года состоялось освящение нижнего храма
                         во имя Преподобного Сергия Радонежского. 
                        <div>4 ноября 2012 года в День народного единства 
                            митрополитом Новосибирским и Бердским Тихоном был освящен верхний храм в честь иконы Божией Матери Казанская.</div>
                        <p><b>Адрес:</b> п. Краснообск, ул. Западная, 1. 
                                
                        </p> </div>
                    </table>
                    </div>



                     {/* Храм во имя святителя Тихона Задонского */}
                     <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм во имя святителя Тихона Задонского 
                            <div>при Новосибирской православной духовной семинарии, г. Обь</div>
                            </p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_10_image} width={450} 
                            alt="Храм во имя святителя Тихона Задонского"
                                title="Храм во имя святителя Тихона Задонского" />
                        </div>
                        <div class ={"inf-4"}>
                        Новосибирская духовная семинария находится в городе Обь, в бывшем здании гарнизонного Дома офицеров. 
                        Когда областные власти передали здание Новосибирской епархии, оно было в полуразрушенном состоянии. 
                        Произошло это в 2004 году. Сейчас здание, в котором находится семинария, выглядит совсем по-иному — 
                        его не узнать, и только фотографии в фойе напоминают о тех руинах, которые когда-то достались епархии. 
                        <div>Храм был освящен архиепископом Новосибирским и Бердским Тихоном
                            в 2008 году в честь святителя Тихона, епископа Воронежского, Задонского чудотворца.</div>
                        <p><b>Адрес:</b> г. Обь, ул. Военный Городок, 127. 
                                
                        </p> </div>
                    </table>
                    </div>



                    {/* Храм во имя святого праведного Лазаря Четверодневного */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм во имя святого праведного Лазаря Четверодневного, с. Толмачево</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_12_image} width={500} 
                            alt="Храм во имя святого праведного Лазаря Четверодневного с. Толмачево"
                                title="Храм во имя святого праведного Лазаря Четверодневного" />
                        </div>
                        <div class ={"inf-4"}>
                        Приход в селе Толмачево был учрежден в 1998 году. 
                        До революции в селе располагался храм во имя святителя Николая, в годы гонений он был разрушен.
                         Долгое время храм был обустроен в доме священника, где в советское время находились сельская школа и библиотека. 
                        <div>В 2018 году было начато строительство нового здания храма. За 4 года было возведено 
                            капитальное кирпичное здание, в котором расположены храм святого Лазаря, трапезная и кабинеты для воскресной школы.
                            Освящение храма состоялось 20 ноября 2022 года.</div>
                        <p><b>Адрес:</b> с. Толмачево, ул. Советская, 71Б. 
                                
                        </p> </div>
                    </table>
                    </div>
                   


                    {/* Храм во имя Святой Живоначальной Троицы */}
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм в честь Святой Живоначальной Троицы, п. Верх-Тула</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_13_image} width={450} 
                            alt="Храм в честь Святой Живоначальной Троицы, п. Верх-Тула"
                                title="Храм в честь Святой Живоначальной Троицы" />
                        </div>
                        <div class ={"inf-4"}>
                       До революции храм в честь Святой Живоначальной Троицы в селе был построен в 1898 году. 
                       В 1933 году храм был закрыт и вскоре разрушен.  
                          
                        <div>Новый храм был построен и освящен в 2004 году.</div>
                        <p><b>Адрес:</b> п. Верх-Тула, 2-й квартал, 7.
                                
                        </p> </div>
                    </table>
                    </div>

<p></p>
                    <div class ={"inf-8"}>Раздел дополняется...</div> <p></p>




                </div>
            </section>
        </div>
    );
}

export default Novosibirskiy