import React from 'react'
import emblem from "./region/Kishtovskiy/herb2.png"
import attraction_1_image from "./region/Kishtovskiy/priroda1.png"
import church_1_image from "./region/Kishtovskiy/h1.jpg"
import kish from "./region/Kishtovskiy/ad.jpg"

function Kishtovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180}/>
                    </div>
                    <h1 className='head'>Кыштовский район</h1>
                </div>
                
                <div class="about-region">
                    
                        <div class="inf-4">
                        

                        
                            <p>Кыштовский район выделен из состава Барабинского округа постановлением Президиума ВЦИК 
                                25 мая 1925 года. В 1937 году из состава Западно-Сибирского края выделена Новосибирская область, 
                                на территории которой оказался Кыштовский район. Современные границы 
                                район обрёл в 1964 году, по завершении реформы административно-территориального деления.
                                <div>Административный центр — село Кыштовка. Население 9819 чел. </div></p>
                            
                           

                
                        
                        </div>

                        <div class='center-image'><img src={kish} width={700}/></div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Кыштовского района</h2>
                    </div>
                   
                    
                   


                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм в честь Святителя Николая Мирликийского Чудотворца, с. Кыштовка</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_1_image} width={400} alt="Храм в честь Святителя Николая Мирликийского Чудотворца с. Кыштовка" title="Храм1" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        <p>В 1985 году, в с. Кыштовка появилась православная община. Начали проводится службы,
                             сначала в жилом доме, а в 1989 году община переоборудовала под храм жилой дом.  
                           В 1994 году заложена каменная церковь, первая служба в которой была совершена в 1997 году. 
                         окончательно завершенная в 2004.</p>
                         <p>Выстроена однопрестольная одноглавая церковь — четверик под шестнадцатискатной 
                            крышей, с деревянной шатровой колокольней над западным фасадом.</p>
                        
                        <p><b>Адрес:</b> с. Кыштовка, ул. Ленина, 39.</p> 
                        </div>
                        </div>
                
                        </table>            
                </div>

                <p> </p>
                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Данилово</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_1_image} width={700} alt="Озеро Горькое" title="Озеро" />
                            </div>
                        </div>
                    </div>
            </section>
        </div>);
}

export default Kishtovskiy


/*
function Kishtovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Кыштовский район</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} width={300} alt="Флаг" title="Герб" />
                        
                    </div>
                    <div class="emblem">
                        <img src={emblem} width={200} alt="Герб" title="Герб" />
                    </div>
                    <div class="inf-4">
                         Административный центр- село Кыштовка.
                            Население: 9819 чел.
                            В 1870 году выделена Кыштовская волость, включавшая 42 деревни. В 1925 Кыштовский район выделен из состава Барабинского округа. В 1937 году из состава Западно-Сибирского края была выделена Новосибирская область, на территории которой оказался Кыштовский район.
                            Каинская епархия, Западный благочиннический округ.
                            Правящий архиерей - митрополит Никодим.
                        
                    </div>
                    <div class="admin-1-image">
                        <img src={admin} alt="амин" title="амин" />
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Объекты природного наследия:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озера Данилово и Карбалык</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_1_image} alt="амин" title="амин" />
                            </div>
                        </div>
                        <div class="attraction-2">
                            <div class="attraction-2-text">
                                <p>Майзасский охотничий заказник</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="churches">
                    <div class="churches-titile">
                        <h2>Церкви Кыштовского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм в честь Святителя Николая Мирликийского Чудотворца с. Кыштовка</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Храм в честь Святителя Николая Мирликийского Чудотворца с. Кыштовка" title="Храм1" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Kishtovskiy
*/