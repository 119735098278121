import React from 'react'
import './main.css'
import church_1_image from "./region/Barabinskiy/h1.jpg"
import church_2_image from "./region/Barabinskiy/h2.png"
import church_3_image from "./region/Barabinskiy/h3-1.jpg"
import church_5_image from "./region/Barabinskiy/h5.jpg"
import flag from "./region/Barabinskiy/herb1.png"
import emblem from "./region/Barabinskiy/herb2.png"
import attraction_5_image from "./region/Barabinskiy/karta.png"
import attraction_1_image from "./region/Barabinskiy/priroda.png"
import attraction_2_image from "./region/Barabinskiy/priroda2.png"
import attraction_3_image from "./region/Barabinskiy/priroda3.png"
import attraction_4_image from "./region/Barabinskiy/priroda4.png"
function Barabinskiy() {
    return (
        <div class='main'>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
{/*             <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
            <meta name="viewport" content="user-scalable=yes"/>
            <title>Document</title>
            <section class="region">
                
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Герб Барабинск>" title="Герб" />
                    </div>
                    <h1 className='head'>Барабинский район</h1>
            </div>
               
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <p>Население: 40 000 чел. (2021 г.). Год образования: 1925 в составе Барабинского округа Сибирского
                            края. В 1937 году вошел в состав новообразованной Новосибирской области.</p>
                    </div>
                </div>
                <div class="info-region">
                   
                </div>
                <div class="churches">
                    <div >
                        <h2 className='churches-title'>Церкви Барабинского района</h2>
                    </div>
                    <div class ={"inf-4"}>Первый приход православной общины г. Барабинска был образован в 1978 году. Храма в городе не было, поэтому верующие люди 
                        собирались в простом доме. Туда же по благословению митрополита Новосибирского и 
                        Барнаульского Гедеона (Докукина, †2003) приезжали священники из Новосибирска. 
                        Позднее был построен молитвенный дом, а затем и освящен храм в 
                        честь Покрова Пресвятой Богородицы. В настоящее время в Барабинске построены и 
                        действуют 3 храма. </div>
                    
                    
                    <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Кафедральный собор в честь Иконы Божией Матери Всех Скорбящих Радость</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={430} alt="Храм в честь Иконы Божией Матери Всех Скорбящих Радость"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                         Храм построен и освящен в начале 2000-х годов и является кафедральным собором г. Барабинска.  
                    <p>Адрес: г. Барабинск, ул. Карла Маркса, 85.</p> </div>
                    </table>
                    </div>
                    
                    <div class="church-2"> 
                        <div class="church-2-title">
                            <p>Храм в честь Великомученника Георгия Победоносца, г. Барабинск</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={400} alt="Храм в честь Великомученника Георгия Победоносца"
                                title="Храм" />
                        </div>
                            <div className='stolbik'> 
                                <div class="church-image-double">
                                <img src={church_5_image} width={500} alt="Храм в честь Великомученника Георгия Победоносца"
                                 title="Храм" />
                                </div>
                                <div class ={"inf-4"}>
                                Храм построен в 1997 году.  
                                <p>Адрес: г. Барабинск, ул.Цветочная, 16.</p> <p>
                                </p> <p></p>  <p> </p>  </div>
                             </div>
                    
                    </div>

                    <div class="church-1"> <table>
                        <div class="church-1-title">
                            <p>Храм в честь Святых благоверных князей Петра и Февронии (г.Барабинск)</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={600} alt="Храм в честь Иконы Божией Матери Всех Скорбящих Радость"
                                title="Храм" />
                        </div>
                        <div class ={"inf-4"}>
                        Храм был освящен 23 декабря 2017 года.   
                    <p>Адрес: г. Барабинск, ул. Деповская. Церковь находится на привокзальной площади 
                        г. Барабинска.</p> </div>
                    </table></div>
                    
                </div>

                
                <div class="attractions">
                        <div className="head1">
                            <h2 className="head1">Природные достопримечательности </h2>
                        </div> 
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Памятник природы областного значения "Казанцевский мыс".</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_1_image} width={800} alt="Казанцевский мыс" title="Мыс" />
                            </div>
                        </div>
                        
                        <p> </p>
                        <div class="attraction-2">
                            <div class="attraction-1-text">
                                <p>Кирзинский заказник.</p>
                            </div>
                            <div class="attraction-2-image">
                                <img src={attraction_2_image} width={800} alt="Кирзинский заказник." title="заказник" />
                            </div>
                        </div>
                        
                        <p> </p>
                        <div class="attraction-3">
                            <div class="attraction-1-text">
                                <p>Соленое бессточное озеро Чаны.</p>
                            </div>
                            <div class="attraction-3-image">
                                <img src={attraction_3_image} width={800} alt="Озеро Чаны" title="Озеро" />
                            </div>
                        </div>

                        <p> </p>
                        <div class="attraction-4">
                            <div class="attraction-1-text">
                                <p>Пресное озеро Сартлан.</p>
                            </div>
                            <div class="attraction-4-image">
                                <img src={attraction_4_image} width={800} alt="Озеро Сарлатан" title="Озеро" />
                            </div>
                        </div>
                        <div class="attraction-5">
                            
                        </div>
                    </div>
            </section>
        </div>
    );
}
export default Barabinskiy
