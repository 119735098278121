import React from 'react'
import emblem from "./region/Moshkovskiy/herb2.png"
import church_1_image from "./region/Moshkovskiy/h1.png"
import church_2_image from "./region/Moshkovskiy/h2.png"
import church_3_image from "./region/Moshkovskiy/ap_andreya.jpg"


import ad1 from "./region/Moshkovskiy/ad1.jpg"
import h1 from "./region/Moshkovskiy/h1.jpg"
import h2 from "./region/Moshkovskiy/h2.jpg"
import h3 from "./region/Moshkovskiy/h3.jpg"

function Moshkovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={175} alt="Флаг Баганск" title="Герб" />
                    </div>
                    <h1 className='head'>Мошковский район</h1>
                </div>
                <div class="about-region">
                    
                        <div class="inf-4">
                        
                        <p>
                            <p>В 1925 году в составе Новосибирского округа  Сибирского края на основе прежней
                            Алексеевской укрупнённой волости был создан Алексеевский район, власти которого разместились в селе
                            Алексеевка.</p> 
                            
                            <p>В 1930 году Сибирский край был разделён на западную и восточную части, и район оказался
                            в составе Западно-Сибирского края. 7 июня 1933 года райцентр село Алексеевка было переименовано в Мошково,
                            а район — в Мошковский.</p> 
                            
                            <p>28 сентября 1937 года район был включён в новообразованную Новосибирскую область.
                            В 1956 году в состав района была включена часть земель расформированного Ояшинского района.</p> 

                            <p>В 1963 году район был упразднён, а его территория вошла в состав Болотнинского района; 
                            в 1972 году Мошковский район был восстановлен в прежних границах.</p>
                            
                        </p>
                        <img src={ad1} width={600} alt="Флаг Баганск" title="Герб" />  
                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Мошковского района</h2>
                    </div>

                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм в честь святого Алексия, человека Божия</p>
                        </div>
                        <div class="church-1-image">
                            <img src={h1} width={500}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                            Верующие мошковчане в 1990 году купили на дальней окраине села небольшой дом на улице Калининская, 42,
                            обустроили его как церковь. Так было положено начало мошковскому Свято-Алексеевскому приходу. 
                            Началось регулярное богослужение в молитвенном доме.
                            
                            <div>
                            К концу уходящего века и уходящего тысячелетия храм был построен. Он встал посредине райцентра. 
                            Для мошковичан это было поистине чудом на рубеже веков.
                            </div>

                            <div>3 декабря 1999 года прошло первое вечернее богослужение в новой церкви.
                             4 декабря, в праздник Введения во храм Пресвятой Богородицы, была совершена 
                             торжественная Божественная литургия.</div>

                            <div>
                            С 2000 года при церкви действует воскресная школа для детей.
                            </div>
                        <p><b>Адрес:</b> Рабочий посёлок Мошково, ул. Максима Горького, 14.</p>  </div> 
                    </div>
                    </table>
                    

                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Храм в честь иконы Божией Матери "Неупиваемая чаша"</p>
                        </div>
                        <div class="church-1-image">
                            <img src={h2} width={500}/>
                        </div>
                        
                        <div class ={"inf-4"}>

                        
                        7 января 1999 года местная администрация выделила приходу здание,
                        в котором раньше находился клуб, кинозал, библиотека. Помещение привели в порядок. 
                        Обустроили в нём церковь.
                        

                        <div>
                        18 мая 1999 года, на престольный праздник храма, Божественную литургию совершил Преосвященнейший Сергий, 
                        епископ Новосибирский и Бердский.
                        </div>

                        <p><b>Адрес:</b> Мошковский район, п. Сокур, ул. Советская, д. 54.</p>  </div> 
                    </div>
                    </table>


                


                    <table>
                    <div class="church-3">
                        <div class="church-1-title">
                            <p>Храм в честь Владимирской иконы Божией Матери</p>
                        </div>
                        <div class="church-1-image">
                            <img src={h3} width={370}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        5 июля 2012, в день памяти священномученика Евсевия, епископа Самосатского,
                         епископ Искитимский и Черепановский Лука совершил чин Великого освящения храма в честь иконы
                         Пресвятой Богородицы Владимирская р.п. Станционно-Ояшинский Мошковского 
                         района Новосибирской области.

                            <p><b>Адрес:</b> рабочий посёлок Станционно-Ояшинский, ул. Коммунистическая, 135.</p>


                         </div> 
                    </div>
                    </table>



                    
                    <table>
                    <div class="church-3">
                        <div class="church-1-title">
                            <p>Храм во имя святого апостола Андрея Первозванного</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={370}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                           Строительство храма началось весной 2021 года.
                           К престольному празднику 13 декабря 2022 года храм был освящен
                            и открыт для прихожан. 

                        

                            <p><b>Адрес:</b> Мошковский район, село Дубровино. </p>


                         </div> 
                    </div>
                    </table>




                </div>

                
                
            </section>
        </div>);
}

export default Moshkovskiy















/*
import React from 'react'
import emblem from "./region/Moshkovskiy/herb2.png"
import church_1_image from "./region/Moshkovskiy/h1.png"
import church_2_image from "./region/Moshkovskiy/h2.png"
import church_3_image from "./region/Moshkovskiy/h3.png"
import church_4_image from "./region/Moshkovskiy/h4.png"
function Moshkovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Мошковский район (Ануреева)</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={emblem} alt="Флаг" title="Герб" /> 
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб" title="Герб" />
                    </div>
                    <div class="text">
                        <p>В 1925 году под наименованием в составе Новосибирского округа  Сибирского края на основе прежней
                         Алексеевской укрупнённой волости был создан Алексеевский район, власти которого разместились в селе
                          Алексеевка. В 1930 году Сибирский край был разделён на западную и восточную части, и район оказался
                           в составе Западно-Сибирского края. 7 июня 1933 года райцентр село Алексеевка было переименовано в Мошково,
                            а район — в Мошковский. 28 сентября 1937 года район был включён в новообразованную Новосибирскую область.
                             В 1956 году в состав района была включена часть земель расформированного Ояшинского района. 
                             В 1963 году район был упразднён, а его территория вошла в состав Болотнинского района; 
                             в 1972 году Мошковский район был восстановлен в прежних границах.</p>
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2></h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p></p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={church_4_image} alt="амин" title="амин" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="churches">
                    <div class="churches-titile">
                        <h2>Церкви Мошковского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм в честь святого Алексия, человека Божьего.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Храм в честь святого Алексия, человека Божьего" title="Храм1" />
                        </div>
                    </div>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Храм в честь иконы Божией Матери Неупиваемая чаша.</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} alt="Храм в честь иконы Божией Матери Неупиваемая чаша" title="Храм2" />
                        </div>
                    </div>
                    <div class="church-3">
                        <div class="church-3-title">
                            <p>Храм в честь иконы Пресвятой Богородицы "Скоропослушница".</p>
                        </div>
                        <div class="church-3-image">
                            <img src={church_3_image} alt="Храм в честь иконы Пресвятой Богородицы Скоропослушница" title="Храм3" />
                        </div>
                    </div>
                    <div class="church-4">
                        <div class="church-4-title">
                            <p>Храм во имя апостола Андрея Первозванного</p>
                       </div>
                        <div class="church-4-image">
                            <img src={church_4_image} alt="Храм во имя апостола Андрея Первозванного" title="Храм4" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Moshkovskiy
*/