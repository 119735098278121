import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import ww from "./vozn.jpg";
import hjk from "./xxl.jpg";
import ikona from "./Ikona Skoroposl.jpg";
import Lozhok from "./Lozhok_2.jpg";
import Lozhok2 from "./Lozhok_3.jpg";
import chas from "./chasovnya1.jpg";
import podvorie from "./podvorie_1.jpg";



function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
      <p></p>
      <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        Святыни Новосибирской епархии
      </div>
      <p></p>

      <div>
        {" "}
        <table>
          <div class="sv-title">
            <p>Часовня святителя и чудотворца Николая</p>
          </div>
          <div class="church-1-image">
            <img
              src={chas}
              width={320}
              alt="Иверская икона"
              title="Иверская икона"
            />
          </div>

          <div class={"inf-5"}>
            Святыней города Ново-Николаевска–Новосибирска стала часовня во имя
            Святителя Николая Чудотворца. Она была возведена в ознаменование
            300-летия царствования Дома Романовых.
            <div>
              Автор проекта — известный архитектор А.Д.Крячков. Строилась
              часовня на пожертвования благотворителей и горожан
              Ново-Николаевска. Это была поистине всенародная стройка. Освящение
              часовни состоялось 6 декабря 1915 года, в день памяти Святителя
              Николая Чудотворца.{" "}
            </div>
            <div>
              А 9 ноября 1929 года Новосибирский окрисполком, «учитывая
              пожелания трудящихся масс и считаясь с благоустройством города»,
              одобрил постановление Новосибирского горсовета о сносе часовни.
              Разрушение святыни началось 29 января 1930 года.{" "}
            </div>
            <div>
              В начале 1990-х годов, в ходе подготовки к празднованию 100-летия
              Новосибирска, было принято решение о восстановлении часовни. В
              юбилейном 1993 году строительство часовни во имя Святителя Николая
              было завершено.{" "}
            </div>
            <div></div>{" "}
          </div>
        </table>
      </div>

      <div>
        {" "}
        <table>
          <div class="sv-title">
            <p>Иверская икона Божией Матери</p>
          </div>
          <div class="church-1-image">
            <img
              src={ikona}
              width={320}
              alt="Иверская икона"
              title="Иверская икона"
            />
          </div>

          <div class={"inf-5"}>
            Одной из главных святынь не только Новосибирской митрополии, но и
            всей Сибири является чудотворная Иверская икона Божией Матери,
            которая находится в храме в честь иконы Божией Матери
            «Скоропослушница» на станции Мочище под Новосибирском.{" "}
    
            Приход в честь иконы Божией Матери «Скоропослушница» был открыт в
            1994 году. В следующем году на Преображение Господне в дар ему
            передали из Новосибирска икону Пресвятой Богородицы
            «Скоропослушница», а в Крещенский сочельник потемневшая икона
            обновилась. Это чудо стало предвестием знаменательного события — 5
            марта 1996 года верующими Болотнинского района в храм был передан
            чудотворный список Иверской иконы Божией Матери.{" "}
      
            Святой образ был создан в 1909 году в Русском Свято-Пантелеимоновом
            монастыре на Афоне, о чем свидетельствует печать с надписью на
            обратной стороне иконы. До 1920-х годов образ Божией Матери пребывал
            в иконостасе церкви села Рыбинск Болотнинского района, а после
            пожара, уничтожившего храм, долгие годы хранился в семье местной
            жительницы Ефросиньи. В 1970-е годы семья переехала в Болотное, и
            здесь в 1996 году произошло чудесное исцеление тяжело больной
            Светланы, правнучки Ефросиньи. Отыскав на чердаке дома икону,
            девочка стала горячо просить Божию Матерь об исцелении. Во сне ей
            привиделась Богородица, Которая положила на ее плечи Свои руки, и на
            следующее утро та проснулась исцеленной. По просьбе верующих семья
            передала чудотворный образ в недавно открывшийся храм в Мочище.{" "}
          </div>
          <p></p>
          <div class="church-1-image">
            <img src={podvorie} width={360} alt="Иверская икона" />
          </div>
          <div class={"inf-5"}>
            В 2006 было здесь было учреждено Архиерейское подворье и началось
            строительство нового храма в честь иконы Божией Матери
            «Скоропослушница». Храм был освящен в 2021 году. И теперь со всех
            концов страны на Архиерейское подворье в честь иконы Божией Матери
            «Скоропослушница» приезжают верующие, чтобы прикоснуться к святыне и
            получить исцеление. Особо они стараются приехать утром в четверг,
            когда в храме совершается молебен с акафистом Иверской иконе Божией
            Матери. О благодатной помощи Царицы Небесной свидетельствуют
            многочисленные письма исцеленных людей.
            Добраться до храма можно на железнодорожном транспорте до станции
            «Мочище», а также автобусом до остановки «Станция Мочище». Для
            паломников на подворье открыта гостиница.{" "}
          </div>
        </table>
      </div>

      <div>
        {" "}
        <table>
          <div class="sv-title">
            <p>Святой источник в п. Ложок</p>
          </div>
          <div class="church-1-image">
            <img
              src={Lozhok}
              width={470}
              alt="Святой источник"
              title="Святой источник"
            />
          </div>

          <div class={"inf-5"}>
            Храм-памятник в честь Новомучеников и исповедников Церкви Русской
            расположен недалеко от поселка Ложок Искитимского района
            Новосибирской области. Красивый храм стоит на небольшом пригорке, у
            подножия которого бьет целебный источник — Святой ключ. Святой
            источник забил в 40-х годах на месте расстрела заключенных, в числе
            которых были священнослужители.{" "}

            В годы сталинских репрессий в этих местах действовал один из самых
            страшных спецлагерей — Искитимский штрафной лагерь СибЛАГа. По
            воспоминаниям репрессированных, название города с ужасом произносили
            в лагерях Магадана и Колымы. Отправить заключенного в Искитим
            означало послать его на верную смерть. Заключенные работали в
            известняковом карьере, добывали щебень, обжигали известь в
            «костровых» печах. Почти все работы выполнялись вручную, большие
            камни дробили при помощи кувалды и металлических клиньев. Работа
            была невыносимо тяжелой, многие умирали от истощения и неотвратимого
            силикоза. Среди узников лагеря были политзаключенные и
            священнослужители.

            В 2006 году был освящен закладной камень на месте строительства
            храма в честь Новомучеников и исповедников Церкви Русской. Храм
            строился почти 10 лет и был освящен в сентябре 2015 года. Рядом с
            храмом в память о всех безвинно пострадавших в годы гонений
            установлен поклонный крест. В цокольном помещении храма разместился
            церковно-исторический музей, посвященный святым новомученикам и всем
            пострадавшим во время политических репрессий 1930-х годов.
          </div>
          <p>
            <div class="image-center">
              <img
                src={Lozhok2}
                width={770}
                alt="Храм Новомучеников}"
                title="Храм Новомучеников"
              />
            </div>{" "}
          </p>
        </table>
      </div>

      <Link to="/">
        <div class="BiExit">
          <BiExit />
        </div>
      </Link>
    </div>
  );
}

export default Ep;
