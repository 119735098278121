import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import Sv_Dimitry from './img_sobor/sv_dmitry.jpg'




function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <title>Священномученик Димитрий Семёнов</title>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Священномученик Димитрий Семёнов</b>
        </div><p></p> 
      


                  {/* Священномученик Димитрий Семёнов */}
                   <div> <table>
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={Sv_Dimitry} width={300}
                                alt="Священномученик Димитрий Семёнов"
                                title="Священномученик Димитрий Семёнов" />
                        </div>
                        
                        <div class ={"inf-4"}>
                        16 мая 2023 года на заседании Священного Синода Русской Православной Церкви был рассмотрен вопрос 
                        об утверждении поимённых списков местных Соборов святых. В список святых Новосибирской митрополии было внесено 
                        имя священномученика Димитрия Семёнова, который почитается в епархиях Ставропольской митрополии. Однако более 
                        четырёх лет его священнического служения прошли 
                        на земле Сибирской, в Каинском уезде Томской губернии (ныне Барабинский район Новосибирской области).
                        <div> Священномученик Димитрий Семёнов родился 30 октября 1873 года в селе Бешпагир Ставропольского уезда 
                          Ставропольской губернии в семье священника Крестовоздвиженской церкви Евтихия Емельяновича Семёнова. 
                          Окончил три класса Ставропольской духовной семинарии, получив свидетельство на звание учителя народных училищ, 
                          после чего два года состоял учителем в Ставропольской дирекции народных училищ. А с 5 августа 1896 года Димитрий 
                          был принят на службу в духовное ведомство и определён на диаконо-учительское место к церкви 
                          Рождества Пресвятой Богородицы села Винодельного Новогригорьевского уезда Ставропольской губернии. 
                          После рукоположения в сан диакона без малого 12 лет он служил в 
                          Спасо-Преображенской церкви села Новоселицкого Александровского уезда Ставропольской губернии.</div>
                        
                        <div> Во время столыпинской аграрной реформы, когда началось активное переселение в Сибирь, он в числе первых в 
                          Ставропольской епархии подал ходатайство о переводе в Томскую епархию. В мае 1909 года архиепископом Ставропольским 
                          и Екатеринодарским Агафодором (Преображенским) он был рукоположен в сан священника и 
                          по приезде в Томскую епархию определён на служение в Михаило-Архангельскую церковь села Таскаевского Каинского уезда.  </div>

                          <div>Более четырёх лет отец Димитрий усердно трудился на поприще устроения приходской жизни и защиты православия от 
                            исказителей святой веры. В приход наряду с селом Таскаевским входили деревни Новомихайловская, Кармышакская и Бакмасиха, 
                            отстоявшие от села на 7–15 километров, общим числом более 400 дворов и около 3500 человек прихожан. Стараниями отца Димитрия 
                            в 1911 году в Бакмасихе было построено прекрасное здание церковно-приходской школы. В Таскаевском он состоял заведующим 
                            и законоучителем в сельском училище 
                            Министерства внутренних дел. В 1912 году он был награждён набедренником «за заслуги по церковно-школьному делу».  </div>
                          <div>Летом 1913 года, обеспокоенный состоянием здоровья престарелого отца, отец Димитрий подал прошение о 
                            переводе его обратно в Ставропольскую епархию, где был зачислен вторым священником в Крестовоздвиженскую 
                            церковь родного села Бешпагир. Отмечалось, что и на этом месте своего 
                            служения он добросовестно и честно исполнял свой пастырский долг, заботился о христианском просвещении детей. </div>
                          
                          
                             
                              <div> Мученическая кончина его описывается так: «В день вступления красноармейцев в Бешпагир 14/27 июня 1918 года 
                                они пришли в дом батюшки и потребовали их накормить. Насытившись, они пообещали, что „теперь… священник будет цел“,
                                 и ушли. Однако буквально через полчаса за ним прислали конвой и повели в волостное правление, в помещениях которого 
                                 расположился штаб красноармейцев… Красноармейцы долго пытали отца Димитрия и издевались над ним, а потом сказали: 
                                 „Ну, теперь готовься“. Он попросил разрешить ему помолиться перед смертью. Отец Димитрий был казнён за селом в ночь 
                                 с 14/27 на 15/28 июня 1918 года вместе с 
                                несколькими жителями Бешпагира и соседних сёл. Бездыханное тело сбросили под кручу недалеко от места казни» </div>
                              <div>Хоронить доброго пастыря запретили. Когда красноармейцы ушли из села, состоялось его погребение в ограде 
                                Крестовоздвиженской церкви. «Почти всё село вышло проводить прах мученика, и многие оплакивали его безвременную 
                                кончину». 
                                После закрытия церкви в 1936 году и полного разрушения её здания в 1943 году могила отца Димитрия была утрачена. </div>
                              <div>Память священномученика Димитрия Семёнова совершается 14 июня (27 июня н.ст.), а также в день празднования 
                                Собора новомучеников и исповедников Церкви Русской и Собора святых Новосибирской митрополии. </div>
                               
                      
                      </div>
                    </table>
                    </div>   
                    {/* <p></p>
                    <div class="sv-title">Тропарь, глас 4</div>
                        <div className="inf-4">Церкве Русския столпе непоколебимый, благочестия правило, 
                        жития евангельскаго образе, священномучениче Димитрие, Христа ради пострадавый даже до крове,
                         Егоже моли усердно, яко Начальника и Совершителя спасения Русь Святую утвердити в Православии 
                         до скончания века.</div>
                        <p></p>
                        <div class="sv-title">Кондак, глас 3</div>
                        <div className="inf-4">Святителю Христу Богу возлюбленне, правило веры и образ 
                        милосердия людем
                        был еси, проповедию своею и многими труды языки алтайския ко Христу привел еси,
                        сам же в подвизех пребывая, от Бога нетлением на земли увенчался еси, духом же
                        Престолу Божию предстоя, молися ныне Господеви да укрепит нас во православии
                        и благочестии, и спасет души наша.</div> */}

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
