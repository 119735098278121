import React from "react";

import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import hram6 from './img/al_nev/hram6.jpg';
import hram1 from './img/al_nev/slide-23.jpg';
import hram2 from './img/al_nev/vagon-cerkov.jpg';
import tihomirov from './img/al_nev/tihomirov2.jpg';
import nikolay from './img/al_nev/Nikolay.jpg';
import hram5 from './img/al_nev/hram5.jpg';




function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b> Александро-Невский собор в Новосибирске </b>
        </div>  
        
<p></p>
                 
                    <div> <table>
                        <div class="sv-title">
                           
                        </div>
                        
                        
                        <div class ={"inf-5"}>
                        В конце XIX века в Западной Сибири был построен величественный собор во имя святого князя Александра Невского. Этот собор стал первым каменным храмом и одним из первых каменных зданий Новониколаевска, 
                        переименованного в 1925 году в Новосибирск.</div>
                        <p></p>
                        <div class="center-image">
                            <img src={hram5} width={750}
                                alt="Александро-Невский собор"
                                title="Александро-Невский собор" />
                        </div>
                        <div class ={"inf-5_2"}><b>Строительство храма </b></div>
                       <div class ={"inf-5_2"}>История возведения Александро-Невского храма тесно связана с историей Новониколаевска. Город возник как посёлок строителей Транссибирской железной дороги — Великого сибирского пути, связавшего центральную Россию с Сибирью и Дальним Востоком. Строительство железной дороги имело для России огромное значение — оно способствовало активному 
                       развитию богатого Сибирского края.</div>
                       <div class ={"inf-5_2"}>Поэтому император Александр III с самого начала уделял этому строительству 
                       особое внимание и постоянно следил за тем, как оно идёт. В 1891 году по маршруту будущей 
                       Транссибирской магистрали проехал наследник престола цесаревич Николай Александрович, 
                       который обратил внимание на малочисленность 
                       церквей по пути его следования.</div>
                       <div class ={"inf-5_2"}>В 1894 году, после смерти Александра III, ставший императором Николай II 
                       создал Фонд строительства церквей в честь своего отца. По линии сибирской железной дороги на 
                       средства этого фонда было сооружено 27 церквей. </div>
                       
                       <div class ={"inf-5_2"}>С 1896 года по строящейся сибирской магистрали 
                       стал курсировать Вагон-церковь во имя святой равноапостольной великой княгини Ольги. А в 1897 году в посёлке Новониколаевске была 
                       поставлена первая деревянная церковь во имя пророка Божия Даниила.</div><p></p>

                       <div class="center-image"><table className="table-w"><tr>
                            <img src={hram2} width={750}
                                alt="Вагон-церковь во имя святой княгини Ольги"
                                title="Вагон-церковь во имя святой княгини Ольги" /></tr>
                        <tr><div class='podpis'>Вагон-церковь во имя святой равноапостольной великой княгини Ольги  
                        </div></tr></table></div>

                       <div class ={"inf-5_2"}>Однако ещё раньше, в 1895 году, жители посёлка обратились к 
                       епископу Томскому Макарию (Невскому) с просьбой построить большую каменную церковь во имя святого князя Александра Невского. Каменный храм предлагалось возвести в память об императоре 
                       Александре III — основателе Великого сибирского пути.</div>
                       <div class ={"inf-5_2"}>Был создан Комитет по строительству храма, который возглавил известный инженер-путеец, начальник строительства Средне-Сибирского участка железной дороги Н.П.Меженинов. Он организовал сбор пожертвований на храм, выбрал место на взгорье для будущей церкви. Проект храма был подготовлен известным томским 
                       архитектором Константином Константиновичем Лыгиным.</div>
                       <div class ={"inf-5_2"}>Временно, до возведения каменного храма, жителям посёлка Новониколаевского было разрешено соорудить деревянный молитвенный дом. В течение месяца он был построен и 
                       в декабре 1895 года освящён.</div>
                       <div class ={"inf-5_2"}>Поскольку Александро-Невский храм в Новониколаевске предлагалось возвести в память императора Александра III, царская семья придавала этому строительству большое значение. В мае 1896 года под будущий храм была выделена земля. В апреле того же года император Николай II пожертвовал на храм 5000 рублей. Основные средства на его возведение — 47 тысяч рублей — выделил Фонд строительства церквей имени Александра III и известные московские купцы-благотворители братья Баевы. Деньги жертвовали также простые жители города, работники железной дороги. Однако начать работы по сооружению храма 
                       в том году так и не удалось.</div>

                       <div class="church-1r1-image"><table className="table-w"><tr>
                            <img src={tihomirov} width={280}
                                alt="Н.М. Тихомиров"
                                title="Н.М. Тихомиров" /></tr>
                        <tr><div class='podpis'>Н.М. Тихомиров  
                        </div></tr></table></div>

                       <div class ={"inf-5_2"}>В 1897 году строительство храма возглавил инженер-путеец Николай Михайлович Тихомиров.</div>
                       
                        <div class ={"inf-5_2"}>15 мая 1897 года состоялась закладка храма. По свидетельству современников, возведение собора Н.М.Тихомиров считал главным делом своей жизни. Более двух лет, не щадя сил и времени, он отдал строительству храма: тщательно следил за каждым его этапом, вникал во все процессы, проверял каждую мелочь. Как позже воспоминала супруга Н.М.Тихомирова Мария Ананьевна, «Николай Михайлович — натура горячая, творческая — возводить собор взялся с большим вдохновением. Устроил хоры для певчих, алтарь. Когда брал меня с собой на разные этапы строительства, я просто любовалась им — бегает по всему зданию, кричит: „А здесь как слышно, а здесь?!“… Говорил: „Запомни, здесь ни одной трещинки не будет“». К концу 1898 года кладка кирпичных стен церкви была завершена. 
                        Фундамент здания стоял на прочном основании — на скальном грунте. </div>
                        <div class ={"inf-5_2"}>Крестово-купольный храм был построен в византийском стиле. Его высота до верха купола — 24 метра. Фасад храма украшен узорными карнизами. Cложную кладку фасада осуществляли итальянские рабочие-каменщики. Низкий и широкий барабан центрального купола прорезан шестнадцатью большими окнами. На барабане покоится объёмный купол. С запада к храму примыкает колокольня, под которой находится главный вход в собор. Роспись церкви была выполнена известной томской иконописной мастерской И.А.Панкрышева. Пол собора был вымощен привезённой 
                        из Голландии керамической плиткой.</div>
                        <div class ={"inf-5_2"}>В церкви — три престола: главный — во имя святого благоверного князя Александра Невского и два боковых — во имя Святителя Николая Чудотворца и во имя святого великомученика 
                        Георгия Победоносца.</div>

                        
                        <div class="church-1l-image">
                            <img src={hram6} width={450}
                                alt="Александро-Невский собор"
                                title="Александро-Невский собор" />
                        </div>
                        <div class ={"inf-5_2"}>В 1899 году император Николай II пожаловал на изготовление иконостаса храма 6500 рублей. Тогда же он прислал в храм священническое и диаконское облачение, сшитое из ценной золотой парчи, которая прежде служила покровом на гроб великого князя Георгия Александровича, брата Николая II. В дар храму император передал две иконы афонского письма: Иверскую икону Божией Матери и икону святого великомученика Пантелеимона Целителя. Украшением храма стал также образ Георгия Победоносца, пожалованный императрицей Марией Фёдоровной 
                        в память об умершем великом князе Георгии Александровиче.</div>

                        <div class ={"inf-5_2"}>29 декабря 1899 года епископом Томским Макарием (Невским) храм был освящён. В «Путеводителе по Великой сибирской дороге» за 1901 год сообщалось: «В центре посёлка, раскинувшегося почти на 10 вёрст вдоль берегов реки Оби и её притока реки Каменки, сооружён прекрасный каменный храм, долженствующий служить памятником царю-миротворцу на Великом сибирском пути. Храм Александра Невского построил инженер Тихомиров Н.М.». Новониколаевский храм стал самым крупным и 
                        красивым из всех возведённых на средства Фонда имени Александра III.</div>
                        
                        <div class="church-1r1-image"><table className="table-w"><tr>
                            <img src={nikolay} width={280}
                                alt="священник Николай Завадовский"
                                title="священник Николай Завадовский" /></tr>
                        <tr><div class='podpis'>Священник Николай Завадовский  
                        </div></tr></table></div>
                        
                        <div class ={"inf-5_2"}>Первым настоятелем Александро-Невского храма был назначен священник Николай Завадовский. На долгие годы Александро-Невский храм стал центром духовной жизни Новониколаевска. Его священнослужители активно занимались духовным просвещением жителей Новониколаевска. С 1900 года в храме по воскресным дням в вечернее, а иногда и в утреннее время проводились духовные чтения. На территории прихода храма разместились учебные заведения: реальное училище имени Дома Романовых, женская гимназия, учительская семинария, городское четырёхклассное училище и 10 школ (одна из них церковно-приходская). С 1898 года при храме было открыто Приходское попечительство. Стараниями его членов, сбором доброхотных пожертвований горожан в Новониколаевске были устроены Покровская (1901) и Воскресенская (1907) церкви. В 1918 году решением собрания прихожан при храме было образовано Сестричное общество, цель которого состояла в оказании помощи 
                        престарелым и больным людям.</div>
                    
                        <div class ={"inf-5_2"}>В 1915 году храм во имя святого благоверного князя Александра Невского получил достоинство собора.</div>
<p></p>
                        <div class ={"inf-5_2"}><b>Закрытие храма</b></div>

                        <div class ={"inf-5_2"}>В 1938 году, во время гонений на Русскую Православную Церковь, по распоряжению городских властей Александро-Невский собор был закрыт. В 1930-е годы несколько раз делались попытки взорвать здание храма, но заряды разрушали только перегородки, мощные стены оставались невредимы. Так сбылись слова, сказанные Николаем Михайловичем Тихомировым ещё во время строительства храма: «Здесь ни одной трещинки не будет». В дальнейшем от идеи разрушения храма пришлось отказаться, так как взрывами мог быть причинён значительный ущерб важным административным зданиям, располагавшимся поблизости. Здание собора городские власти решили сохранить 
                        и использовать по своему усмотрению.</div>

                        <div class ={"inf-5_2"}>В 1940-е годы в здании собора размещался проектный институт.
                        В период с 1957 по 1984 год в бывшем храме работала Западно-Сибирская студия кинохроники, которая 
                        выпускала известный киножурнал «Сибирь на экране». Именно в эти годы была полностью уничтожена прекрасная 
                        стенная роспись собора, в помещении бывшего храма появились три этажа, а в куполе собора была устроена 
                        комната.
                        </div>

                        <div class ={"inf-5_2"}>В 1971 году при проведении земляных работ около Александро-Невского собора рабочие обнаружили склеп с останками строителя храма Н.М.Тихомирова. По решению городских властей останки одного из основателей Новониколаевска-Новосибирска были перезахоронены 
                        на Заельцовском кладбище.</div>
                        
                        <div class ={"inf-5_2"}>В 1985 году здание собора было передано Новосибирской филармонии. Был разработан план перестройки храмового помещения под концертный зал. Но наступил 1988 год, который не дал осуществиться планам окончательного уничтожения 
                        храма как места молитвы.</div>
                       <p></p>
                        <div class ={"inf-5_2"}><b>Восстановление храма</b></div>
                        <div class ={"inf-5_2"}>В 1988 году, после празднования 1000-летия Крещения Руси, в городе Новосибирске раз вернулось движение за возвращение Александро-Невского собора Новосибирской епархии. В городе появились инициативные группы по сбору подписей в пользу передачи храма Церкви. 
                        Люди даже выходили на митинг.</div>

                        <div class ={"inf-5_2"}>25 августа 1989 года Новосибирский городской совет принял решение о передаче собора в пользование
                         Новосибирской епархии. </div>

                        <div class ={"inf-5_2"}>Сразу после возвращения Александро-Невского собора Церкви в нём начались восстановительные работы. Была вновь отстроена колокольня, перекрыты купола храма, обновлены и восстановлены пришедшие в негодность фрагменты внешних стен, восстановлена внутренняя планировка и заново 
                        отштукатурены стены храма.</div>
                        <div class ={"inf-5_2"}>15 мая 1991 года впервые посетивший Новосибирск Святейший Патриарх Московский и всея Руси Алексий II торжественно освятил собор. Знаменательно, что событие это совершилось в тот день, когда ровно за 94 года до этого был освящён закладной камень в основание будущего храма. С этого времени в соборе ежедневно начали 
                        совершаться богослужения.</div>
                        <div class ={"inf-5_2"}>В 1994 году из Александро-Невской лавры Санкт-Петербурга в Александро-Невский собор 
                        была торжественно передана великая святыня — икона святого благоверного князя Александра Невского 
                        с частицей мощей 
                        и с частицей схимнического одеяния святого.</div>
                        <div class ={"inf-5_2"}>В середине 2000-х годов художником П.А.Миловановым храм был заново расписан. </div>
                        <div class ={"inf-5_2"}>16 июля 2017 года у южных врат Александро-Невского собора был торжественно 
                        открыт памятник святым царственным страстотерпцам — царю Николаю и цесаревичу Алексию.</div>
<p></p>
                        <div class="center-image">
                            <img src={hram1} width={750}
                                alt="Александро-Невский собор"
                                title="Александро-Невский собор" />
                        </div>
                        
                        
                        </table>
                        
                          
                    
                    </div>        
      


             
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
