import React from 'react'
import { BiExit } from "react-icons/bi";
import "./InformContact.css";
import { Link } from "react-router-dom";
function InformContact() {
  return (
    <div className='MainClassInformContact'>
        
        "Путеводитель по Новосибирской митрополии" подготовлен к 100-летию Новосибирской епархии
        по благословению Высокопросвященнейшего Никодима, митрополита Новосибирского и Бердского. 
        <div>Проект будет пополнятся информацией о храмах, монастырях, 
          святынях Новосибирской митрополии, а также статьями по сибирскому краеведению. </div>
          <div>В разделе контакты указан адрес для обратной связи, по которому Вы можете написать свои замечания и предложения.</div>

          <Link to="/">
        <div class="BiExit1">
          <BiExit />
        </div>
      </Link>
        </div>
  )
}

export default InformContact