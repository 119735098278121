import React from 'react'
import flag from "./region/Chanovskiy/herb1.png"
import emblem from "./region/Chanovskiy/herb2-.png"
import church_1_image from "./region/Chanovskiy/h4.jpg"
import church_2_image from "./region/Chanovskiy/h2.png"
import attr from "./region/Chanovskiy/t1.png"
function Chanovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                    <div class="flag">
                        <img src={emblem} width={180} alt="Герб" title="Герб" />
                    </div>
                <div class="title-region">
                    <h1 className='head'>Чановский район</h1>
                </div>
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <p>Административный центр — посёлок городского типа Чаны.
                            Население 22 475 чел. (2021).
                            Район образован в 1925 году в составе Барабинского округа Сибирского края.
                             В 1930 году Сибирский край был разделён на западную и восточную части. 
                               В 1937 году район был включён в состав новообразованной 
                               Новосибирской области.
                            </p>
                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div>
                    <h2 className='churches-title'> Церкви Чановского района</h2>
                    </div>
                    
                    <table>
                    <div class="church-1">
                          <div class="church-1-title">
                            Церковь Покрова Пресвятой Богородицы в р.п. Чаны
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={400} alt="Церковь Покрова Пресвятой Богородицы в р.п. Чаны" title="Храм1" />
                        </div>
                        
                        <div class ={"inf-4"}>В 1989 году, после празднования 1000-летия Крещения Руси, в р. п. Чаны образовалась православная община. 
                        23 августа 1990 года община была зарегистрирована. Верующие жители поселка собирались в 
                        жилом доме, приглашали священников из соседнего райцентра для совершения богослужений.</div> 
                        <div class ={"inf-4"}>Совместными усилиями православной общины и администрации 
                        Чановского района в 1991 году началось 
                        строительство храма. Епископ Томский Софроний (Будько), викарий Новосибирской епархии, в сослужении 
                        протоиерея Сергия Государкина и протодиакона Александра Реморова освятил закладной камень 
                        новосооружаемого храма в честь Покрова Пресвятой Богородицы.
                        Храм был построен в 1997 году, в нем стали совершаться службы.</div> 
                        <p><div class ={"inf-4"}><b>Адрес: </b>р.п. Чаны, ул. Комсомольская, 28. </div></p>
                        
                    </div>
                    </table>


                    <table>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Церковь целителя Пантелеимона на ст. Озеро Карачи</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} alt="Церковь целителя Пантелеимона на ст. Озеро Карачи" title="Храм2" />
                        </div>
                        <div class ={"inf-4"}>Приход в поселке Озеро Карачи был открыт в 1996 году трудами протоиерея Павла Патрина. 
                        С 1997 года являлся приписным к храму Покрова Пресвятой Богородицы р.п. Чаны. 
                        В 2001 году в храм был назначен настоятель и приход стал действовать самостоятельно.</div>
                        <p><div class ={"inf-4"}><b>Адрес: </b>п.Озеро Карачи, Чановского район, ул. Школьная, 5. </div></p>
                    </div>
                    </table>


                    <div class="attractions">
                        
                        <div class="attractions-title">
                            <h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Карачи и карачинский источник минеральной воды</p>
                            </div>
                           <div class="attraction-1-image">
                            <img src={attr} alt="Озеро Карачи" title="Озеро Карачи" />
                        </div>
                        <div className='inf-4'><p> </p></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Chanovskiy