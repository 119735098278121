import React from "react";
import {
  YMaps,
  Map,
  ObjectManager,
  Placemark,
  GeolocationControl,
} from "@pbe/react-yandex-maps";
import "./Styl.css";

const MapYa = ({ points }) => {
  const [aItem, setAitem] = React.useState(points);
  let centerX = document.documentElement.clientWidth;
  let centerY = document.documentElement.clientHeight;
  const onItemPush = () => {
    setAitem.push({
      id: "2",
      coordinates: [61.699623, 30.690952],
      title: "Пристань Метеоров",
    });
  };
  const onItemDelete = () => {
    setAitem([]);
  };
  const mapState = {
    center: [55.03, 82.93211176],
    zoom: 12.0,
    behaviors: ["default", "scrollZoom"],
  };

  const collection = {
    type: "FeatureCollection",
    features: aItem.map((point, id) => {
      return {
        id: id,

        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: point.coordinates,
        },
        properties: {
          balloonContent:
            ` 
          <img class="image" src=${point.image} alt="Point">
          <h2>${point.name}</h2>
          <div className='lst'>${point.text}</div>
          <div>${point.adress}</div>
        `,

          clusterCaption: `Метка №${id + 1}`,
        },
      };
    }),
  };

  return (
    <>
      <YMaps>
        <Map width={centerX} height={centerY} state={mapState}>
          <Placemark
            geometry={[61.702423, 30.688193]}
            properties={{ balloonContentLayout: collection }}
          />
          <GeolocationControl options={{ float: "left" }} />
          <ObjectManager
            objects={{
              openBalloonOnClick: true,
            }}
            clusters={{}}
            options={{
              clusterize: true,
              gridSize: 32,
            }}
            defaultFeatures={collection}
            modules={[
              "objectManager.addon.objectsBalloon",
              "objectManager.addon.clustersBalloon",
            ]}
          />
        </Map>
      </YMaps>
      {/* <button onClick={() => onItemPush()}>Push</button>
      <button onClick={() => onItemDelete()}>Delete</button> */}
    </>
  );
};

export default MapYa;
