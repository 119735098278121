import React from "react";
import "../nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import h from './zavyalovo.jpeg'

function Iskitim() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);
  

  return (
    <div class="nsk">
      <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
      </div>
      <div
        className="alle3"
        style={{ filter: isActive ? "" : "blur(20px)" }}
      ></div>
      <div className="header" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h1>Искитимская Епархия </h1>
        <a>
          <b>Искити́мская епархия — </b> епархия Русской православной церкви,
          объединяющая приходы и монастыри в восточной части Новосибирской
          области (в границах Болотнинского, Искитимского, Маслянинского,
          Мошковского, Сузунского, Тогучинского и Черепановского районов).
          Входит в состав Новосибирской митрополии[
        </a>
      </div>
      <div className="all_ep" style={{ filter: isActive ? "blur(150px)" : "" }}>
        <Link class="sem" to="/kainsk">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Каинская епархия
          </div>
        </Link>
        <Link class="sem" to="/Karasuk">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            {" "}
            Карасукская епархия
          </div>
        </Link>
        <Link class="sem" to="/Iskitim">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Искитимская епархия
          </div>
        </Link>
        <Link class="sem" to="/ep">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Новосибирская митрополия
          </div>
        </Link>
      </div>
      <div className="history" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2>История</h2>
        <div className="his-inf">
          Учреждена решением Священного Синода Русской православной церкви 28
          декабря 2011 года путём выделения части приходов из Новосибирской
          епархии. Административно включена в состав Новосибирской
          митрополии. Управляющим епархией избран игумен <i>ука (Волчков)</i>Л, клирик
          Новосибирской епархии, хиротонисанный во епископа 10 марта 2012 года.
        </div>
      </div>
      <div className="arh" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="arh-h1" style={{ filter: isActive ? "" : "blur(20px)" }}>
          Святыни
        </h2>
        <div
          className="arh-name"
          style={{ filter: isActive ? "" : "blur(20px)" }}
        >
            <h3>Дата основания:</h3>
         <i>@28 декабря 2011 года.
        (Учреждена решением Священного Синода Русской православной церкви)</i>


        </div>
      </div>
      <div className="obr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="obr-h1">Искитимская Епархия </h2>
        <a href="http://iskitimeparhia.ru//">Искитимская Епархия </a>
      </div>
      <div>
        <h2>Монастыри</h2>
        <h3>Покровский монастырь в селе Завьялово (мужской)</h3>
        <div>Покро́вский монасты́рь в Завья́лове — мужской монастырь Русской православной церкви в Новосибирской епархии. Находится в селе Завьялово Искитимского района Новосибирской области.</div>
        <div className="smr"><img src={h} width={500} alt='Покровский монастырь'/></div>
        <li>Монастырь в честь Всех святых, в земле Сибирской просиявших в Черепанове (мужской)</li>
      </div>
      <Link to="/">
        <div class="BiExit">
          <BiExit />
        </div>
      </Link>
    </div>
  );
}

export default Iskitim;
