import React from 'react'

function Getinfo() {
  return (
    <div>
      SS
    </div>
  )
}

export default Getinfo
