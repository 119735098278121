import React from 'react'
import emblem from './region/Kochkovskiy/e1.png'
import church_1_image from './region/Kochkovskiy/h_1_1.jpg'
import church_2_image from './region/Kochkovskiy/h2.jpg'
import church_3_image from './region/Kochkovskiy/h4.jpg'
import a1 from './region/Kochkovskiy/a1.jpg'
import d1 from './region/Kochkovskiy/d2.jpg'
import d2 from './region/Kochkovskiy/d1.jpg'
import v1 from './region/Kochkovskiy/v1.jpg'

function Kochkovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                
                
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} />
                    </div>
                    <h1 className='head'>Кочковский район</h1>
                </div>

                
                
                <div class="about-region">
                    
                        <div class="inf-4">
                                
                            Кочковский район официально образован 12 сентября 1924 года в составе Каргатского уезда 
                                Новониколаевской губернии на базе Решетовской, Кочковской и Жуланской волостей. 
                                В 1925 году были упразднены губернии и образован Сибирский край, и район вошёл в состав 
                                Каменского округа Сибирского края. В 1930 году Сибирский край был разделён на западную и 
                                восточную части, и район оказался в составе Западно-Сибирского края. В 1937 район был включен 
                                в состав нового Алтайского края, а 8 апреля 1939 передан в Новосибирскую область.
                                Административный центр — село Кочки. Население 14000 человек.
<p></p>
                            <div class='center-image'>   
                                <img src={v1} width={700} /> </div>
                    </div>


                </div>

                
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Кочковского района</h2>
                    </div>
                   
                    
                   
                   
                   


                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Храм в честь Рождества Христова
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                       
                        В 2004 г. по инициативе бывшего главы Кочковского района И.И. Михайлова за короткое время был построен новый храм. 
                        Освящение состоялось 2 января 2005 г. в день святого праведного Иоанна Кронштадтского. С этого дня начались регулярные богослужения, крещения, 
                        венчания в новом храме.


                        <p>На территории Прихода в 2014 г. была построена библиотека, фонд которой составляет духовная литература.
                         При храме действует воскресная школа для детей.</p>
                         <p><b>Адрес:</b> Кочковский район, село Кочки, ул. Революционная, 20.</p>
                        </div> 
                    </div>
                    </table>



<p></p>
                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                        Храм в честь Святой Троицы, с. Решеты
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        В селе Решеты существовал деревянный храм в честь Святой Живоначальной Троицы, построенный в 1910 году. 
                        В советское время был закрыт и вскоре сгорел.
                        <div>Строительство нового храма совершилось уже в новое время. 
                            В 2020 году началось строительство нового храма. </div>
                        <div>16 июня 2024 его преосвященство Филипп, епископ Карасукский и Ордынский, 
                        совершил чин великого освящения храма в честь Святой Живоначальной Троицы 
                        с. Решеты и первую Божественную литургию в новоосвященном храме. </div>
                        


                        
                         <p><b>Адрес:</b> Кочковский район, село Решеты.</p>
                        </div> 
                    </div>
                    </table>


                    <p></p>
                    <table class='table-w'>
                    <div class="church-1">
                        <div class="church-1-title">
                        Храм в честь Казанской иконы Божией Матери, с. Комарье
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                       
                         <p><b>Адрес:</b> село Комарье, ул. Почтовая, 1.</p>
                        </div> 
                    </div>
                    </table>




                    
                </div>

                
                <div class="attractions">
                        <div class="attractions-title">
                            <h2>Достопримечательности </h2>
                        </div>
                       
                       
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Кочковский краеведческий музей</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={a1} width={700}/>
                            </div>
                        </div>

<p></p>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Биологический заказник государственного значения «Маяк»</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={d1} width={700}/>
                                <img src={d2} width={700}/>
                            </div>
                        </div>
                    </div>
            </section>
        </div>);
}

export default Kochkovskiy




