import React from 'react'
import { BiExit } from "react-icons/bi";
import "./InformContact.css";
import { Link } from "react-router-dom";
function InformContact() {
  return (
    <div className='MainClassInformContact'>
        <div>  </div>
         <div>Наш e-mail для обратной связи: <b> pravkraeved@yandex.ru</b></div>
       <p> Если Вы заметили неточность или у Вас есть дополнительная информация для сайта,        
        пожалуйста, напишите нам. </p>
        
        <Link to="/">
        <div class="BiExit1">
          <BiExit />
        </div>
      </Link>
        </div>
        
        
  )
  
}

export default InformContact