import React from 'react'
import emblem from "./region/Cherepanovskiy/herb3.png"
import admin from "./region/Cherepanovskiy/admin.jpg"
import krest from "./region/Cherepanovskiy/h5.jpg"
import church_1_image from "./region/Cherepanovskiy/h1.jpg"
import church_2_image from "./region/Cherepanovskiy/h2.jpg"
import church_3_image from "./region/Cherepanovskiy/h3.jpg"
import church_4_image from "./region/Cherepanovskiy/h4.jpg"

function Cherepanovskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Черепановский район</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={170} alt="Флаг Баганск" title="Герб" />
                    </div>
                    <h1 className='head'>Черепановский район</h1>
                </div>
                
                <div class="about-region">
                    
                    
                    <div class="inf-4">
                        <p>
                            Район образован в 1925 году в составе Новосибирского округа Сибирского края. 
                            В 1930 году Сибирский край был разделён на западную и восточную части, и район оказался в составе 
                            Западно-Сибирского края.
                            1 октября 1933 года был упразднён Бердский район, к Черепановскому району перешли сельсоветы 
                            Бердского района.
                            В 1937 район был включен в состав новообразованной Новосибирской области.
                            Черепановский район относится к Искитимской епархии.
                            Административный центр — город Черепаново.
                        </p>
                    </div>
                    <div class="image-podpis">
                        <img src={admin} width={600} alt="Черепаново" />     
                        <img src={krest} width={327} alt="Поклонный Крест" />
                        <div class ={"inf-podpis"}>г. Черепаново с высоты птичьего полета и поклонный Крест на въезде в город </div>                     
                    </div>
                </div>
                
                <div class="churches">
                    <div class="churches-titile">
                    <h2 className='churches-title'>Храмы и монастыри</h2>
                    </div>
                   
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Церковь Алексия, митрополита Московского, г. Черепаново</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={550} alt="Храм в честь Успения Пресвятой Богородицы (с. Баган)."
                                title="Храм в честь Успения Пресвятой Богородицы" />
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Каменный Алексиевский храм был построен в 1914 г. и предназначался для строителей 
                    Алтайской железной дороги, проживавших в образованном ими поселке 
                    "Свободный" на месте будущей станции Черепаново, давшей в 1921 г. свое название и поселку.
                    Традиция возведения храмов при станциях железной дороги сложилась в России во второй 
                    половине XIX в. с появлением и быстрым развитием железнодорожного транспорта, 
                    а восходит она к более древней традиции строительства храмов и часовен на перекрестках 
                    дорог и пересечении улиц. Выбор правильного пути был связан у русского человека 
                    с молитвенным обращением к Богу.</div>
                    <div class ={"inf-4"}>Здание Алексиевского храма было возведено из кирпича на каменном фундаменте и 
                    представляло собой трапезный тип храма с расширенным относительно основного объема 
                    притвором, без колокольни и с прямоугольной алтарной абсидой. Отсутствие колокольни 
                    объясняется тем, что в поселке, по свидетельствам местных жителей, сначала построили 
                    деревянный храм с колокольней, посвященный Николаю Угоднику, а потом началось 
                    строительство каменного. 
                    Близость колокольни деревянного храма позволила не возводить каменную.</div>
                    <div class ={"inf-4"}> В 1930-е годы храм был закрыт. Здание бывшего Алексиевского храма 
                    было перепрофилировано под клуб и под Дом пионеров. После возвращения здания в 2006 году 
                    Русской Православной Церкви была проведена реконструкция здания, сделан ремонт 
                    и с 2008 года в храме совершаются службы.
                    <p><b>Адрес: </b>Новосибирская область, город Черепаново, улица Пролетарская, 81.</p>
                     
                    </div> 
                   
                   
                   
                   
                   
                    
                    <div class="church-1"> <table>
                        <div class="church-2-title">
                            <p>Церковь Симеона Верхотурского, г. Черепаново.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={500} alt="Церковь Симеона Верхотурского (г. Черепаново)" title="Храм2" />
                        </div>
                        <div class ={"inf-4"}> Храм находится в восточной части г. Черепаново, 
                          это второй храм в городе.
                          Строился храм по проекту архитектора Бориса Александровича Захарова. 
                          Храм двухуровневый. Нижний храм освящен в честь святого пророка 
                          Иоанна Крестителя. Верхний — в честь святого праведного 
                          Симеона Верхотурского — великого чудотворца Сибирской Земли.
                          <div>В 1997 году был освящён закладной камень в 
                          основание храма и началось строительство. Первую Божественную 
                          литургию служили в верхнем храме на Пасху в 2000 году, 
                          хотя храм еще не до конца был построен. Купола и кресты поднимали 
                          на Крестопоклонной неделе в 2001 году, а в 2002 году были
                        установлены еще 7 колоколов на звонницу.</div>
                        <div>В храме есть частица мощей святого праведного 
                        Симеона Верхотурского.</div>  
                    <p><b>Адрес: </b>Новосибирская область, город Черепаново, ул. Юбилейная, 25а.</p>
                    
                        </div>
                        </table>
                    </div>

                    <div class="church-3">
                        <div class="church-2-title">
                            <p>Черепановский мужской монастырь в честь всех Святых <br/>в земле 
                                Сибирской просиявших</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} alt="Церковь Всех Сибирских Святых  (г. Черепаново, при монастыре)" title="Храм3" />
                        </div>
                        <div class ={"inf-4"}>Храм в честь Всех святых в земле Сибирской просиявших 
                        обустраивался силами местной православной общины в 1980-е годы. 
                        Храмовое здание было перестроено из жилого дома в духе традиций русского 
                        церковного зодчества. Новоустроенный храм был освящен митрополитом 
                        Гедеоном (Докукиным) в 1986 году.</div>
                        <div class ={"inf-4"}>В октябре 2002 года архиепископ Тихон (Емельянов) назначил иеромонаха Кирилла (Скакальского)
                        и.о. наместника новообразуемого мужского монастыря. С этого времени при храме начала
                        формироваться монашеская община. В 2004 году при монастыре построен 
                        Духовно-просветительский центр, освященный в том же году архиепископом Тихоном.
                        17 июля 2006 года на заседании Священного Синода под председательством 
                        Патриарха Алексия II был учрежден мужской монастырь в честь 
                        Всех Святых в земле Сибирской просиявших в г. Черепаново.  
                    <p><b>Адрес: </b>г. Черепаново, ул. Социалистическая, 51А. </p>
                    <p> </p><p> </p><p> </p> </div>
                        
                    </div>
                    <div class="church-4">
                        <div class="center-image">
                            <img src={church_4_image} width={1100} alt="Черепановский мужской монастырь в честь всех Святых в земле Сибирской просиявших" title="Храм4" />
                        </div>
                    </div>
                </div>
                <p> </p>
                {/*
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Достопримечательности</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-2-text">
                                <p>Поклонный крест на въезде в Черепаново.</p>
                            </div>
                            <div class="attraction-3-text">
                                <p>Аллея Памяти героев-черепановцев,</p>
                            </div>
                            <div class="attraction-4-text">
                                <p>Монумент Славы.</p>
                            </div>
                        </div>
                    </div>
                </div>
                */}
            </section>
        </div>
    );
}

export default Cherepanovskiy