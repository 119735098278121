import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";

import Sv_Michail from './img_sobor/mihail-pyataev.jpg'
import Sv_Michail1 from './img_sobor/mix-piataev-photo.jpg'
import Sv_Michail2 from './img_sobor/mihail-s-semey-i-prihozhan.jpg'
import sv_nov from './img_sobor/sv_nov.jpg'


function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Священномученик Михаил Пятаев</b>
        </div><p></p> 
      


                  
                   <div> 
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={Sv_Michail} width={280}
                                alt="Священномученик Михаил Пятаев"
                                title="Священномученик Михаил Пятаев" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        С ускорением темпов коллективизации в начале 1930-х годов в Сибири начались аресты и высылка 
                        крестьянских семей, а вместе с ними и аресты духовенства. В январе 1930 года по обвинению 
                        в контрреволюционной деятельности, «агитации против сплошной коллективизации села» был арестован 
                        и 28 февраля того же года расстрелян в Каинске (ныне город Куйбышев Новосибирской области) 
                        священник Михаил Пятаев. Последним местом его служения был Богоявленский храм 
                        села Малокрасноярка Омской губернии — ныне оно входит в состав Кыштовского района Новосибирской области.   </div>
                        
                        <div class ={"inf-5"}>Священномученик Михаил родился в 1891 году в селе Мачкасы Пензенской губернии 
                        в семье крестьянина. Образование он получил в Москве, после этого преподавал русский язык и 
                        литературу в Саратове. Вместе с волной переселенцев молодой учитель с семьёй оказался в Сибири. 
                        Первое время он продолжил преподавать в Омске, но затем принял решение посвятить свою жизнь 
                        служению Господу. Начавшаяся Гражданская война не изменила его решение, и в 1918 году он был посвящён 
                        в диакона. Поскольку в то время большой семье трудно было прокормиться в городе, он попросил 
                        перевести его в село. С 1922 года началось его служение в глухом, отдалённом от больших городов 
                        и железных дорог селе Малокрасноярка Омского уезда. 
                        В 1923 году он был рукоположен во священника к Богоявленскому храму этого села. </div>
                        
                        <div class ={"inf-5"}>Отец Михаил с большой любовью относился к прихожанам, всегда готов был прийти 
                        на помощь. Часто совершал крещения, венчания, отпевания бесплатно. Многие нищие находили у него 
                        поддержку и пропитание, для этого он специально ловил рыбу и раздавал большую её часть беднякам. 
                        Духовный авторитет священника был так высок, что к нему в Малокрасноярку приходили люди из тридцати 
                        окрестных сёл и деревень. В 1928 году в Богоявленский храм был назначен второй священник — отец Иоанн Куминов. 
                        <div class="church-1r-image"><table><tr>
                            <img src={Sv_Michail2} width={420}
                                alt="Священномученик Михаил Пятаев"
                                title="Священномученик Михаил Пятаев" /></tr>
                        <tr><div class='podpis'>Священномученик Михаил Пятаев <div> 
                          с семьей и прихожанами</div></div></tr></table></div>
                        

                        <div>В начале января 1930 года в Малокрасноярский районный административный отдел обратился 
                          староста Богоявленского храма за разрешением провести в праздник Крещения Господня традиционный 
                          Крестный ход на Иордань. Делопроизводитель показал ему образец того, как должно быть составлено заявление.
                           «Я в этом ничего не понимаю, пусть приходит священник», — сказал староста. Когда пришёл отец Михаил, 
                           делопроизводитель дал ему бюллетень НКВД, в котором была опубликована инструкция от 1 октября 1929 года
                            «О правах и обязанностях религиозных объединений». Помимо правил подачи заявлений о различных шествиях,
                             в ней содержался новый порядок обязательной 
                          регистрации религиозных общин с приложением формы заявления о регистрации и списка членов.  </div>
                          
                          <div>3 января к Дорофею Гришманову, жителю села Большеречье, что в 17 километрах от Малокрасноярки, 
                            пришёл один из прихожан Богоявленского храма и принёс список верующих для перерегистрации, составленный 
                            отцом Михаилом. Он предложил Дорофею взять кого-нибудь пограмотней из твёрдо верующих и обойти 
                            с этим списком село, однако тот отправился прямиком в сельсовет и заявил, что к нему ночью приезжал 
                            священник Михаил Пятаев и без ведома сельсовета послал его для производства какой-то регистрации. 
                            5 января членами сельсовета был составлен акт: «Усматривая поповские ночные визиты подозрительными 
                            в связи с коллективизацией, а также без ведома сельсовета, постановили составить настоящий акт 
                            для передачи в административный отдел на предмет расследования 
                            и привлечения к ответственности» (УФСБ России по Новосибирской области. Д. 14548. Л. 4). </div>
                            
                          <div>Отец Михаил понимал, что власти собираются его арестовать. У него был друг детства, 
                            который работал в московской милиции. В одном из писем он писал ранее священнику: 
                            «Михаил, мне очень жаль… ты был учителем, а теперь стал священником. Не подумай дурного, 
                            я тебе это говорю не потому, что плохо отношусь к духовенству, но сейчас неподходящее для 
                            священнослужителей время. Я тебе предлагаю приехать в Москву и снова поступить на работу учителем. 
                            Я помогу тебе устроиться на работу и найти жильё». За два дня до ареста жена отца Михаила напомнила 
                            ему об этом письме и предложила срочно уехать. Однако он сказал: «Я никуда не уеду, я дал присягу. 
                            Как я могу изменить Богу и народу? Никогда! Я знаю, что иду на верную смерть, но такова воля Божия! 
                            А ты детей всех вырастишь 
                            и с Божьей помощью воспитаешь, никого не растеряешь». </div>
                          
                                                  
                          <div>Вскоре отца Михаила, а вместе с ним и отца Иоанна Куминова арестовали и отправили в городскую тюрьму 
                            Каинска. На допросах следователь ОГПУ заставлял священников подписаться под показаниями, что они и 
                            члены церковного совета якобы занимались организацией крестьян на борьбу с колхозами и советской властью. 
                            Арестованных безжалостно избивали, а однажды по приказу следователя на отца Михаила вылили нечистоты. 
                            Но, несмотря на все мучения, пастыри отказались лжесвидетельствовать. Отец Михаил дал такие показания: 
                            «За время моей с Куминовым службы мы нигде и ни в чём противозаконном не участвовали. Исполняли только 
                            свой религиозный долг... Во время богослужений я проповедовал исключительно по Божественному Писанию». </div>
                                                     
                            <div class="church-1l-image">
                            <img src={Sv_Michail1} width={250}
                                alt="Священномученик Михаил Пятаев"
                                title="Священномученик Михаил Пятаев" />
                        </div>
                              <div>Несмотря на данные показания арестованных, в обвинительном заключении было сказано, что 
                                «Пятаев и Куминов использовали религиозные предрассудки несознательного крестьянства и 
                                при поддержке зажиточной части деревни проводили контрреволюционную деятельность на срыв мероприятий,
                                 запугивая крестьян провокационными слухами о близком падении советской власти, якобы на основании 
                                 Писания Божия. В конце декабря 1929 года на общем собрании граждан единогласно было принято — 
                                 записаться в коллектив. Член группы, священник Пятаев, на второй день обходом индивидуально каждого 
                                 хозяйства проводит якобы запись в общество верующих, между тем как следствием установлено — 
                                 последний проводил агитацию против сплошной коллективизации села». Прочитав обвинение,
                                  отец Михаил написал: «Виновным себя не признаю. Был в объезде сёл в течение двух суток. 
                                  Списки на запись верующих составлял по словесному 
                                распоряжению милиции для перерегистрации церковной общины». </div>
                              <div>21 февраля 1930 года тройка ОГПУ приговорила священника Михаила Пятаева к расстрелу, 
                                а его семью — к ссылке на север. Такой же приговор был вынесен и отцу Иоанну Куминову.</div>

                                <div>28 февраля отцу Михаилу разрешили свидание со старшей дочерью Анной. «Дай мне свою косу», — 
                                  попросил он девушку. Она протянула ему через решётку косу, и она стала вся мокрой от слёз. 
                                  «Папа, что ты плачешь?» — «Мне очень тяжело, потому что вас так много и вы остаётесь одни». 
                                  В ту же ночь Анна увидела во сне, как Божия Матерь причащает из золотой Чаши отца Михаила.
                                   Проснувшись с мыслью, что отец будет освобождён, радостная она поспешила в тюрьму 
                                  с передачей. «Пятаев? — переспросил надзиратель. — Да он сегодня ночью расстрелян». </div>
                                <div>Священномученик Михаил Пятаев был расстрелян 28 февраля 1930 года и погребён 
                                  в безвестной общей могиле в городе Каинске. </div>
                                <div>В августе 2000 года Юбилейный Архиерейский Собор Русской Православной Церкви причислил 
                                  священника Михаила Пятаева к лику святых новомучеников и исповедников Российских для общецерковного 
                                  почитания.  </div>
                                  Память священномученика Михаила Пятаева Русская Православная Церковь чтит 15 февраля 
                                  (28 февраля н.ст.), в Соборе святых Новоси¬бирской митрополии 16 февраля (29 февраля н.ст.) 
                                  и в Соборе новомучеников и исповедников Церкви Русской 
                                  (первое воскресенье, начиная с 25 января (7 февраля н.ст.)).  </div>
 
                      </div>
                      
                      
                    
                      
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
