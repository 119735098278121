import React from "react";
import "../nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
// import e from "./531px-Куйбышев,_церковь.jpeg";
import k from "./DSC_0578.jpeg";
import { BiExit } from "react-icons/bi";
function Kainsk() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
      </div>
      <div className="alle3" style={{ filter: isActive ? "" : "blur(20px)" }}>
      </div>
      <div className="header" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h1>Каинская Епархия</h1>
        <a>
        <b>Каинская епархия</b> — епархия Русской православной церкви в границах
          Барабинского, Венгеровского, Каргатского, Коченёвского, Куйбышевского,
          Кыштовского, Северного, Татарского, Чановского, Чулымского, Убинского
          и Усть-Таркского районов Новосибирской области. Входит в состав
          Новосибирской митрополии. Территория современной Каинской епархии до
          1924 года входила в состав Томской епархии, с 1924 года перешла в
          состав новоучреждённой Новониколаевской (с 1925 года Новосибирской)
          епархии.
        </a>
      </div>
      <div className="all_ep" style={{ filter: isActive ? "blur(150px)" : "" }}>
        <Link class="sem" to="/kainsk">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Каинская епархия
          </div>
        </Link>
        <Link class="sem" to="/Karasuk">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            {" "}
            Карасукская епархия
          </div>
        </Link>
        <Link class="sem" to="/Iskitim">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Искитимская епархия
          </div>
        </Link>
        <Link class="sem" to="/ep">
          <div class="h" style={{ fontSize: isActive ? "0" : "" }}>
            Новосибирская митрополия
          </div>
        </Link>
      </div>
      <div className="history" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2>История</h2>
        <div className="his-inf">
          Территория современной <b>Каинской епархии</b> до 1924 года входила в состав
          <i> Томской епархии</i>, с 1924 года перешла в состав новоучреждённой
          Новониколаевской (с 1925 года — Новосибирской) епархии[4]. Каинская
          епархия учреждена решением Священного Синода Русской православной
          церкви 28 декабря 2011 года путём выделения из Новосибирской епархии.
          Административно включена в состав Новосибирской митрополии. Избранный
          управляющим епархией иеромонах Феодосий (Чащин), клирик Новосибирской
          епархии, 17 марта 2012 года был рукоположен в сан епископа
          Каинского[5]. В 2012 году епархия насчитывала 14 приходов
        </div>
      </div>
      <div className="arh" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="arh-h1" style={{ filter: isActive ? "" : "blur(20px)" }}>
          Святыни
        </h2>
        <div
          className="arh-name"
          style={{ filter: isActive ? "" : "blur(20px)" }}
        >
          До 1917 года, по данным протоиерея Андрея Сулоцкого, наиболее
          почитаемыми святынями на территории Каинского округа были чудотворная
          икона св. Параскевы, находившаяся в часовне близ святого колодца в
          окрестностях села Вознесенское (ныне Вознесенка, Венгеровского района
          Новосибирской области), а также местночтимая икона св. Николая в селе
          Осиновые Колки, почитаемый список Казанской иконы Богоматери в селе
          Дмитриевская Волость, и местночтимая икона св. Екатерины в селе
          Бухтарма. В эти населённые пункты регулярно совершались паломничества
          для поклонения святыням. Спустя десятилетия после полного разорения, в
          1995 году была возрождена традиция паломничеств к одной из указанных
          святынь — святому колодцу в урочище Половинка близ села Вознесенка
          Венгеровского района, где, по преданию, не позднее середины XIX века
          явилась чудотворная икона св. Параскевы (сама икона считается
          утраченной). Вода из колодца считается целебной, над ним в 2005 году
          была установлена сень с куполом и крестом. Особо почитаемой является
          также Казанская икона Богоматери, хранящаяся в Покровском храме города
          Татарск, которая была подарена для прихода в Татарск, в 1912 году
          группой переселенцев, и чудом уцелела в период гонений. Особо
          почитаемой является также Иверская икона Богоматери, хранящаяся в
          храме Рождества Иоанна Предтечи города Каинска, которая была подарена
          в 1907 году строительницей храма А. И. Шкроевой в память 50-летней
          службы Церкви Божьей в Томской епархии архиепископа Томского и
          Барнаульского Макария. Освящена икона в Иверской часовне города
          Москвы.
        </div>
      </div>
      <div className="sbr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="sbr-h1">
          Административный центр — <i>город Куйбышев (до 1935 года — Каинск)</i>
          .
        </h2>
        <div>
          Включает в себя 17 приходов. Епархия учреждена решением Священного
          Синода Русской православной церкви 28 декабря 2011 года путём
          выделения из Новосибирской епархии. Административно включена в состав
          Новосибирской митрополии. Управляющим епархией избран иеромонах
          Феодосий (Чащин), клирик Новосибирской епархии, 17 марта 2012 года был
          рукоположен в сан епископа Каинского.
        </div>
      </div>
      <div className="sbr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="sbr-h1">
          Спасский кафедральный собор —{" "}
          <i>
            кафедральный собор в честь Нерукотворного образа Господа нашего
            Иисуса Христа ( г.Куйбышев)
          </i>
          .
        </h2>
        <div>
          <h3>Настоятель</h3>
          <i>Протоиерей Сергий Воронкин.</i>
        </div>
        {/* <img src={e} alt="s" /> */}
      </div>
      <div className="sbr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="sbr-h1">
        </h2>
        <div className="Sviat">
          <h3>Святыни:</h3>
          До 1917 года, (по данным протоиерея <i>Андрея Сулоцкого</i>) наиболее
          почитаемыми святынями на территории Каинского округа были чудотворная
          <i>икона св. Параскевы</i>, находившаяся в часовне близ святого колодца в
          окрестностях села Вознесенское (ныне Вознесенка, Венгеровского района
          Новосибирской области), а также местночтимая икона св. Николая в селе
          Осиновые Колки, почитаемый список Казанской иконы Богоматери в селе
          Дмитриевская Волость, и местночтимая икона св. Екатерины в селе
          Бухтарма. В эти населённые пункты регулярно совершались паломничества
          для поклонения святыням. Спустя десятилетия после полного разорения, в
          1995 году была возрождена традиция паломничеств к одной из указанных
          святынь — святому колодцу в урочище Половинка близ села Вознесенка
          Венгеровского района, где, по преданию, не позднее середины XIX века
          явилась чудотворная икона св. Параскевы (сама икона считается
          утраченной). Вода из колодца считается целебной, над ним в 2005 году
          была установлена сень с куполом и крестом.
        </div>
        <img src={k} alt='w'/>
      </div>
      <div className="obr" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <h2 className="obr-h1">Каинская епархия</h2>
        <a href="http://kainsk-eparhia.ru/prihod/">Каинская епархия</a>
      </div>
      <Link to='/'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Kainsk;
