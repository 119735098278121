import "./Carousel.css";
import CarouselCompound from "./components/carousel-compound";
import img1 from "../Image/chasovnya-svyatogo-nikolaya.jpeg";
import img2 from "../Image/hram-andreya-pervozvannogo-1536x1018.jpeg";
import img3 from "../Image/hram-arhistratiga-mihaila.jpeg";
import img4 from "../Image/hram-ioanna-kronshtadtskogo.jpeg";
import img5 from "../Image/hram-mihaila-arhangela.jpeg";
import img6 from "../Image/hram-nikolaya-chudotvortsa.jpeg";
import img7 from "../Image/hram-novomuchenikov-i-ispovednikov.jpeg";
import img8 from "../Image/hram-olega-bryanskogo.jpeg";
import img9 from "../Image/hram-strastoterptsa-nikolaya.jpeg";
import img10 from "../Image/hram-vseh-svyatyh.jpeg";
import img11 from "../Image/kazanskii-hram-1536x1023.jpeg";
import img12 from "../Image/pokrovskaya-tserkov.jpeg";
import img13 from "../Image/rozhdestvenskii-sobor.jpeg";
import img14 from "../Image/sobor-aleksandra-nevskogo.jpeg";
import img16 from "../Image/troitse-vladimirskii-sobor.jpeg";
import img17 from "../Image/Voznesenskiy.jpeg";
export default function MainCarousel() {
  let centerX = document.documentElement.clientWidth;
  let centerY = document.documentElement.clientHeight;
  return (
    <div className="app__main-container">
      <CarouselCompound infinite>
        <CarouselCompound.Page>
          <div className="item item-1">
            <div className="name">храм</div>
            <a href="https://ru.wikipedia.org/wiki/%D0%A7%D0%B0%D1%81%D0%BE%D0%B2%D0%BD%D1%8F_%D0%B2%D0%BE_%D0%B8%D0%BC%D1%8F_%D0%A1%D0%B2%D1%8F%D1%82%D0%B8%D1%82%D0%B5%D0%BB%D1%8F_%D0%B8_%D0%A7%D1%83%D0%B4%D0%BE%D1%82%D0%B2%D0%BE%D1%80%D1%86%D0%B0_%D0%9D%D0%B8%D0%BA%D0%BE%D0%BB%D0%B0%D1%8F_(%D0%9D%D0%BE%D0%B2%D0%BE%D1%81%D0%B8%D0%B1%D0%B8%D1%80%D1%81%D0%BA)"><img src={img1} alt="church" width={centerX} height={centerY} /></a>
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-2">
          <div className="name">храм</div>
          <a href="http://www.aphram.ru/"><img src={img2} alt="church" width={centerX} height={centerY} /></a>
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-3">
          <div className="name">храм</div>
          <a href="https://ru.wikipedia.org/wiki/%D0%A5%D1%80%D0%B0%D0%BC_%D0%90%D1%80%D1%85%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B8%D0%B3%D0%B0_%D0%9C%D0%B8%D1%85%D0%B0%D0%B8%D0%BB%D0%B0_(%D0%9D%D0%BE%D0%B2%D0%BE%D1%81%D0%B8%D0%B1%D0%B8%D1%80%D1%81%D0%BA)"><img src={img3} alt="church" width={centerX} height={centerY} /></a>
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-4">
          <div className="name">храм</div>
            <img src={img4} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-5">
          <div className="name">храм</div>
            <img src={img5} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-6">
          <div className="name">храм</div>
            <img src={img6} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-7">
          <div className="name">храм</div>
            <img src={img7} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-8">
          <div className="name">храм</div>
            <img src={img8} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-9">
          <div className="name">храм</div>
            <img src={img9} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-10">
          <div className="name">храм</div>
            <img src={img10} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-11">
          <div className="name">храм</div>
            <img src={img11} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-12">
          <div className="name">храм</div>
            <img src={img12} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-13">
            ]<div className="name">храм</div>
            <img src={img13} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-14">
          <div className="name">храм</div>
            <img src={img14} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-16">
          <div className="name">храм</div>
            <img src={img16} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
        <CarouselCompound.Page>
          <div className="item item-17">
          <div className="name">храм</div>
            <img src={img17} alt="church" width={centerX} height={centerY} />
          </div>
        </CarouselCompound.Page>
      </CarouselCompound>
    </div>
  );
}
